/* ###### 3.12 Popover ###### */

.popover {
  font-size: 12px;
  padding: 0;
  border-radius: 0;
}

.popover-header {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  color: $gray-800;
  letter-spacing: 0.5px;
  padding: 12px 15px;
  background-color: $white;
  border-color: rgba(28, 39, 60, 0.2);
  border-top-left-radius: 0;
  border-top-right-radius: 0;

  &::before {
    display: none !important;
  }
}

.popover-body {
  padding: 15px;

  p:last-child {
    margin-bottom: 0;
  }
}

.popover-head-primary {
  .popover-header {
    color: $white;
    background-color: $primary;
  }

  &.bs-popover-top .popover-arrow, &.bs-popover-auto[data-popper-placement^="top"] .popover-arrow {
    bottom: -7px;
  }

  &.bs-popover-start .popover-arrow, &.bs-popover-auto[data-popper-placement^="left"] .popover-arrow {
    right: -7px;
  }

  &.bs-popover-end .popover-arrow, &.bs-popover-auto[data-popper-placement^="right"] .popover-arrow {
    left: -7px;
  }

  &.bs-popover-bottom .popover-arrow::after, &.bs-popover-auto[data-popper-placement^="bottom"] .popover-arrow::after {
    border-bottom-color: $primary !important;
  }
}

.popover-head-secondary {
  .popover-header {
    color: $white;
    background-color: $primary;
  }

  &.bs-popover-top .popover-arrow, &.bs-popover-auto[data-popper-placement^="top"] .popover-arrow {
    bottom: -7px;
  }

  &.bs-popover-start .popover-arrow, &.bs-popover-auto[data-popper-placement^="left"] .popover-arrow {
    right: -7px;
  }

  &.bs-popover-end .popover-arrow, &.bs-popover-auto[data-popper-placement^="right"] .popover-arrow {
    left: -7px;
  }

  &.bs-popover-bottom .popover-arrow::after, &.bs-popover-auto[data-popper-placement^="bottom"] .popover-arrow::after {
    border-bottom-color: $primary;
  }
}

.popover-head-primary, .popover-head-secondary {
  border: 0;

  .popover-header {
    border: 0;
  }
}

.popover-head-primary .popover-body, .popover-head-secondary .popover-body {
  border-top-width: 0;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
}

.popover-head-primary {
  &.bs-popover-bottom .popover-arrow::before, &.bs-popover-auto[data-popper-placement^="bottom"] .popover-arrow::before {
    display: none;
  }
}

.popover-head-secondary {
  &.bs-popover-bottom .popover-arrow::before, &.bs-popover-auto[data-popper-placement^="bottom"] .popover-arrow::before {
    display: none;
  }
}

.popover-primary {
   background-color: $primary !important;
    border-width: 0;
    padding: 15px;
    border: 1px solid $primary  !important;

  .popover-header {
    background-color: transparent;
    border-bottom-width: 0;
    padding: 0 0 15px;
    color: $white;
  }

  .popover-body {
    padding: 0;
    color: rgba(255, 255, 255, 0.75);
  }

  .popover-arrow::before {
    display: none;
  }

  &.bs-popover-top .popover-arrow::after, &.bs-popover-auto[data-popper-placement^="top"] .popover-arrow::after {
    border-top-color: $primary !important;
  }

  &.bs-popover-bottom .popover-arrow::after, &.bs-popover-auto[data-popper-placement^="bottom"] .popover-arrow::after {
    border-bottom-color: $primary;
  }

  &.bs-popover-start .popover-arrow::after, &.bs-popover-auto[data-popper-placement^="left"] .popover-arrow::after {
    border-left-color: $primary;
  }

  &.bs-popover-end .popover-arrow::after, &.bs-popover-auto[data-popper-placement^="right"] .popover-arrow::after {
    border-right-color: $primary;
  }
}

.popover-secondary {
	background-color: $secondary !important;
    border-width: 0;
    padding: 15px;
    border: 1px solid $secondary !important;

  .popover-header {
    background-color: transparent;
    border-bottom-width: 0;
    padding: 0 0 15px;
    color: $white;
  }

  .popover-body {
    padding: 0;
    color: rgba(255, 255, 255, 0.75);
  }

  .popover-arrow::before {
    display: none;
  }

  &.bs-popover-top .popover-arrow::after, &.bs-popover-auto[data-popper-placement^="top"] .popover-arrow::after {
    border-top-color: $secondary;
  }

  &.bs-popover-bottom .popover-arrow::after, &.bs-popover-auto[data-popper-placement^="bottom"] .popover-arrow::after {
    border-bottom-color: $secondary !important;
  }

  &.bs-popover-start .popover-arrow::after, &.bs-popover-auto[data-popper-placement^="left"] .popover-arrow::after {
    border-left-color: $primary;
  }

  &.bs-popover-end .popover-arrow::after, &.bs-popover-auto[data-popper-placement^="right"] .popover-arrow::after {
    border-right-color: $primary;
  }
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.76562rem;
  word-wrap: break-word;
  background-color: $white;
  background-clip: padding-box;
  border-radius: 5px;
  border: 1px solid $border;
  box-shadow: -8px 12px 18px 0 rgba(154, 154, 204, .1);
  .popover-arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem;

    &::before, &::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid;
    }
  }
}

.bs-popover-top, .bs-popover-auto[data-popper-placement^="top"] {
  margin-bottom: 0.5rem;
}

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow {
  bottom: calc((0.5rem + 1px) * -1);
}

.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: $border;
}

.bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::after {
  border-width: 0.5rem 0.5rem 0;
  border-top-color: $white;
}

 .bs-popover-auto[data-popper-placement^="right"] {
  margin-left: 0.8rem !important;  
}

.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow {
  left: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin:0 0;
}

.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: $border;
}

.bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: $white;
}

.bs-popover-bottom, .bs-popover-auto[data-popper-placement^="bottom"] {
  margin-top: 0.5rem;
}

.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow {
  top: calc((0.5rem + 1px) * -1);
}

.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: $border;
}

.bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: $white;
}

.bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

// .bs-popover-start, .bs-popover-auto[data-popper-placement^="left"] {
//   margin-right: -1rem !important;
// }

.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow {
  right: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0rem 0;
}

.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: $border;
}

.bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: $white;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  background-color: $white;
  border-bottom: 1px solid $border;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);

  &:empty {
    display: none;
  }
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: $default-color;
}

.popover-static-demo {
  background-color: $gray-200;
  padding-top: 40px;
  padding-bottom: 40px;
  text-align: center;

  .popover {
    z-index: 0;
    opacity: 1;
    position: relative;
    display: inline-block;
    margin: 0 10px;
    box-shadow: -8px 12px 18px 0 rgba(154, 154, 204, .1);
    border: 1px solid $border;
  }

  .bs-popover-top .popover-arrow, .bs-popover-auto[data-popper-placement^="top"] .popover-arrow, .bs-popover-bottom .popover-arrow, .bs-popover-auto[data-popper-placement^="bottom"] .popover-arrow {
    left: 50%;
    margin-left: -5px;
  }

  .bs-popover-start, .bs-popover-auto[data-popper-placement^="left"], .bs-popover-end, .bs-popover-auto[data-popper-placement^="right"] {
    margin-top: 5px;
  }

  .bs-popover-start .popover-arrow, .bs-popover-auto[data-popper-placement^="left"] .popover-arrow, .bs-popover-end .popover-arrow, .bs-popover-auto[data-popper-placement^="right"] .popover-arrow {
    top: 50%;
    margin-top: -5px;
  }
}