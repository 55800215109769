.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background: $white !important;
    background-clip: border-box;
    border-radius: 5px;
    border: 0px solid transparent;
    margin-bottom: 1.3rem;
    box-shadow: $shadow;

  > {
    hr {
      margin-right: 0;
      margin-left: 0;
    }

    .list-group {
      &:first-child .list-group-item:first-child {
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
      }

      &:last-child .list-group-item:last-child {
        border-bottom-right-radius: 3px;
        border-bottom-left-radius: 3px;
      }
    }
  }
}

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link {
  &:hover {
    text-decoration: none;
  }

  + .card-link {
    margin-left: 1.25rem;
  }
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgb(255, 255, 255);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);

  &:first-child {
    border-radius: calc(3px - 1px) calc(3px - 1px) 0 0;
  }

  + .list-group .list-group-item:first-child {
    border-top: 0;
  }
}

.card-footer {
  padding: 1.25rem 1.25rem;
  background-color: rgb(255, 255, 255);
  border-top: 1px solid rgba(0, 0, 0, 0.125);

  &:last-child {
    border-radius: 0 0 calc(3px - 1px) calc(3px - 1px);
  }
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
}

.card-img {
  width: 100%;
  border-radius: calc(3px - 1px);
}

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(5px - 1px);
  border-top-right-radius: calc(5px - 1px);
}

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(10px - 1px);
  border-bottom-left-radius: calc(10px - 1px);
}

.card-deck {
  display: flex;
  flex-direction: column;

  .card {
    margin-bottom: 15px;
  }
}

@media (min-width: 576px) {
  .card-deck {
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;

    .card {
      display: flex;
      flex: 1 0 0%;
      flex-direction: column;
      margin-right: 15px;
      margin-bottom: 0;
      margin-left: 15px;
    }
  }
}

.card-group {
  display: flex;
  flex-direction: column;

  > .card {
    margin-bottom: 15px;
  }
}

@media (min-width: 576px) {
  .card-group {
    flex-flow: row wrap;

    > .card {
      flex: 1 0 0%;
      margin-bottom: 0;

      + .card {
        margin-left: 0;
        border-left: 0;
      }

      &:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;

        .card-img-top, .card-header {
          border-top-right-radius: 0;
        }

        .card-img-bottom, .card-footer {
          border-bottom-right-radius: 0;
        }
      }

      &:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;

        .card-img-top, .card-header {
          border-top-left-radius: 0;
        }

        .card-img-bottom, .card-footer {
          border-bottom-left-radius: 0;
        }
      }
    }
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;

    .card {
      display: inline-block;
      width: 100%;
    }
  }
}

.card {
  border-radius:5px;

  &.text-white .card-title, &.tx-white .card-title {
    color: $white;
  }
}

.card-header, .card-footer {
  position: relative;
  border-color: $border;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  padding-top: 1.25rem;
}

.card-header {
  border-bottom: 0;
  border-top-left-radius: 5px !important;
  border-top-right-radius: 5px !important;

  &:first-child {
    border-radius: 0;
  }
}
.card-footer{
	border-bottom-left-radius: 7px !important;
  border-bottom-right-radius: 7px !important;
}
.card-header-tab {
  border-bottom: 0;
  padding: 0;
}

.card-title {
  font-weight: 600;
  font-size: 14px;
  text-transform: uppercase;
}

.card-item-desc .card-item-desc-1 dt, dd {
  display: inline-block;
}

.card-item-desc {
  .card-item-desc-1 {
    dt {
      font-weight: 500;
      font-size: 13px;
    }

    dd {
      font-size: 13px;
      color: $gray-600;
      margin-bottom: 0;
    }
  }

  dl {
    margin-bottom: 0;
  }
}

.card .card-header .card-header-right .card-option {
  width: 35px;
  height: 20px;
  overflow: hidden;
  -webkit-transition: .3s ease-in-out;
  transition: .3s ease-in-out;

  li {
    display: inline-block;
  }
}

.card-category {
    font-size: 18px;
    text-transform: uppercase;
    text-align: center;
    font-weight: 500;
    letter-spacing: .05em;
    margin: 0 0 .5rem;
    background: $gray-100;
    padding: 7px 0;
    border-radius: 4px;
}

.card-dashboard-audience-metrics .flot-chart .flot-x-axis > div {
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 700;
  color: $gray-600;
  display: none;
}

.card-minimal-two {
  .nav-pills .nav-link.active {
    border-radius: 2px;
    background-color: $primary;
  }

  .nav-link {
    display: block;
    padding: 8px 30px;
    margin: 2px;
    background-color: $gray-200;
  }
}

.card-body-top {
  a {
    color: $gray-900;
    font-weight: 700;
    width: 20px;
    text-align: right;
    display: inline-block;
  }

  top: 63px;
  left: -5px;
  position: absolute;
  font-size: 10px;
  text-transform: uppercase;
  letter-spacing: 1px;
  z-index: 10;
}

.card-minimal-four .card-footer .nav-link {
  flex: 1;
  display: block;
  text-align: center;
  background-color: $gray-200;
  position: relative;
  padding-left: 10px;
  padding-right: 10px;
}

.card-progress {
  display: flex;
  align-items: center;

  .progress {
    height: 3px;
    flex: 1;
    margin: 0 5px;
  }
}

.card {
  &.card-primary {
    border-top: 2px solid $primary !important;
  }

  &.card-secondary {
    border-top: 2px solid $secondary !important;
  }

  &.card-success {
    border-top: 2px solid #22e840 !important;
  }

  &.card-danger {
    border-top: 2px solid $danger !important;
  }

  &.card-warning {
    border-top: 2px solid #ffb209 !important;
  }

  &.card-info {
    border-top: 2px solid #01b8ff !important;
  }

  &.card-purple {
    border-top: 2px solid $purple !important;
  }

  &.card-dark {
    border-top: 2px solid #343a40 !important;
  }
}
.card-img-top.card-img-bottom{
	border-bottom-left-radius: calc(10px - 1px);
	border-bottom-right-radius: calc(10px - 1px);
	}
	
.card-category1 {
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: .05em;
    margin: 0 0 .5rem;
    background: transparent;
    padding: 0;
    border-radius: 4px;
    color: #383853;
}

  .Choose1{
   border: #ededf5 solid 2px;
   padding: 8px 19px;
   border-radius: 5px;
  }
.pricing-card:hover .card-category.style-1{
    background: $primary;
    color: #eee;
	transition: all 0.5s ease 0s;
}
.pricing-card:hover .Choose1{
    background: $primary;
    color: #eee;
	transition: all 0.5s ease 0s;
   padding: 8px 19px;

}

.error-bg1 {
    background: url(../img/png/circle4.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}
.error-bg1::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
}
.card-sigin{
   background: $white;
    padding: 50px;
    border-radius: 15px;
}

.square-box div:nth-child(1) {
    top: 12%;
    left: 42%;
    animation: animate 10s linear infinite;
}
.square-box div {
   position: absolute;
    width: 50px;
    height: 50px;
    background: transparent;
    border: 5px solid rgba($white,0.3);
    border-radius: 35px;
}
.square-box div:nth-child(2) {
    top: 70%;
    left: 50%;
    animation: animate 7s linear infinite;
}.square-box div:nth-child(4) {
    top: 20%;
    left: 60%;
    animation: animate 10s linear infinite;
}
.square-box div:nth-child(3) {
    top: 17%;
    left: 6%;
    animation: animate 9s linear infinite;
}
.square-box div:nth-child(5) {
    top: 88%;
    left: 10%;
    animation: animate 6s linear infinite;
}
.square-box div:nth-child(6) {
    top: 80%;
    left: 70%;
    animation: animate 12s linear infinite;
}
.square-box div:nth-child(7) {
    top: 60%;
    left: 80%;
    animation: animate 15s linear infinite;
}
.square-box div:nth-child(8) {
    top: 32%;
    left: 25%;
    animation: animate 16s linear infinite;
}

.square-box div:nth-child(9) {
    top: 20%;
    left: 80%;
    animation: animate 10s linear infinite;
}
.square-box div:nth-child(10) {
    top: 40%;
    left: 72%;
    animation: animate 5s linear infinite;
}
.square-box div:nth-child(11) {
    top: 50%;
    left: 30%;
    animation: animate 6s linear infinite;
}
.square-box div:nth-child(12) {
    top: 65%;
    left: 16%;
    animation: animate 9s linear infinite;
}
.square-box div:nth-child(13) {
    top: 45%;
    left: 3%;
    animation: animate 14s linear infinite;
}
.square-box div:nth-child(14) {
    top: 47%;
    left: 55%;
    animation: animate 3s linear infinite;
}
.square-box div:nth-child(15) {
    top: 79%;
    left: 90%;
    animation: animate 8s linear infinite;
}

@-webkit-keyframes animate {
  0% {
    -webkit-transform: scale(0) translateY(0) rotate(0);
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(1.3) translateY(-90px) rotate(360deg);
    opacity: 0;
  }
}
@media (max-width: 767px){
  .card-sigin-main{
  padding:25px;
  }

}
	
@media (max-width: 767px){
	.card-sigin {
		padding: 25px;
	}
}
	
.card-collapsed > :not(.card-header):not(.card-status) {
    display: none;
}

.card-fullscreen {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    margin: 0;
}

.card-title:before{
    content: '';
    width: 3px;
    height: 16px;
    background: $primary;
    position: absolute;
    left: 11px;
    display: block;
    top: 21px;
}

.card-title2:before{
    content: '';
    background: #f32b71;
}

.card-title3:before{
    content: '';
    background: #1590be;
}

.card-img-top-1 .card-title:before{
  display:none;
}
.card-img-left {
    width: 100%;
    border-bottom-left-radius: calc(5px - 1px);
    border-top-left-radius: calc(5px - 1px);
}
.custom-title:before {
  content: '';
  width: 3px;
  height: 16px;
  background: #fff;
  position: absolute;
  left: 11px;
  display: block;
  top: 19px;
}

.primary-custom-card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    overflow: visible !important;
    word-wrap: break-word;
    overflow: hidden;
    background-clip: border-box;
    border-radius: 5px;
    margin-bottom: 1.3rem;
    box-shadow: 0px 4px 7px rgba(154, 154, 204,0.1);
    background-size: cover !important;
    background-repeat: no-repeat;
    background-position: center;
    &:before {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      opacity: 0.2;
      left: 0;
      right: 0;
      border-radius: 5px;
      top: 0;
      bottom: 0;
    }
}
.primary-custom-card1 {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  overflow: visible !important;
  word-wrap: break-word;
  overflow: hidden;
  background-clip: border-box;
  border-radius: 5px;
  margin-bottom: 1.3rem;
  box-shadow: 0px 4px 7px rgba(154, 154, 204,0.1);
  background-image: url(../../assets/img/png/chatbg.png) !important;
  background-size: cover !important;
  background-repeat: no-repeat;
  background-position: center;
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0.2;
    left: 0;
    right: 0;
    border-radius: 5px;
    top: 0;
    bottom: 0;
  }
}
.bg-primary-card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background: $primary !important;
    background-clip: border-box;
    border-radius: 5px;
    margin-bottom: 1.3rem;
    box-shadow: 0px 4px 7px rgba(154, 154, 204,0.1);
    color: $white !important;

    .card-header {
      background-color: $primary !important;
        .card-title:before {
          background: $white;
          color: $white !important;
        }
    }
    
}