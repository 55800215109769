/*---- Tags-----*/

.tag {
	font-size: 0.75rem;
	color: #8189b6;
	border: 1px solid $border;
	border-radius: 3px;
	padding: 2px 0.55rem;
	line-height: 2em;
	display: -ms-inline-flexbox;
	display: inline-flex;
	cursor: default;
	font-weight: 400;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

a.tag {
  text-decoration: none;
  cursor: pointer;
  transition: .3s color, .3s background;

  &:hover {
    background-color: rgba(110, 118, 135, 0.2);
    color: inherit;
  }
}

.tag-addon {
  display: inline-block;
  padding: 0 .5rem;
  color: inherit;
  text-decoration: none;
  background: #f9f9fb;
  margin: 0 -.4rem 0 .5rem;
  text-align: center;
  min-width: 1.5rem;

  &:last-child {
    border-radius: 3px;
  }

  i {
    vertical-align: middle;
    margin: 0 -.25rem;
    line-height: 1.5px;
    font-size: 13px;
  }
}

a.tag-addon {
  text-decoration: none;
  cursor: pointer;
  transition: .3s color, .3s background;

  &:hover {
    background: rgb(238, 238, 255);
    color: inherit;
  }
}

.tag-avatar {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 3px 0 0 3px;
  margin: 0 .5rem 0 -.5rem;
}

.tag-blue {
  background-color: #467fcf !important;
  color: $white;
}

.tag-indigo {
  background-color: $indigo !important;
  color: $white;
}

.tag-purple {
  background-color: $purple !important;
  color: $white;
}

.tag-pink {
  background-color: #bc22be !important;
  color: $white;
}

.tag-red {
  background-color: $danger !important;
  color: $white;
}

.tag-orange {
  background-color: $orange !important;
  color: $white;
}

.tag-yellow {
  background-color: #fdb901 !important;
  color: $white;
}

.tag-green {
  background-color: #0fa751 !important;
  color: $white;
}

.tag-teal {
  background-color: $teal !important;
  color: $white;
}

.tag-cyan {
  background-color: $info !important;
  color: $white;
}

.tag-white {
  background-color: $white !important;
  color: $white;
}

.tag-gray {
  background-color: #868e96 !important;
  color: $white;
}

.tag-gray-dark {
  background-color: #343a40 !important;
  color: $white;
}

.tag-azure {
  background-color: #17c1f4 !important;
  color: $white;
}

.tag-lime {
  background-color: #7bd235 !important;
  color: $white;
}

.tag-primary {
  background-color: #467fcf !important;
  color: $white;
}

.tag-secondary {
  background-color: #868e96 !important;
  color: $white;
}

.tag-success {
  background-color: #0fa751 !important;
  color: $white;
}

.tag-info {
  background-color: #17c1f4 !important;
  color: $white;
}

.tag-warning {
  background-color: #fdb901 !important;
  color: $white;
}

.tag-danger {
  background-color: #ec2d38 !important;
  color: $white;
}

.tag-light {
  background-color: #f8f9fa !important;
  color: $white;
}

.tag-dark {
  background-color: #343a40 !important;
  color: $white;
}

.tag-rounded {
  border-radius: 50px;

  .tag-avatar {
    border-radius: 50px;
  }
}

.tags {
  margin-bottom: -.5rem;
  font-size: 0;

  > .tag {
    margin-bottom: .5rem;

    &:not(:last-child) {
      margin-right: .2rem;
    }
  }
}

@media (min-width: 459px) {
  .text-wrap .example .form-group{
    margin-bottom:0;
  }
}
.tag-primary{
 background-color: $primary !important;
    color: #fff;
}
.tag-secondary{
 background-color: $secondary !important;
    color: #fff;
}

.tag-success{
 background-color: $success !important;
    color: #fff;
}
.tag-danger{
 background-color: $danger !important;
    color: #fff;
}
.tag-warning{
 background-color: $warning !important;
    color: #fff;
}
.tag-info{
 background-color: $info !important;
    color: #fff;
}
.tag-teal{
 background-color: $teal !important;
    color: #fff;
}