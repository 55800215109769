/* ###### 3.11 Pagination ###### */

.page-item {
  &:first-child .page-link, &:last-child .page-link {
    border-radius: 0;
  }

  + .page-item {
    margin-left: 1px;
  }

  &.active .page-link {
    background-color: $primary;
  }
}

.page-link {
  color: $gray-700;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 38px;
  border-width: 0;
  background-color: $gray-200;
  margin-left: 0;
  font-weight: 500;

  i {
    font-size: 18px;
    line-height: 0;
  }

  &:hover, &:focus {
    color: $gray-900;
    background-color: $gray-300;
  }
}

.pagination-dark {
  .page-item.active .page-link {
    background-color: $primary  !important;
    color: $white;
	border: 1px solid $primary;
  }
   
  .page-link {
    font-weight: 400;
    background-color: $white-05;
    color: $white-8;
    border: 1px solid $black-1;    

    &:hover, &:focus {
      color: $white;
      background-color: $white-08;
    }
  }
}

.pagination-primary .page-item.active .page-link {
  background-color: $primary;
}

.pagination-success .page-item.active .page-link {
  background-color: $success;
  border-color: $success;
}

.pagination-circled {
  .page-item {
    + .page-item {
      margin-left: 5px;
    }

    &:first-child .page-link, &:last-child .page-link {
      border-radius: 100%;
    }
  }

  .page-link {
    padding: 0;
    width: 38px;
    border-radius: 100%;
  }
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 3px;
}

.page-link {
  position: relative;
  display: flex;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #596882;
  background-color: #e3e7ed;
  border: 1px solid $background;

  &:hover {
    z-index: 2;
    color: $gray-100;
    text-decoration: none;
    background-color: $primary !important;
    border-color: $primary !important;
  }

  &:focus {
    z-index: 2;
    outline: 0;
   
  }
}

.page-item {
  &:first-child .page-link {
    margin-left: 0;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
  }

  &:last-child .page-link {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }

  &.active .page-link {
    z-index: 1;
    color: $white;
    background-color: $primary;
    border-color: $primary;
  }

  &.disabled .page-link {
    color: $gray-600;
    pointer-events: none;
    cursor: auto;
    background-color: $white;
    border-color: $gray-300;
  }
}

.pagination-lg {
  .page-link {
    padding: 1.2rem 1.3rem;
    font-size: 1.05rem;
    line-height: 1.5;
  }

  .page-item {
    &:first-child .page-link {
      border-top-left-radius: 0.3rem;
      border-bottom-left-radius: 0.3rem;
    }

    &:last-child .page-link {
      border-top-right-radius: 0.3rem;
      border-bottom-right-radius: 0.3rem;
    }
  }
}

.pagination-sm {
  .page-link {
    padding: 0.25rem 0.5rem;
    font-size: 0.76562rem;
    line-height: 1.5;
  }

  .page-item {
    &:first-child .page-link {
      border-top-left-radius: 0.2rem;
      border-bottom-left-radius: 0.2rem;
    }

    &:last-child .page-link {
      border-top-right-radius: 0.2rem;
      border-bottom-right-radius: 0.2rem;
    }
  }
}
.page-prev .page-link, .page-next .page-link {
  width: auto !important;
  padding: 0 10px;
}

.page-prev .page-link, .page-next .page-link {
  width: auto !important;
  padding: 10px 15px;
}

.pagination {
  li a {
    margin-right: 4px;
    border-radius: 4px;
  }

  .page-link {
    background-color: $gray-100;
    border: 1px solid $border;

    &:hover {
      background-color: $primary !important;
      border: 1px solid $primary !important;
    }
  }

  .page-item {
    &:last-child .page-link, &:first-child .page-link {
      border-radius: 4px;
    }
  }

  &.pagination-circled .page-link {
    border-radius: 50% !important;
  }
}

.pagination-dark.pagination .page-link {
  background-color: #455377 !important;
  border: 1px solid rgba(255, 255, 255, .1) !important;

  &:hover {
    background: $primary !important;
    color: $white;
    border: 1px solid $primary !important;
  }
}
.pagination-success .page-link:hover{
 background-color: $success !important;
    border-color: $success!important;
}
.pagination-circle {
    margin-top: 0;
    border-radius: 50px;
    background: #f0f0ff;
    padding: 7px;
}
.page-link:focus{
  box-shadow:none;
}
.page-link-outline {
  color: $dark;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border: 1px solid #ededf5;
  background-color: $white;
  margin-left: 0;
  font-weight: 500;

  i {
    font-size: 18px;
    line-height: 0;
  }
}
.page-item.active .page-link-outline {
    z-index: 1;
   background-color: #fff;
    border: 1px solid $primary;
   border-radius: 5px;
    color: $primary;
    
}