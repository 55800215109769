.m-0 {
  margin: 0 !important;
}

.mt-0, .my-0 {
  margin-top: 0 !important;
}

.me-0, .mx-0 {
  margin-right: 0 !important;
}

.mb-0, .my-0 {
  margin-bottom: 0 !important;
}

.ms-0, .mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1, .my-1 {
  margin-top: 0.25rem !important;
}

.me-1, .mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1, .my-1 {
  margin-bottom: 0.25rem !important;
}

.ms-1, .mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2, .my-2 {
  margin-top: 0.5rem !important;
}

.me-2, .mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2, .my-2 {
  margin-bottom: 0.5rem !important;
}

.ms-2, .mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3, .my-3 {
  margin-top: 1rem !important;
}

.me-3, .mx-3 {
  margin-right: 1rem !important;
}

.mb-3, .my-3 {
  margin-bottom: 1rem !important;
}

.ms-3, .mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4, .my-4 {
  margin-top: 1.5rem !important;
}

.me-4, .mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4, .my-4 {
  margin-bottom: 1.5rem !important;
}

.ms-4, .mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5, .my-5 {
  margin-top: 3rem !important;
}

.me-5, .mx-5 {
  margin-right: 3rem !important;
}

.mb-5, .my-5 {
  margin-bottom: 3rem !important;
}

.ms-5, .mx-5 {
  margin-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1, .my-n1 {
  margin-top: -0.25rem !important;
}

.me-n1, .mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1, .my-n1 {
  margin-bottom: -0.25rem !important;
}

.ms-n1, .mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2, .my-n2 {
  margin-top: -0.5rem !important;
}

.me-n2, .mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2, .my-n2 {
  margin-bottom: -0.5rem !important;
}

.ms-n2, .mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3, .my-n3 {
  margin-top: -1rem !important;
}

.me-n3, .mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3, .my-n3 {
  margin-bottom: -1rem !important;
}

.ms-n3, .mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4, .my-n4 {
  margin-top: -1.5rem !important;
}

.me-n4, .mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4, .my-n4 {
  margin-bottom: -1.5rem !important;
}

.ms-n4, .mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5, .my-n5 {
  margin-top: -3rem !important;
}

.me-n5, .mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5, .my-n5 {
  margin-bottom: -3rem !important;
}

.ms-n5, .mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto, .my-auto {
  margin-top: auto !important;
}

.me-auto, .mx-auto {
  margin-right: auto !important;
}

.mb-auto, .my-auto {
  margin-bottom: auto !important;
}

.ms-auto, .mx-auto {
  margin-left: auto !important;
}

@media (max-width: 767px) {
  .m-sm-0 {
    margin: 0 !important;
  }

  .mt-sm-0, .my-sm-0 {
    margin-top: 0 !important;
  }

  .me-sm-0, .mx-sm-0 {
    margin-right: 0 !important;
  }

  .mb-sm-0, .my-sm-0 {
    margin-bottom: 0 !important;
  }

  .ms-sm-0, .mx-sm-0 {
    margin-left: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .mt-sm-1, .my-sm-1 {
    margin-top: 0.25rem !important;
  }

  .me-sm-1, .mx-sm-1 {
    margin-right: 0.25rem !important;
  }

  .mb-sm-1, .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .ms-sm-1, .mx-sm-1 {
    margin-left: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .mt-sm-2, .my-sm-2 {
    margin-top: 0.5rem !important;
  }

  .me-sm-2, .mx-sm-2 {
    margin-right: 0.5rem !important;
  }

  .mb-sm-2, .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .ms-sm-2, .mx-sm-2 {
    margin-left: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .mt-sm-3, .my-sm-3 {
    margin-top: 1rem !important;
  }

  .me-sm-3, .mx-sm-3 {
    margin-right: 1rem !important;
  }

  .mb-sm-3, .my-sm-3 {
    margin-bottom: 1rem !important;
  }

  .ms-sm-3, .mx-sm-3 {
    margin-left: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .mt-sm-4, .my-sm-4 {
    margin-top: 1.5rem !important;
  }

  .me-sm-4, .mx-sm-4 {
    margin-right: 1.5rem !important;
  }

  .mb-sm-4, .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .ms-sm-4, .mx-sm-4 {
    margin-left: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .mt-sm-5, .my-sm-5 {
    margin-top: 3rem !important;
  }

  .me-sm-5, .mx-sm-5 {
    margin-right: 3rem !important;
  }

  .mb-sm-5, .my-sm-5 {
    margin-bottom: 3rem !important;
  }

  .ms-sm-5, .mx-sm-5 {
    margin-left: 3rem !important;
  }

  .m-sm-n1 {
    margin: -0.25rem !important;
  }

  .mt-sm-n1, .my-sm-n1 {
    margin-top: -0.25rem !important;
  }

  .me-sm-n1, .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-sm-n1, .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ms-sm-n1, .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }

  .m-sm-n2 {
    margin: -0.5rem !important;
  }

  .mt-sm-n2, .my-sm-n2 {
    margin-top: -0.5rem !important;
  }

  .me-sm-n2, .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-sm-n2, .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ms-sm-n2, .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }

  .m-sm-n3 {
    margin: -1rem !important;
  }

  .mt-sm-n3, .my-sm-n3 {
    margin-top: -1rem !important;
  }

  .me-sm-n3, .mx-sm-n3 {
    margin-right: -1rem !important;
  }

  .mb-sm-n3, .my-sm-n3 {
    margin-bottom: -1rem !important;
  }

  .ms-sm-n3, .mx-sm-n3 {
    margin-left: -1rem !important;
  }

  .m-sm-n4 {
    margin: -1.5rem !important;
  }

  .mt-sm-n4, .my-sm-n4 {
    margin-top: -1.5rem !important;
  }

  .me-sm-n4, .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-sm-n4, .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ms-sm-n4, .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }

  .m-sm-n5 {
    margin: -3rem !important;
  }

  .mt-sm-n5, .my-sm-n5 {
    margin-top: -3rem !important;
  }

  .me-sm-n5, .mx-sm-n5 {
    margin-right: -3rem !important;
  }

  .mb-sm-n5, .my-sm-n5 {
    margin-bottom: -3rem !important;
  }

  .ms-sm-n5, .mx-sm-n5 {
    margin-left: -3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mt-sm-auto, .my-sm-auto {
    margin-top: auto !important;
  }

  .me-sm-auto, .mx-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-auto, .my-sm-auto {
    margin-bottom: auto !important;
  }

  .ms-sm-auto, .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }

  .mt-md-0, .my-md-0 {
    margin-top: 0 !important;
  }

  .me-md-0, .mx-md-0 {
    margin-right: 0 !important;
  }

  .mb-md-0, .my-md-0 {
    margin-bottom: 0 !important;
  }

  .ms-md-0, .mx-md-0 {
    margin-left: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .mt-md-1, .my-md-1 {
    margin-top: 0.25rem !important;
  }

  .me-md-1, .mx-md-1 {
    margin-right: 0.25rem !important;
  }

  .mb-md-1, .my-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .ms-md-1, .mx-md-1 {
    margin-left: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .mt-md-2, .my-md-2 {
    margin-top: 0.5rem !important;
  }

  .me-md-2, .mx-md-2 {
    margin-right: 0.5rem !important;
  }

  .mb-md-2, .my-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .ms-md-2, .mx-md-2 {
    margin-left: 0.5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .mt-md-3, .my-md-3 {
    margin-top: 1rem !important;
  }

  .me-md-3, .mx-md-3 {
    margin-right: 1rem !important;
  }

  .mb-md-3, .my-md-3 {
    margin-bottom: 1rem !important;
  }

  .ms-md-3, .mx-md-3 {
    margin-left: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .mt-md-4, .my-md-4 {
    margin-top: 1.5rem !important;
  }

  .me-md-4, .mx-md-4 {
    margin-right: 1.5rem !important;
  }

  .mb-md-4, .my-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .ms-md-4, .mx-md-4 {
    margin-left: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .mt-md-5, .my-md-5 {
    margin-top: 3rem !important;
  }

  .me-md-5, .mx-md-5 {
    margin-right: 3rem !important;
  }

  .mb-md-5, .my-md-5 {
    margin-bottom: 3rem !important;
  }

  .ms-md-5, .mx-md-5 {
    margin-left: 3rem !important;
  }

  .m-md-n1 {
    margin: -0.25rem !important;
  }

  .mt-md-n1, .my-md-n1 {
    margin-top: -0.25rem !important;
  }

  .me-md-n1, .mx-md-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-md-n1, .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ms-md-n1, .mx-md-n1 {
    margin-left: -0.25rem !important;
  }

  .m-md-n2 {
    margin: -0.5rem !important;
  }

  .mt-md-n2, .my-md-n2 {
    margin-top: -0.5rem !important;
  }

  .me-md-n2, .mx-md-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-md-n2, .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ms-md-n2, .mx-md-n2 {
    margin-left: -0.5rem !important;
  }

  .m-md-n3 {
    margin: -1rem !important;
  }

  .mt-md-n3, .my-md-n3 {
    margin-top: -1rem !important;
  }

  .me-md-n3, .mx-md-n3 {
    margin-right: -1rem !important;
  }

  .mb-md-n3, .my-md-n3 {
    margin-bottom: -1rem !important;
  }

  .ms-md-n3, .mx-md-n3 {
    margin-left: -1rem !important;
  }

  .m-md-n4 {
    margin: -1.5rem !important;
  }

  .mt-md-n4, .my-md-n4 {
    margin-top: -1.5rem !important;
  }

  .me-md-n4, .mx-md-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-md-n4, .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ms-md-n4, .mx-md-n4 {
    margin-left: -1.5rem !important;
  }

  .m-md-n5 {
    margin: -3rem !important;
  }

  .mt-md-n5, .my-md-n5 {
    margin-top: -3rem !important;
  }

  .me-md-n5, .mx-md-n5 {
    margin-right: -3rem !important;
  }

  .mb-md-n5, .my-md-n5 {
    margin-bottom: -3rem !important;
  }

  .ms-md-n5, .mx-md-n5 {
    margin-left: -3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mt-md-auto, .my-md-auto {
    margin-top: auto !important;
  }

  .me-md-auto, .mx-md-auto {
    margin-right: auto !important;
  }

  .mb-md-auto, .my-md-auto {
    margin-bottom: auto !important;
  }

  .ms-md-auto, .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }

  .mt-lg-0, .my-lg-0 {
    margin-top: 0 !important;
  }

  .me-lg-0, .mx-lg-0 {
    margin-right: 0 !important;
  }

  .mb-lg-0, .my-lg-0 {
    margin-bottom: 0 !important;
  }

  .ms-lg-0, .mx-lg-0 {
    margin-left: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .mt-lg-1, .my-lg-1 {
    margin-top: 0.25rem !important;
  }

  .me-lg-1, .mx-lg-1 {
    margin-right: 0.25rem !important;
  }

  .mb-lg-1, .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .ms-lg-1, .mx-lg-1 {
    margin-left: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .mt-lg-2, .my-lg-2 {
    margin-top: 0.5rem !important;
  }

  .me-lg-2, .mx-lg-2 {
    margin-right: 0.5rem !important;
  }

  .mb-lg-2, .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .ms-lg-2, .mx-lg-2 {
    margin-left: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .mt-lg-3, .my-lg-3 {
    margin-top: 1rem !important;
  }

  .me-lg-3, .mx-lg-3 {
    margin-right: 1rem !important;
  }

  .mb-lg-3, .my-lg-3 {
    margin-bottom: 1rem !important;
  }

  .ms-lg-3, .mx-lg-3 {
    margin-left: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .mt-lg-4, .my-lg-4 {
    margin-top: 1.5rem !important;
  }

  .me-lg-4, .mx-lg-4 {
    margin-right: 1.5rem !important;
  }

  .mb-lg-4, .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .ms-lg-4, .mx-lg-4 {
    margin-left: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .mt-lg-5, .my-lg-5 {
    margin-top: 3rem !important;
  }

  .me-lg-5, .mx-lg-5 {
    margin-right: 3rem !important;
  }

  .mb-lg-5, .my-lg-5 {
    margin-bottom: 3rem !important;
  }

  .ms-lg-5, .mx-lg-5 {
    margin-left: 3rem !important;
  }

  .m-lg-n1 {
    margin: -0.25rem !important;
  }

  .mt-lg-n1, .my-lg-n1 {
    margin-top: -0.25rem !important;
  }

  .me-lg-n1, .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-lg-n1, .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ms-lg-n1, .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }

  .m-lg-n2 {
    margin: -0.5rem !important;
  }

  .mt-lg-n2, .my-lg-n2 {
    margin-top: -0.5rem !important;
  }

  .me-lg-n2, .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-lg-n2, .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ms-lg-n2, .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }

  .m-lg-n3 {
    margin: -1rem !important;
  }

  .mt-lg-n3, .my-lg-n3 {
    margin-top: -1rem !important;
  }

  .me-lg-n3, .mx-lg-n3 {
    margin-right: -1rem !important;
  }

  .mb-lg-n3, .my-lg-n3 {
    margin-bottom: -1rem !important;
  }

  .ms-lg-n3, .mx-lg-n3 {
    margin-left: -1rem !important;
  }

  .m-lg-n4 {
    margin: -1.5rem !important;
  }

  .mt-lg-n4, .my-lg-n4 {
    margin-top: -1.5rem !important;
  }

  .me-lg-n4, .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-lg-n4, .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ms-lg-n4, .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }

  .m-lg-n5 {
    margin: -3rem !important;
  }

  .mt-lg-n5, .my-lg-n5 {
    margin-top: -3rem !important;
  }

  .me-lg-n5, .mx-lg-n5 {
    margin-right: -3rem !important;
  }

  .mb-lg-n5, .my-lg-n5 {
    margin-bottom: -3rem !important;
  }

  .ms-lg-n5, .mx-lg-n5 {
    margin-left: -3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mt-lg-auto, .my-lg-auto {
    margin-top: auto !important;
  }

  .me-lg-auto, .mx-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-auto, .my-lg-auto {
    margin-bottom: auto !important;
  }

  .ms-lg-auto, .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }

  .mt-xl-0, .my-xl-0 {
    margin-top: 0 !important;
  }

  .me-xl-0, .mx-xl-0 {
    margin-right: 0 !important;
  }

  .mb-xl-0, .my-xl-0 {
    margin-bottom: 0 !important;
  }

  .ms-xl-0, .mx-xl-0 {
    margin-left: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .mt-xl-1, .my-xl-1 {
    margin-top: 0.25rem !important;
  }

  .me-xl-1, .mx-xl-1 {
    margin-right: 0.25rem !important;
  }

  .mb-xl-1, .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .ms-xl-1, .mx-xl-1 {
    margin-left: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .mt-xl-2, .my-xl-2 {
    margin-top: 0.5rem !important;
  }

  .me-xl-2, .mx-xl-2 {
    margin-right: 0.5rem !important;
  }

  .mb-xl-2, .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .ms-xl-2, .mx-xl-2 {
    margin-left: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .mt-xl-3, .my-xl-3 {
    margin-top: 1rem !important;
  }

  .me-xl-3, .mx-xl-3 {
    margin-right: 1rem !important;
  }

  .mb-xl-3, .my-xl-3 {
    margin-bottom: 1rem !important;
  }

  .ms-xl-3, .mx-xl-3 {
    margin-left: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .mt-xl-4, .my-xl-4 {
    margin-top: 1.5rem !important;
  }

  .me-xl-4, .mx-xl-4 {
    margin-right: 1.5rem !important;
  }

  .mb-xl-4, .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .ms-xl-4, .mx-xl-4 {
    margin-left: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .mt-xl-5, .my-xl-5 {
    margin-top: 3rem !important;
  }

  .me-xl-5, .mx-xl-5 {
    margin-right: 3rem !important;
  }

  .mb-xl-5, .my-xl-5 {
    margin-bottom: 3rem !important;
  }

  .ms-xl-5, .mx-xl-5 {
    margin-left: 3rem !important;
  }

  .m-xl-n1 {
    margin: -0.25rem !important;
  }

  .mt-xl-n1, .my-xl-n1 {
    margin-top: -0.25rem !important;
  }

  .me-xl-n1, .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-xl-n1, .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ms-xl-n1, .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }

  .m-xl-n2 {
    margin: -0.5rem !important;
  }

  .mt-xl-n2, .my-xl-n2 {
    margin-top: -0.5rem !important;
  }

  .me-xl-n2, .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-xl-n2, .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ms-xl-n2, .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }

  .m-xl-n3 {
    margin: -1rem !important;
  }

  .mt-xl-n3, .my-xl-n3 {
    margin-top: -1rem !important;
  }

  .me-xl-n3, .mx-xl-n3 {
    margin-right: -1rem !important;
  }

  .mb-xl-n3, .my-xl-n3 {
    margin-bottom: -1rem !important;
  }

  .ms-xl-n3, .mx-xl-n3 {
    margin-left: -1rem !important;
  }

  .m-xl-n4 {
    margin: -1.5rem !important;
  }

  .mt-xl-n4, .my-xl-n4 {
    margin-top: -1.5rem !important;
  }

  .me-xl-n4, .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-xl-n4, .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ms-xl-n4, .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }

  .m-xl-n5 {
    margin: -3rem !important;
  }

  .mt-xl-n5, .my-xl-n5 {
    margin-top: -3rem !important;
  }

  .me-xl-n5, .mx-xl-n5 {
    margin-right: -3rem !important;
  }

  .mb-xl-n5, .my-xl-n5 {
    margin-bottom: -3rem !important;
  }

  .ms-xl-n5, .mx-xl-n5 {
    margin-left: -3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mt-xl-auto, .my-xl-auto {
    margin-top: auto !important;
  }

  .me-xl-auto, .mx-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-auto, .my-xl-auto {
    margin-bottom: auto !important;
  }

  .ms-xl-auto, .mx-xl-auto {
    margin-left: auto !important;
  }
}

/* ###### 9.4 Margin   ###### */

.mg-0 {
  margin: 0px;
}

.mg-0-f {
  margin: 0px !important;
}

.mg-y-0 {
  margin-top: 0px;
  margin-bottom: 0px;
}

.mg-y-0-f {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.mg-x-0 {
  margin-left: 0px;
  margin-right: 0px;
}

.mg-x-0-f {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.mg-t-0 {
  margin-top: 0px;
}

.mg-r-0 {
  margin-right: 0px;
}

.mg-b-0 {
  margin-bottom: 0px;
}

.mg-l-0 {
  margin-left: 0px;
}

.mg-t-0-f {
  margin-top: 0px !important;
}

.mg-r-0-f {
  margin-right: 0px !important;
}

.mg-b-0-f {
  margin-bottom: 0px !important;
}

.mg-l-0-f {
  margin-left: 0px !important;
}

.mg-1 {
  margin: 1px;
}

.mg-1-f {
  margin: 1px !important;
}

.mg-y-1 {
  margin-top: 1px;
  margin-bottom: 1px;
}

.mg-y-1-f {
  margin-top: 1px !important;
  margin-bottom: 1px !important;
}

.mg-x-1 {
  margin-left: 1px;
  margin-right: 1px;
}

.mg-x-1-f {
  margin-left: 1px !important;
  margin-right: 1px !important;
}

.mg-t-1 {
  margin-top: 1px;
}

.mg-r-1 {
  margin-right: 1px;
}

.mg-b-1 {
  margin-bottom: 1px;
}

.mg-l-1 {
  margin-left: 1px;
}

.mg-t-1-f {
  margin-top: 1px !important;
}

.mg-r-1-f {
  margin-right: 1px !important;
}

.mg-b-1-f {
  margin-bottom: 1px !important;
}

.mg-l-1-f {
  margin-left: 1px !important;
}

.mg-2 {
  margin: 2px;
}

.mg-2-f {
  margin: 2px !important;
}

.mg-y-2 {
  margin-top: 2px;
  margin-bottom: 2px;
}

.mg-y-2-f {
  margin-top: 2px !important;
  margin-bottom: 2px !important;
}

.mg-x-2 {
  margin-left: 2px;
  margin-right: 2px;
}

.mg-x-2-f {
  margin-left: 2px !important;
  margin-right: 2px !important;
}

.mg-t-2 {
  margin-top: 2px;
}

.mg-r-2 {
  margin-right: 2px;
}

.mg-b-2 {
  margin-bottom: 2px;
}

.mg-l-2 {
  margin-left: 2px;
}

.mg-t-2-f {
  margin-top: 2px !important;
}

.mg-r-2-f {
  margin-right: 2px !important;
}

.mg-b-2-f {
  margin-bottom: 2px !important;
}

.mg-l-2-f {
  margin-left: 2px !important;
}

.mg-3 {
  margin: 3px;
}

.mg-3-f {
  margin: 3px !important;
}

.mg-y-3 {
  margin-top: 3px;
  margin-bottom: 3px;
}

.mg-y-3-f {
  margin-top: 3px !important;
  margin-bottom: 3px !important;
}

.mg-x-3 {
  margin-left: 3px;
  margin-right: 3px;
}

.mg-x-3-f {
  margin-left: 3px !important;
  margin-right: 3px !important;
}

.mg-t-3 {
  margin-top: 3px;
}

.mg-r-3 {
  margin-right: 3px;
}

.mg-b-3 {
  margin-bottom: 3px;
}

.mg-l-3 {
  margin-left: 3px;
}

.mg-t-3-f {
  margin-top: 3px !important;
}

.mg-r-3-f {
  margin-right: 3px !important;
}

.mg-b-3-f {
  margin-bottom: 3px !important;
}

.mg-l-3-f {
  margin-left: 3px !important;
}

.mg-4 {
  margin: 4px;
}

.mg-4-f {
  margin: 4px !important;
}

.mg-y-4 {
  margin-top: 4px;
  margin-bottom: 4px;
}

.mg-y-4-f {
  margin-top: 4px !important;
  margin-bottom: 4px !important;
}

.mg-x-4 {
  margin-left: 4px;
  margin-right: 4px;
}

.mg-x-4-f {
  margin-left: 4px !important;
  margin-right: 4px !important;
}

.mg-t-4 {
  margin-top: 4px;
}

.mg-r-4 {
  margin-right: 4px;
}

.mg-b-4 {
  margin-bottom: 4px;
}

.mg-l-4 {
  margin-left: 4px;
}

.mg-t-4-f {
  margin-top: 4px !important;
}

.mg-r-4-f {
  margin-right: 4px !important;
}

.mg-b-4-f {
  margin-bottom: 4px !important;
}

.mg-l-4-f {
  margin-left: 4px !important;
}

.mg-5 {
  margin: 5px;
}

.mg-5-f {
  margin: 5px !important;
}

.mg-y-5 {
  margin-top: 5px;
  margin-bottom: 5px;
}

.mg-y-5-f {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

.mg-x-5 {
  margin-left: 5px;
  margin-right: 5px;
}

.mg-x-5-f {
  margin-left: 5px !important;
  margin-right: 5px !important;
}

.mg-t-5 {
  margin-top: 5px;
}

.mg-r-5 {
  margin-right: 5px;
}

.mg-b-5 {
  margin-bottom: 5px;
}

.mg-l-5 {
  margin-left: 5px;
}

.mg-t-5-f {
  margin-top: 5px !important;
}

.mg-r-5-f {
  margin-right: 5px !important;
}

.mg-b-5-f {
  margin-bottom: 5px !important;
}

.mg-l-5-f {
  margin-left: 5px !important;
}

.mg-6 {
  margin: 6px;
}

.mg-6-f {
  margin: 6px !important;
}

.mg-y-6 {
  margin-top: 6px;
  margin-bottom: 6px;
}

.mg-y-6-f {
  margin-top: 6px !important;
  margin-bottom: 6px !important;
}

.mg-x-6 {
  margin-left: 6px;
  margin-right: 6px;
}

.mg-x-6-f {
  margin-left: 6px !important;
  margin-right: 6px !important;
}

.mg-t-6 {
  margin-top: 6px;
}

.mg-r-6 {
  margin-right: 6px;
}

.mg-b-6 {
  margin-bottom: 6px;
}

.mg-l-6 {
  margin-left: 6px;
}

.mg-t-6-f {
  margin-top: 6px !important;
}

.mg-r-6-f {
  margin-right: 6px !important;
}

.mg-b-6-f {
  margin-bottom: 6px !important;
}

.mg-l-6-f {
  margin-left: 6px !important;
}

.mg-7 {
  margin: 7px;
}

.mg-7-f {
  margin: 7px !important;
}

.mg-y-7 {
  margin-top: 7px;
  margin-bottom: 7px;
}

.mg-y-7-f {
  margin-top: 7px !important;
  margin-bottom: 7px !important;
}

.mg-x-7 {
  margin-left: 7px;
  margin-right: 7px;
}

.mg-x-7-f {
  margin-left: 7px !important;
  margin-right: 7px !important;
}

.mg-t-7 {
  margin-top: 7px;
}

.mg-r-7 {
  margin-right: 7px;
}

.mg-b-7 {
  margin-bottom: 7px;
}

.mg-l-7 {
  margin-left: 7px;
}

.mg-t-7-f {
  margin-top: 7px !important;
}

.mg-r-7-f {
  margin-right: 7px !important;
}

.mg-b-7-f {
  margin-bottom: 7px !important;
}

.mg-l-7-f {
  margin-left: 7px !important;
}

.mg-8 {
  margin: 8px;
}

.mg-8-f {
  margin: 8px !important;
}

.mg-y-8 {
  margin-top: 8px;
  margin-bottom: 8px;
}

.mg-y-8-f {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}

.mg-x-8 {
  margin-left: 8px;
  margin-right: 8px;
}

.mg-x-8-f {
  margin-left: 8px !important;
  margin-right: 8px !important;
}

.mg-t-8 {
  margin-top: 8px;
}

.mg-r-8 {
  margin-right: 8px;
}

.mg-b-8 {
  margin-bottom: 8px;
}

.mg-l-8 {
  margin-left: 8px;
}

.mg-t-8-f {
  margin-top: 8px !important;
}

.mg-r-8-f {
  margin-right: 8px !important;
}

.mg-b-8-f {
  margin-bottom: 8px !important;
}

.mg-l-8-f {
  margin-left: 8px !important;
}

.mg-9 {
  margin: 9px;
}

.mg-9-f {
  margin: 9px !important;
}

.mg-y-9 {
  margin-top: 9px;
  margin-bottom: 9px;
}

.mg-y-9-f {
  margin-top: 9px !important;
  margin-bottom: 9px !important;
}

.mg-x-9 {
  margin-left: 9px;
  margin-right: 9px;
}

.mg-x-9-f {
  margin-left: 9px !important;
  margin-right: 9px !important;
}

.mg-t-9 {
  margin-top: 9px;
}

.mg-r-9 {
  margin-right: 9px;
}

.mg-b-9 {
  margin-bottom: 9px;
}

.mg-l-9 {
  margin-left: 9px;
}

.mg-t-9-f {
  margin-top: 9px !important;
}

.mg-r-9-f {
  margin-right: 9px !important;
}

.mg-b-9-f {
  margin-bottom: 9px !important;
}

.mg-l-9-f {
  margin-left: 9px !important;
}

.mg-10 {
  margin: 10px;
}

.mg-10-f {
  margin: 10px !important;
}

.mg-y-10 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.mg-y-10-f {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.mg-x-10 {
  margin-left: 10px;
  margin-right: 10px;
}

.mg-x-10-f {
  margin-left: 10px !important;
  margin-right: 10px !important;
}

.mg-t-10 {
  margin-top: 10px;
}

.mg-r-10 {
  margin-right: 10px;
}

.mg-b-10 {
  margin-bottom: 10px;
}

.mg-l-10 {
  margin-left: 10px;
}

.mg-t-10-f {
  margin-top: 10px !important;
}

.mg-r-10-f {
  margin-right: 10px !important;
}

.mg-b-10-f {
  margin-bottom: 10px !important;
}

.mg-l-10-f {
  margin-left: 10px !important;
}

.mg-15 {
  margin: 15px;
}

.mg-15-f {
  margin: 15px !important;
}

.mg-y-15 {
  margin-top: 15px;
  margin-bottom: 15px;
}

.mg-y-15-f {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}

.mg-x-15 {
  margin-left: 15px;
  margin-right: 15px;
}

.mg-x-15-f {
  margin-left: 15px !important;
  margin-right: 15px !important;
}

.mg-t-15 {
  margin-top: 15px;
}

.mg-r-15 {
  margin-right: 15px;
}

.mg-b-15 {
  margin-bottom: 15px;
}

.mg-l-15 {
  margin-left: 15px;
}

.mg-t-15-f {
  margin-top: 15px !important;
}

.mg-r-15-f {
  margin-right: 15px !important;
}

.mg-b-15-f {
  margin-bottom: 15px !important;
}

.mg-l-15-f {
  margin-left: 15px !important;
}

.mg-20 {
  margin: 20px;
}

.mg-20-f {
  margin: 20px !important;
}

.mg-y-20 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.mg-y-20-f {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.mg-x-20 {
  margin-left: 20px;
  margin-right: 20px;
}

.mg-x-20-f {
  margin-left: 20px !important;
  margin-right: 20px !important;
}

.mg-t-20 {
  margin-top: 20px;
}

.mg-r-20 {
  margin-right: 20px;
}

.mg-b-20 {
  margin-bottom: 20px;
}

.mg-l-20 {
  margin-left: 20px;
}

.mg-t-20-f {
  margin-top: 20px !important;
}

.mg-r-20-f {
  margin-right: 20px !important;
}

.mg-b-20-f {
  margin-bottom: 20px !important;
}

.mg-l-20-f {
  margin-left: 20px !important;
}

.mg-25 {
  margin: 25px;
}

.mg-25-f {
  margin: 25px !important;
}

.mg-y-25 {
  margin-top: 25px;
  margin-bottom: 25px;
}

.mg-y-25-f {
  margin-top: 25px !important;
  margin-bottom: 25px !important;
}

.mg-x-25 {
  margin-left: 25px;
  margin-right: 25px;
}

.mg-x-25-f {
  margin-left: 25px !important;
  margin-right: 25px !important;
}

.mg-t-25 {
  margin-top: 25px;
}

.mg-r-25 {
  margin-right: 25px;
}

.mg-b-25 {
  margin-bottom: 25px;
}

.mg-l-25 {
  margin-left: 25px;
}

.mg-t-25-f {
  margin-top: 25px !important;
}

.mg-r-25-f {
  margin-right: 25px !important;
}

.mg-b-25-f {
  margin-bottom: 25px !important;
}

.mg-l-25-f {
  margin-left: 25px !important;
}

.mg-30 {
  margin: 30px;
}

.mg-30-f {
  margin: 30px !important;
}

.mg-y-30 {
  margin-top: 30px;
  margin-bottom: 30px;
}

.mg-y-30-f {
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}

.mg-x-30 {
  margin-left: 30px;
  margin-right: 30px;
}

.mg-x-30-f {
  margin-left: 30px !important;
  margin-right: 30px !important;
}

.mg-t-30 {
  margin-top: 30px;
}

.mg-r-30 {
  margin-right: 30px;
}

.mg-b-30 {
  margin-bottom: 30px;
}

.mg-l-30 {
  margin-left: 30px;
}

.mg-t-30-f {
  margin-top: 30px !important;
}

.mg-r-30-f {
  margin-right: 30px !important;
}

.mg-b-30-f {
  margin-bottom: 30px !important;
}

.mg-l-30-f {
  margin-left: 30px !important;
}

.mg-35 {
  margin: 35px;
}

.mg-35-f {
  margin: 35px !important;
}

.mg-y-35 {
  margin-top: 35px;
  margin-bottom: 35px;
}

.mg-y-35-f {
  margin-top: 35px !important;
  margin-bottom: 35px !important;
}

.mg-x-35 {
  margin-left: 35px;
  margin-right: 35px;
}

.mg-x-35-f {
  margin-left: 35px !important;
  margin-right: 35px !important;
}

.mg-t-35 {
  margin-top: 35px;
}

.mg-r-35 {
  margin-right: 35px;
}

.mg-b-35 {
  margin-bottom: 35px;
}

.mg-l-35 {
  margin-left: 35px;
}

.mg-t-35-f {
  margin-top: 35px !important;
}

.mg-r-35-f {
  margin-right: 35px !important;
}

.mg-b-35-f {
  margin-bottom: 35px !important;
}

.mg-l-35-f {
  margin-left: 35px !important;
}

.mg-40 {
  margin: 40px;
}

.mg-40-f {
  margin: 40px !important;
}

.mg-y-40 {
  margin-top: 40px;
  margin-bottom: 40px;
}

.mg-y-40-f {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}

.mg-x-40 {
  margin-left: 40px;
  margin-right: 40px;
}

.mg-x-40-f {
  margin-left: 40px !important;
  margin-right: 40px !important;
}

.mg-t-40 {
  margin-top: 40px;
}

.mg-r-40 {
  margin-right: 40px;
}

.mg-b-40 {
  margin-bottom: 40px;
}

.mg-l-40 {
  margin-left: 40px;
}

.mg-t-40-f {
  margin-top: 40px !important;
}

.mg-r-40-f {
  margin-right: 40px !important;
}

.mg-b-40-f {
  margin-bottom: 40px !important;
}

.mg-l-40-f {
  margin-left: 40px !important;
}

.mg-45 {
  margin: 45px;
}

.mg-45-f {
  margin: 45px !important;
}

.mg-y-45 {
  margin-top: 45px;
  margin-bottom: 45px;
}

.mg-y-45-f {
  margin-top: 45px !important;
  margin-bottom: 45px !important;
}

.mg-x-45 {
  margin-left: 45px;
  margin-right: 45px;
}

.mg-x-45-f {
  margin-left: 45px !important;
  margin-right: 45px !important;
}

.mg-t-45 {
  margin-top: 45px;
}

.mg-r-45 {
  margin-right: 45px;
}

.mg-b-45 {
  margin-bottom: 45px;
}

.mg-l-45 {
  margin-left: 45px;
}

.mg-t-45-f {
  margin-top: 45px !important;
}

.mg-r-45-f {
  margin-right: 45px !important;
}

.mg-b-45-f {
  margin-bottom: 45px !important;
}

.mg-l-45-f {
  margin-left: 45px !important;
}

.mg-50 {
  margin: 50px;
}

.mg-50-f {
  margin: 50px !important;
}

.mg-y-50 {
  margin-top: 50px;
  margin-bottom: 50px;
}

.mg-y-50-f {
  margin-top: 50px !important;
  margin-bottom: 50px !important;
}

.mg-x-50 {
  margin-left: 50px;
  margin-right: 50px;
}

.mg-x-50-f {
  margin-left: 50px !important;
  margin-right: 50px !important;
}

.mg-t-50 {
  margin-top: 50px;
}

.mg-r-50 {
  margin-right: 50px;
}

.mg-b-50 {
  margin-bottom: 50px;
}

.mg-l-50 {
  margin-left: 50px;
}

.mg-t-50-f {
  margin-top: 50px !important;
}

.mg-r-50-f {
  margin-right: 50px !important;
}

.mg-b-50-f {
  margin-bottom: 50px !important;
}

.mg-l-50-f {
  margin-left: 50px !important;
}

.mg-55 {
  margin: 55px;
}

.mg-55-f {
  margin: 55px !important;
}

.mg-y-55 {
  margin-top: 55px;
  margin-bottom: 55px;
}

.mg-y-55-f {
  margin-top: 55px !important;
  margin-bottom: 55px !important;
}

.mg-x-55 {
  margin-left: 55px;
  margin-right: 55px;
}

.mg-x-55-f {
  margin-left: 55px !important;
  margin-right: 55px !important;
}

.mg-t-55 {
  margin-top: 55px;
}

.mg-r-55 {
  margin-right: 55px;
}

.mg-b-55 {
  margin-bottom: 55px;
}

.mg-l-55 {
  margin-left: 55px;
}

.mg-t-55-f {
  margin-top: 55px !important;
}

.mg-r-55-f {
  margin-right: 55px !important;
}

.mg-b-55-f {
  margin-bottom: 55px !important;
}

.mg-l-55-f {
  margin-left: 55px !important;
}

.mg-60 {
  margin: 60px;
}

.mg-60-f {
  margin: 60px !important;
}

.mg-y-60 {
  margin-top: 60px;
  margin-bottom: 60px;
}

.mg-y-60-f {
  margin-top: 60px !important;
  margin-bottom: 60px !important;
}

.mg-x-60 {
  margin-left: 60px;
  margin-right: 60px;
}

.mg-x-60-f {
  margin-left: 60px !important;
  margin-right: 60px !important;
}

.mg-t-60 {
  margin-top: 60px;
}

.mg-r-60 {
  margin-right: 60px;
}

.mg-b-60 {
  margin-bottom: 60px;
}

.mg-l-60 {
  margin-left: 60px;
}

.mg-t-60-f {
  margin-top: 60px !important;
}

.mg-r-60-f {
  margin-right: 60px !important;
}

.mg-b-60-f {
  margin-bottom: 60px !important;
}

.mg-l-60-f {
  margin-left: 60px !important;
}

.mg-65 {
  margin: 65px;
}

.mg-65-f {
  margin: 65px !important;
}

.mg-y-65 {
  margin-top: 65px;
  margin-bottom: 65px;
}

.mg-y-65-f {
  margin-top: 65px !important;
  margin-bottom: 65px !important;
}

.mg-x-65 {
  margin-left: 65px;
  margin-right: 65px;
}

.mg-x-65-f {
  margin-left: 65px !important;
  margin-right: 65px !important;
}

.mg-t-65 {
  margin-top: 65px;
}

.mg-r-65 {
  margin-right: 65px;
}

.mg-b-65 {
  margin-bottom: 65px;
}

.mg-l-65 {
  margin-left: 65px;
}

.mg-t-65-f {
  margin-top: 65px !important;
}

.mg-r-65-f {
  margin-right: 65px !important;
}

.mg-b-65-f {
  margin-bottom: 65px !important;
}

.mg-l-65-f {
  margin-left: 65px !important;
}

.mg-70 {
  margin: 70px;
}

.mg-70-f {
  margin: 70px !important;
}

.mg-y-70 {
  margin-top: 70px;
  margin-bottom: 70px;
}

.mg-y-70-f {
  margin-top: 70px !important;
  margin-bottom: 70px !important;
}

.mg-x-70 {
  margin-left: 70px;
  margin-right: 70px;
}

.mg-x-70-f {
  margin-left: 70px !important;
  margin-right: 70px !important;
}

.mg-t-70 {
  margin-top: 70px;
}

.mg-r-70 {
  margin-right: 70px;
}

.mg-b-70 {
  margin-bottom: 70px;
}

.mg-l-70 {
  margin-left: 70px;
}

.mg-t-70-f {
  margin-top: 70px !important;
}

.mg-r-70-f {
  margin-right: 70px !important;
}

.mg-b-70-f {
  margin-bottom: 70px !important;
}

.mg-l-70-f {
  margin-left: 70px !important;
}

.mg-75 {
  margin: 75px;
}

.mg-75-f {
  margin: 75px !important;
}

.mg-y-75 {
  margin-top: 75px;
  margin-bottom: 75px;
}

.mg-y-75-f {
  margin-top: 75px !important;
  margin-bottom: 75px !important;
}

.mg-x-75 {
  margin-left: 75px;
  margin-right: 75px;
}

.mg-x-75-f {
  margin-left: 75px !important;
  margin-right: 75px !important;
}

.mg-t-75 {
  margin-top: 75px;
}

.mg-r-75 {
  margin-right: 75px;
}

.mg-b-75 {
  margin-bottom: 75px;
}

.mg-l-75 {
  margin-left: 75px;
}

.mg-t-75-f {
  margin-top: 75px !important;
}

.mg-r-75-f {
  margin-right: 75px !important;
}

.mg-b-75-f {
  margin-bottom: 75px !important;
}

.mg-l-75-f {
  margin-left: 75px !important;
}

.mg-80 {
  margin: 80px;
}

.mg-80-f {
  margin: 80px !important;
}

.mg-y-80 {
  margin-top: 80px;
  margin-bottom: 80px;
}

.mg-y-80-f {
  margin-top: 80px !important;
  margin-bottom: 80px !important;
}

.mg-x-80 {
  margin-left: 80px;
  margin-right: 80px;
}

.mg-x-80-f {
  margin-left: 80px !important;
  margin-right: 80px !important;
}

.mg-t-80 {
  margin-top: 80px;
}

.mg-r-80 {
  margin-right: 80px;
}

.mg-b-80 {
  margin-bottom: 80px;
}

.mg-l-80 {
  margin-left: 80px;
}

.mg-t-80-f {
  margin-top: 80px !important;
}

.mg-r-80-f {
  margin-right: 80px !important;
}

.mg-b-80-f {
  margin-bottom: 80px !important;
}

.mg-l-80-f {
  margin-left: 80px !important;
}

.mg-85 {
  margin: 85px;
}

.mg-85-f {
  margin: 85px !important;
}

.mg-y-85 {
  margin-top: 85px;
  margin-bottom: 85px;
}

.mg-y-85-f {
  margin-top: 85px !important;
  margin-bottom: 85px !important;
}

.mg-x-85 {
  margin-left: 85px;
  margin-right: 85px;
}

.mg-x-85-f {
  margin-left: 85px !important;
  margin-right: 85px !important;
}

.mg-t-85 {
  margin-top: 85px;
}

.mg-r-85 {
  margin-right: 85px;
}

.mg-b-85 {
  margin-bottom: 85px;
}

.mg-l-85 {
  margin-left: 85px;
}

.mg-t-85-f {
  margin-top: 85px !important;
}

.mg-r-85-f {
  margin-right: 85px !important;
}

.mg-b-85-f {
  margin-bottom: 85px !important;
}

.mg-l-85-f {
  margin-left: 85px !important;
}

.mg-90 {
  margin: 90px;
}

.mg-90-f {
  margin: 90px !important;
}

.mg-y-90 {
  margin-top: 90px;
  margin-bottom: 90px;
}

.mg-y-90-f {
  margin-top: 90px !important;
  margin-bottom: 90px !important;
}

.mg-x-90 {
  margin-left: 90px;
  margin-right: 90px;
}

.mg-x-90-f {
  margin-left: 90px !important;
  margin-right: 90px !important;
}

.mg-t-90 {
  margin-top: 90px;
}

.mg-r-90 {
  margin-right: 90px;
}

.mg-b-90 {
  margin-bottom: 90px;
}

.mg-l-90 {
  margin-left: 90px;
}

.mg-t-90-f {
  margin-top: 90px !important;
}

.mg-r-90-f {
  margin-right: 90px !important;
}

.mg-b-90-f {
  margin-bottom: 90px !important;
}

.mg-l-90-f {
  margin-left: 90px !important;
}

.mg-95 {
  margin: 95px;
}

.mg-95-f {
  margin: 95px !important;
}

.mg-y-95 {
  margin-top: 95px;
  margin-bottom: 95px;
}

.mg-y-95-f {
  margin-top: 95px !important;
  margin-bottom: 95px !important;
}

.mg-x-95 {
  margin-left: 95px;
  margin-right: 95px;
}

.mg-x-95-f {
  margin-left: 95px !important;
  margin-right: 95px !important;
}

.mg-t-95 {
  margin-top: 95px;
}

.mg-r-95 {
  margin-right: 95px;
}

.mg-b-95 {
  margin-bottom: 95px;
}

.mg-l-95 {
  margin-left: 95px;
}

.mg-t-95-f {
  margin-top: 95px !important;
}

.mg-r-95-f {
  margin-right: 95px !important;
}

.mg-b-95-f {
  margin-bottom: 95px !important;
}

.mg-l-95-f {
  margin-left: 95px !important;
}

.mg-100 {
  margin: 100px;
}

.mg-100-f {
  margin: 100px !important;
}

.mg-y-100 {
  margin-top: 100px;
  margin-bottom: 100px;
}

.mg-y-100-f {
  margin-top: 100px !important;
  margin-bottom: 100px !important;
}

.mg-x-100 {
  margin-left: 100px;
  margin-right: 100px;
}

.mg-x-100-f {
  margin-left: 100px !important;
  margin-right: 100px !important;
}

.mg-t-100 {
  margin-top: 100px;
}

.mg-r-100 {
  margin-right: 100px;
}

.mg-b-100 {
  margin-bottom: 100px;
}

.mg-l-100 {
  margin-left: 100px;
}

.mg-t-100-f {
  margin-top: 100px !important;
}

.mg-r-100-f {
  margin-right: 100px !important;
}

.mg-b-100-f {
  margin-bottom: 100px !important;
}

.mg-l-100-f {
  margin-left: 100px !important;
}

.mg-105 {
  margin: 105px;
}

.mg-105-f {
  margin: 105px !important;
}

.mg-y-105 {
  margin-top: 105px;
  margin-bottom: 105px;
}

.mg-y-105-f {
  margin-top: 105px !important;
  margin-bottom: 105px !important;
}

.mg-x-105 {
  margin-left: 105px;
  margin-right: 105px;
}

.mg-x-105-f {
  margin-left: 105px !important;
  margin-right: 105px !important;
}

.mg-t-105 {
  margin-top: 105px;
}

.mg-r-105 {
  margin-right: 105px;
}

.mg-b-105 {
  margin-bottom: 105px;
}

.mg-l-105 {
  margin-left: 105px;
}

.mg-t-105-f {
  margin-top: 105px !important;
}

.mg-r-105-f {
  margin-right: 105px !important;
}

.mg-b-105-f {
  margin-bottom: 105px !important;
}

.mg-l-105-f {
  margin-left: 105px !important;
}

.mg-110 {
  margin: 110px;
}

.mg-110-f {
  margin: 110px !important;
}

.mg-y-110 {
  margin-top: 110px;
  margin-bottom: 110px;
}

.mg-y-110-f {
  margin-top: 110px !important;
  margin-bottom: 110px !important;
}

.mg-x-110 {
  margin-left: 110px;
  margin-right: 110px;
}

.mg-x-110-f {
  margin-left: 110px !important;
  margin-right: 110px !important;
}

.mg-t-110 {
  margin-top: 110px;
}

.mg-r-110 {
  margin-right: 110px;
}

.mg-b-110 {
  margin-bottom: 110px;
}

.mg-l-110 {
  margin-left: 110px;
}

.mg-t-110-f {
  margin-top: 110px !important;
}

.mg-r-110-f {
  margin-right: 110px !important;
}

.mg-b-110-f {
  margin-bottom: 110px !important;
}

.mg-l-110-f {
  margin-left: 110px !important;
}

.mg-115 {
  margin: 115px;
}

.mg-115-f {
  margin: 115px !important;
}

.mg-y-115 {
  margin-top: 115px;
  margin-bottom: 115px;
}

.mg-y-115-f {
  margin-top: 115px !important;
  margin-bottom: 115px !important;
}

.mg-x-115 {
  margin-left: 115px;
  margin-right: 115px;
}

.mg-x-115-f {
  margin-left: 115px !important;
  margin-right: 115px !important;
}

.mg-t-115 {
  margin-top: 115px;
}

.mg-r-115 {
  margin-right: 115px;
}

.mg-b-115 {
  margin-bottom: 115px;
}

.mg-l-115 {
  margin-left: 115px;
}

.mg-t-115-f {
  margin-top: 115px !important;
}

.mg-r-115-f {
  margin-right: 115px !important;
}

.mg-b-115-f {
  margin-bottom: 115px !important;
}

.mg-l-115-f {
  margin-left: 115px !important;
}

.mg-120 {
  margin: 120px;
}

.mg-120-f {
  margin: 120px !important;
}

.mg-y-120 {
  margin-top: 120px;
  margin-bottom: 120px;
}

.mg-y-120-f {
  margin-top: 120px !important;
  margin-bottom: 120px !important;
}

.mg-x-120 {
  margin-left: 120px;
  margin-right: 120px;
}

.mg-x-120-f {
  margin-left: 120px !important;
  margin-right: 120px !important;
}

.mg-t-120 {
  margin-top: 120px;
}

.mg-r-120 {
  margin-right: 120px;
}

.mg-b-120 {
  margin-bottom: 120px;
}

.mg-l-120 {
  margin-left: 120px;
}

.mg-t-120-f {
  margin-top: 120px !important;
}

.mg-r-120-f {
  margin-right: 120px !important;
}

.mg-b-120-f {
  margin-bottom: 120px !important;
}

.mg-l-120-f {
  margin-left: 120px !important;
}

.mg-t-auto {
  margin-top: auto;
}

.mg-r-auto {
  margin-right: auto;
}

.mg-b-auto {
  margin-bottom: auto;
}

.mg-l-auto {
  margin-left: auto;
}

.mg-x-auto {
  margin: auto;
}

@media (min-width: 480px) {
  .mg-xs-0 {
    margin: 0px;
  }

  .mg-xs-0-f {
    margin: 0px !important;
  }

  .mg-xs-y-0 {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .mg-xs-y-0-f {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }

  .mg-xs-x-0 {
    margin-left: 0px;
    margin-right: 0px;
  }

  .mg-xs-x-0-f {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .mg-xs-t-0 {
    margin-top: 0px;
  }

  .mg-xs-r-0 {
    margin-right: 0px;
  }

  .mg-xs-b-0 {
    margin-bottom: 0px;
  }

  .mg-xs-l-0 {
    margin-left: 0px;
  }

  .mg-xs-t-0-f {
    margin-top: 0px !important;
  }

  .mg-xs-r-0-f {
    margin-right: 0px !important;
  }

  .mg-xs-b-0-f {
    margin-bottom: 0px !important;
  }

  .mg-xs-l-0-f {
    margin-left: 0px !important;
  }

  .mg-xs-1 {
    margin: 1px;
  }

  .mg-xs-1-f {
    margin: 1px !important;
  }

  .mg-xs-y-1 {
    margin-top: 1px;
    margin-bottom: 1px;
  }

  .mg-xs-y-1-f {
    margin-top: 1px !important;
    margin-bottom: 1px !important;
  }

  .mg-xs-x-1 {
    margin-left: 1px;
    margin-right: 1px;
  }

  .mg-xs-x-1-f {
    margin-left: 1px !important;
    margin-right: 1px !important;
  }

  .mg-xs-t-1 {
    margin-top: 1px;
  }

  .mg-xs-r-1 {
    margin-right: 1px;
  }

  .mg-xs-b-1 {
    margin-bottom: 1px;
  }

  .mg-xs-l-1 {
    margin-left: 1px;
  }

  .mg-xs-t-1-f {
    margin-top: 1px !important;
  }

  .mg-xs-r-1-f {
    margin-right: 1px !important;
  }

  .mg-xs-b-1-f {
    margin-bottom: 1px !important;
  }

  .mg-xs-l-1-f {
    margin-left: 1px !important;
  }

  .mg-xs-2 {
    margin: 2px;
  }

  .mg-xs-2-f {
    margin: 2px !important;
  }

  .mg-xs-y-2 {
    margin-top: 2px;
    margin-bottom: 2px;
  }

  .mg-xs-y-2-f {
    margin-top: 2px !important;
    margin-bottom: 2px !important;
  }

  .mg-xs-x-2 {
    margin-left: 2px;
    margin-right: 2px;
  }

  .mg-xs-x-2-f {
    margin-left: 2px !important;
    margin-right: 2px !important;
  }

  .mg-xs-t-2 {
    margin-top: 2px;
  }

  .mg-xs-r-2 {
    margin-right: 2px;
  }

  .mg-xs-b-2 {
    margin-bottom: 2px;
  }

  .mg-xs-l-2 {
    margin-left: 2px;
  }

  .mg-xs-t-2-f {
    margin-top: 2px !important;
  }

  .mg-xs-r-2-f {
    margin-right: 2px !important;
  }

  .mg-xs-b-2-f {
    margin-bottom: 2px !important;
  }

  .mg-xs-l-2-f {
    margin-left: 2px !important;
  }

  .mg-xs-3 {
    margin: 3px;
  }

  .mg-xs-3-f {
    margin: 3px !important;
  }

  .mg-xs-y-3 {
    margin-top: 3px;
    margin-bottom: 3px;
  }

  .mg-xs-y-3-f {
    margin-top: 3px !important;
    margin-bottom: 3px !important;
  }

  .mg-xs-x-3 {
    margin-left: 3px;
    margin-right: 3px;
  }

  .mg-xs-x-3-f {
    margin-left: 3px !important;
    margin-right: 3px !important;
  }

  .mg-xs-t-3 {
    margin-top: 3px;
  }

  .mg-xs-r-3 {
    margin-right: 3px;
  }

  .mg-xs-b-3 {
    margin-bottom: 3px;
  }

  .mg-xs-l-3 {
    margin-left: 3px;
  }

  .mg-xs-t-3-f {
    margin-top: 3px !important;
  }

  .mg-xs-r-3-f {
    margin-right: 3px !important;
  }

  .mg-xs-b-3-f {
    margin-bottom: 3px !important;
  }

  .mg-xs-l-3-f {
    margin-left: 3px !important;
  }

  .mg-xs-4 {
    margin: 4px;
  }

  .mg-xs-4-f {
    margin: 4px !important;
  }

  .mg-xs-y-4 {
    margin-top: 4px;
    margin-bottom: 4px;
  }

  .mg-xs-y-4-f {
    margin-top: 4px !important;
    margin-bottom: 4px !important;
  }

  .mg-xs-x-4 {
    margin-left: 4px;
    margin-right: 4px;
  }

  .mg-xs-x-4-f {
    margin-left: 4px !important;
    margin-right: 4px !important;
  }

  .mg-xs-t-4 {
    margin-top: 4px;
  }

  .mg-xs-r-4 {
    margin-right: 4px;
  }

  .mg-xs-b-4 {
    margin-bottom: 4px;
  }

  .mg-xs-l-4 {
    margin-left: 4px;
  }

  .mg-xs-t-4-f {
    margin-top: 4px !important;
  }

  .mg-xs-r-4-f {
    margin-right: 4px !important;
  }

  .mg-xs-b-4-f {
    margin-bottom: 4px !important;
  }

  .mg-xs-l-4-f {
    margin-left: 4px !important;
  }

  .mg-xs-5 {
    margin: 5px;
  }

  .mg-xs-5-f {
    margin: 5px !important;
  }

  .mg-xs-y-5 {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .mg-xs-y-5-f {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }

  .mg-xs-x-5 {
    margin-left: 5px;
    margin-right: 5px;
  }

  .mg-xs-x-5-f {
    margin-left: 5px !important;
    margin-right: 5px !important;
  }

  .mg-xs-t-5 {
    margin-top: 5px;
  }

  .mg-xs-r-5 {
    margin-right: 5px;
  }

  .mg-xs-b-5 {
    margin-bottom: 5px;
  }

  .mg-xs-l-5 {
    margin-left: 5px;
  }

  .mg-xs-t-5-f {
    margin-top: 5px !important;
  }

  .mg-xs-r-5-f {
    margin-right: 5px !important;
  }

  .mg-xs-b-5-f {
    margin-bottom: 5px !important;
  }

  .mg-xs-l-5-f {
    margin-left: 5px !important;
  }

  .mg-xs-6 {
    margin: 6px;
  }

  .mg-xs-6-f {
    margin: 6px !important;
  }

  .mg-xs-y-6 {
    margin-top: 6px;
    margin-bottom: 6px;
  }

  .mg-xs-y-6-f {
    margin-top: 6px !important;
    margin-bottom: 6px !important;
  }

  .mg-xs-x-6 {
    margin-left: 6px;
    margin-right: 6px;
  }

  .mg-xs-x-6-f {
    margin-left: 6px !important;
    margin-right: 6px !important;
  }

  .mg-xs-t-6 {
    margin-top: 6px;
  }

  .mg-xs-r-6 {
    margin-right: 6px;
  }

  .mg-xs-b-6 {
    margin-bottom: 6px;
  }

  .mg-xs-l-6 {
    margin-left: 6px;
  }

  .mg-xs-t-6-f {
    margin-top: 6px !important;
  }

  .mg-xs-r-6-f {
    margin-right: 6px !important;
  }

  .mg-xs-b-6-f {
    margin-bottom: 6px !important;
  }

  .mg-xs-l-6-f {
    margin-left: 6px !important;
  }

  .mg-xs-7 {
    margin: 7px;
  }

  .mg-xs-7-f {
    margin: 7px !important;
  }

  .mg-xs-y-7 {
    margin-top: 7px;
    margin-bottom: 7px;
  }

  .mg-xs-y-7-f {
    margin-top: 7px !important;
    margin-bottom: 7px !important;
  }

  .mg-xs-x-7 {
    margin-left: 7px;
    margin-right: 7px;
  }

  .mg-xs-x-7-f {
    margin-left: 7px !important;
    margin-right: 7px !important;
  }

  .mg-xs-t-7 {
    margin-top: 7px;
  }

  .mg-xs-r-7 {
    margin-right: 7px;
  }

  .mg-xs-b-7 {
    margin-bottom: 7px;
  }

  .mg-xs-l-7 {
    margin-left: 7px;
  }

  .mg-xs-t-7-f {
    margin-top: 7px !important;
  }

  .mg-xs-r-7-f {
    margin-right: 7px !important;
  }

  .mg-xs-b-7-f {
    margin-bottom: 7px !important;
  }

  .mg-xs-l-7-f {
    margin-left: 7px !important;
  }

  .mg-xs-8 {
    margin: 8px;
  }

  .mg-xs-8-f {
    margin: 8px !important;
  }

  .mg-xs-y-8 {
    margin-top: 8px;
    margin-bottom: 8px;
  }

  .mg-xs-y-8-f {
    margin-top: 8px !important;
    margin-bottom: 8px !important;
  }

  .mg-xs-x-8 {
    margin-left: 8px;
    margin-right: 8px;
  }

  .mg-xs-x-8-f {
    margin-left: 8px !important;
    margin-right: 8px !important;
  }

  .mg-xs-t-8 {
    margin-top: 8px;
  }

  .mg-xs-r-8 {
    margin-right: 8px;
  }

  .mg-xs-b-8 {
    margin-bottom: 8px;
  }

  .mg-xs-l-8 {
    margin-left: 8px;
  }

  .mg-xs-t-8-f {
    margin-top: 8px !important;
  }

  .mg-xs-r-8-f {
    margin-right: 8px !important;
  }

  .mg-xs-b-8-f {
    margin-bottom: 8px !important;
  }

  .mg-xs-l-8-f {
    margin-left: 8px !important;
  }

  .mg-xs-9 {
    margin: 9px;
  }

  .mg-xs-9-f {
    margin: 9px !important;
  }

  .mg-xs-y-9 {
    margin-top: 9px;
    margin-bottom: 9px;
  }

  .mg-xs-y-9-f {
    margin-top: 9px !important;
    margin-bottom: 9px !important;
  }

  .mg-xs-x-9 {
    margin-left: 9px;
    margin-right: 9px;
  }

  .mg-xs-x-9-f {
    margin-left: 9px !important;
    margin-right: 9px !important;
  }

  .mg-xs-t-9 {
    margin-top: 9px;
  }

  .mg-xs-r-9 {
    margin-right: 9px;
  }

  .mg-xs-b-9 {
    margin-bottom: 9px;
  }

  .mg-xs-l-9 {
    margin-left: 9px;
  }

  .mg-xs-t-9-f {
    margin-top: 9px !important;
  }

  .mg-xs-r-9-f {
    margin-right: 9px !important;
  }

  .mg-xs-b-9-f {
    margin-bottom: 9px !important;
  }

  .mg-xs-l-9-f {
    margin-left: 9px !important;
  }

  .mg-xs-10 {
    margin: 10px;
  }

  .mg-xs-10-f {
    margin: 10px !important;
  }

  .mg-xs-y-10 {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .mg-xs-y-10-f {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }

  .mg-xs-x-10 {
    margin-left: 10px;
    margin-right: 10px;
  }

  .mg-xs-x-10-f {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }

  .mg-xs-t-10 {
    margin-top: 10px;
  }

  .mg-xs-r-10 {
    margin-right: 10px;
  }

  .mg-xs-b-10 {
    margin-bottom: 10px;
  }

  .mg-xs-l-10 {
    margin-left: 10px;
  }

  .mg-xs-t-10-f {
    margin-top: 10px !important;
  }

  .mg-xs-r-10-f {
    margin-right: 10px !important;
  }

  .mg-xs-b-10-f {
    margin-bottom: 10px !important;
  }

  .mg-xs-l-10-f {
    margin-left: 10px !important;
  }

  .mg-xs-15 {
    margin: 15px;
  }

  .mg-xs-15-f {
    margin: 15px !important;
  }

  .mg-xs-y-15 {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .mg-xs-y-15-f {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }

  .mg-xs-x-15 {
    margin-left: 15px;
    margin-right: 15px;
  }

  .mg-xs-x-15-f {
    margin-left: 15px !important;
    margin-right: 15px !important;
  }

  .mg-xs-t-15 {
    margin-top: 15px;
  }

  .mg-xs-r-15 {
    margin-right: 15px;
  }

  .mg-xs-b-15 {
    margin-bottom: 15px;
  }

  .mg-xs-l-15 {
    margin-left: 15px;
  }

  .mg-xs-t-15-f {
    margin-top: 15px !important;
  }

  .mg-xs-r-15-f {
    margin-right: 15px !important;
  }

  .mg-xs-b-15-f {
    margin-bottom: 15px !important;
  }

  .mg-xs-l-15-f {
    margin-left: 15px !important;
  }

  .mg-xs-20 {
    margin: 20px;
  }

  .mg-xs-20-f {
    margin: 20px !important;
  }

  .mg-xs-y-20 {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .mg-xs-y-20-f {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }

  .mg-xs-x-20 {
    margin-left: 20px;
    margin-right: 20px;
  }

  .mg-xs-x-20-f {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }

  .mg-xs-t-20 {
    margin-top: 20px;
  }

  .mg-xs-r-20 {
    margin-right: 20px;
  }

  .mg-xs-b-20 {
    margin-bottom: 20px;
  }

  .mg-xs-l-20 {
    margin-left: 20px;
  }

  .mg-xs-t-20-f {
    margin-top: 20px !important;
  }

  .mg-xs-r-20-f {
    margin-right: 20px !important;
  }

  .mg-xs-b-20-f {
    margin-bottom: 20px !important;
  }

  .mg-xs-l-20-f {
    margin-left: 20px !important;
  }

  .mg-xs-25 {
    margin: 25px;
  }

  .mg-xs-25-f {
    margin: 25px !important;
  }

  .mg-xs-y-25 {
    margin-top: 25px;
    margin-bottom: 25px;
  }

  .mg-xs-y-25-f {
    margin-top: 25px !important;
    margin-bottom: 25px !important;
  }

  .mg-xs-x-25 {
    margin-left: 25px;
    margin-right: 25px;
  }

  .mg-xs-x-25-f {
    margin-left: 25px !important;
    margin-right: 25px !important;
  }

  .mg-xs-t-25 {
    margin-top: 25px;
  }

  .mg-xs-r-25 {
    margin-right: 25px;
  }

  .mg-xs-b-25 {
    margin-bottom: 25px;
  }

  .mg-xs-l-25 {
    margin-left: 25px;
  }

  .mg-xs-t-25-f {
    margin-top: 25px !important;
  }

  .mg-xs-r-25-f {
    margin-right: 25px !important;
  }

  .mg-xs-b-25-f {
    margin-bottom: 25px !important;
  }

  .mg-xs-l-25-f {
    margin-left: 25px !important;
  }

  .mg-xs-30 {
    margin: 30px;
  }

  .mg-xs-30-f {
    margin: 30px !important;
  }

  .mg-xs-y-30 {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .mg-xs-y-30-f {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }

  .mg-xs-x-30 {
    margin-left: 30px;
    margin-right: 30px;
  }

  .mg-xs-x-30-f {
    margin-left: 30px !important;
    margin-right: 30px !important;
  }

  .mg-xs-t-30 {
    margin-top: 30px;
  }

  .mg-xs-r-30 {
    margin-right: 30px;
  }

  .mg-xs-b-30 {
    margin-bottom: 30px;
  }

  .mg-xs-l-30 {
    margin-left: 30px;
  }

  .mg-xs-t-30-f {
    margin-top: 30px !important;
  }

  .mg-xs-r-30-f {
    margin-right: 30px !important;
  }

  .mg-xs-b-30-f {
    margin-bottom: 30px !important;
  }

  .mg-xs-l-30-f {
    margin-left: 30px !important;
  }

  .mg-xs-35 {
    margin: 35px;
  }

  .mg-xs-35-f {
    margin: 35px !important;
  }

  .mg-xs-y-35 {
    margin-top: 35px;
    margin-bottom: 35px;
  }

  .mg-xs-y-35-f {
    margin-top: 35px !important;
    margin-bottom: 35px !important;
  }

  .mg-xs-x-35 {
    margin-left: 35px;
    margin-right: 35px;
  }

  .mg-xs-x-35-f {
    margin-left: 35px !important;
    margin-right: 35px !important;
  }

  .mg-xs-t-35 {
    margin-top: 35px;
  }

  .mg-xs-r-35 {
    margin-right: 35px;
  }

  .mg-xs-b-35 {
    margin-bottom: 35px;
  }

  .mg-xs-l-35 {
    margin-left: 35px;
  }

  .mg-xs-t-35-f {
    margin-top: 35px !important;
  }

  .mg-xs-r-35-f {
    margin-right: 35px !important;
  }

  .mg-xs-b-35-f {
    margin-bottom: 35px !important;
  }

  .mg-xs-l-35-f {
    margin-left: 35px !important;
  }

  .mg-xs-40 {
    margin: 40px;
  }

  .mg-xs-40-f {
    margin: 40px !important;
  }

  .mg-xs-y-40 {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .mg-xs-y-40-f {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }

  .mg-xs-x-40 {
    margin-left: 40px;
    margin-right: 40px;
  }

  .mg-xs-x-40-f {
    margin-left: 40px !important;
    margin-right: 40px !important;
  }

  .mg-xs-t-40 {
    margin-top: 40px;
  }

  .mg-xs-r-40 {
    margin-right: 40px;
  }

  .mg-xs-b-40 {
    margin-bottom: 40px;
  }

  .mg-xs-l-40 {
    margin-left: 40px;
  }

  .mg-xs-t-40-f {
    margin-top: 40px !important;
  }

  .mg-xs-r-40-f {
    margin-right: 40px !important;
  }

  .mg-xs-b-40-f {
    margin-bottom: 40px !important;
  }

  .mg-xs-l-40-f {
    margin-left: 40px !important;
  }

  .mg-xs-45 {
    margin: 45px;
  }

  .mg-xs-45-f {
    margin: 45px !important;
  }

  .mg-xs-y-45 {
    margin-top: 45px;
    margin-bottom: 45px;
  }

  .mg-xs-y-45-f {
    margin-top: 45px !important;
    margin-bottom: 45px !important;
  }

  .mg-xs-x-45 {
    margin-left: 45px;
    margin-right: 45px;
  }

  .mg-xs-x-45-f {
    margin-left: 45px !important;
    margin-right: 45px !important;
  }

  .mg-xs-t-45 {
    margin-top: 45px;
  }

  .mg-xs-r-45 {
    margin-right: 45px;
  }

  .mg-xs-b-45 {
    margin-bottom: 45px;
  }

  .mg-xs-l-45 {
    margin-left: 45px;
  }

  .mg-xs-t-45-f {
    margin-top: 45px !important;
  }

  .mg-xs-r-45-f {
    margin-right: 45px !important;
  }

  .mg-xs-b-45-f {
    margin-bottom: 45px !important;
  }

  .mg-xs-l-45-f {
    margin-left: 45px !important;
  }

  .mg-xs-50 {
    margin: 50px;
  }

  .mg-xs-50-f {
    margin: 50px !important;
  }

  .mg-xs-y-50 {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .mg-xs-y-50-f {
    margin-top: 50px !important;
    margin-bottom: 50px !important;
  }

  .mg-xs-x-50 {
    margin-left: 50px;
    margin-right: 50px;
  }

  .mg-xs-x-50-f {
    margin-left: 50px !important;
    margin-right: 50px !important;
  }

  .mg-xs-t-50 {
    margin-top: 50px;
  }

  .mg-xs-r-50 {
    margin-right: 50px;
  }

  .mg-xs-b-50 {
    margin-bottom: 50px;
  }

  .mg-xs-l-50 {
    margin-left: 50px;
  }

  .mg-xs-t-50-f {
    margin-top: 50px !important;
  }

  .mg-xs-r-50-f {
    margin-right: 50px !important;
  }

  .mg-xs-b-50-f {
    margin-bottom: 50px !important;
  }

  .mg-xs-l-50-f {
    margin-left: 50px !important;
  }

  .mg-xs-55 {
    margin: 55px;
  }

  .mg-xs-55-f {
    margin: 55px !important;
  }

  .mg-xs-y-55 {
    margin-top: 55px;
    margin-bottom: 55px;
  }

  .mg-xs-y-55-f {
    margin-top: 55px !important;
    margin-bottom: 55px !important;
  }

  .mg-xs-x-55 {
    margin-left: 55px;
    margin-right: 55px;
  }

  .mg-xs-x-55-f {
    margin-left: 55px !important;
    margin-right: 55px !important;
  }

  .mg-xs-t-55 {
    margin-top: 55px;
  }

  .mg-xs-r-55 {
    margin-right: 55px;
  }

  .mg-xs-b-55 {
    margin-bottom: 55px;
  }

  .mg-xs-l-55 {
    margin-left: 55px;
  }

  .mg-xs-t-55-f {
    margin-top: 55px !important;
  }

  .mg-xs-r-55-f {
    margin-right: 55px !important;
  }

  .mg-xs-b-55-f {
    margin-bottom: 55px !important;
  }

  .mg-xs-l-55-f {
    margin-left: 55px !important;
  }

  .mg-xs-60 {
    margin: 60px;
  }

  .mg-xs-60-f {
    margin: 60px !important;
  }

  .mg-xs-y-60 {
    margin-top: 60px;
    margin-bottom: 60px;
  }

  .mg-xs-y-60-f {
    margin-top: 60px !important;
    margin-bottom: 60px !important;
  }

  .mg-xs-x-60 {
    margin-left: 60px;
    margin-right: 60px;
  }

  .mg-xs-x-60-f {
    margin-left: 60px !important;
    margin-right: 60px !important;
  }

  .mg-xs-t-60 {
    margin-top: 60px;
  }

  .mg-xs-r-60 {
    margin-right: 60px;
  }

  .mg-xs-b-60 {
    margin-bottom: 60px;
  }

  .mg-xs-l-60 {
    margin-left: 60px;
  }

  .mg-xs-t-60-f {
    margin-top: 60px !important;
  }

  .mg-xs-r-60-f {
    margin-right: 60px !important;
  }

  .mg-xs-b-60-f {
    margin-bottom: 60px !important;
  }

  .mg-xs-l-60-f {
    margin-left: 60px !important;
  }

  .mg-xs-65 {
    margin: 65px;
  }

  .mg-xs-65-f {
    margin: 65px !important;
  }

  .mg-xs-y-65 {
    margin-top: 65px;
    margin-bottom: 65px;
  }

  .mg-xs-y-65-f {
    margin-top: 65px !important;
    margin-bottom: 65px !important;
  }

  .mg-xs-x-65 {
    margin-left: 65px;
    margin-right: 65px;
  }

  .mg-xs-x-65-f {
    margin-left: 65px !important;
    margin-right: 65px !important;
  }

  .mg-xs-t-65 {
    margin-top: 65px;
  }

  .mg-xs-r-65 {
    margin-right: 65px;
  }

  .mg-xs-b-65 {
    margin-bottom: 65px;
  }

  .mg-xs-l-65 {
    margin-left: 65px;
  }

  .mg-xs-t-65-f {
    margin-top: 65px !important;
  }

  .mg-xs-r-65-f {
    margin-right: 65px !important;
  }

  .mg-xs-b-65-f {
    margin-bottom: 65px !important;
  }

  .mg-xs-l-65-f {
    margin-left: 65px !important;
  }

  .mg-xs-70 {
    margin: 70px;
  }

  .mg-xs-70-f {
    margin: 70px !important;
  }

  .mg-xs-y-70 {
    margin-top: 70px;
    margin-bottom: 70px;
  }

  .mg-xs-y-70-f {
    margin-top: 70px !important;
    margin-bottom: 70px !important;
  }

  .mg-xs-x-70 {
    margin-left: 70px;
    margin-right: 70px;
  }

  .mg-xs-x-70-f {
    margin-left: 70px !important;
    margin-right: 70px !important;
  }

  .mg-xs-t-70 {
    margin-top: 70px;
  }

  .mg-xs-r-70 {
    margin-right: 70px;
  }

  .mg-xs-b-70 {
    margin-bottom: 70px;
  }

  .mg-xs-l-70 {
    margin-left: 70px;
  }

  .mg-xs-t-70-f {
    margin-top: 70px !important;
  }

  .mg-xs-r-70-f {
    margin-right: 70px !important;
  }

  .mg-xs-b-70-f {
    margin-bottom: 70px !important;
  }

  .mg-xs-l-70-f {
    margin-left: 70px !important;
  }

  .mg-xs-75 {
    margin: 75px;
  }

  .mg-xs-75-f {
    margin: 75px !important;
  }

  .mg-xs-y-75 {
    margin-top: 75px;
    margin-bottom: 75px;
  }

  .mg-xs-y-75-f {
    margin-top: 75px !important;
    margin-bottom: 75px !important;
  }

  .mg-xs-x-75 {
    margin-left: 75px;
    margin-right: 75px;
  }

  .mg-xs-x-75-f {
    margin-left: 75px !important;
    margin-right: 75px !important;
  }

  .mg-xs-t-75 {
    margin-top: 75px;
  }

  .mg-xs-r-75 {
    margin-right: 75px;
  }

  .mg-xs-b-75 {
    margin-bottom: 75px;
  }

  .mg-xs-l-75 {
    margin-left: 75px;
  }

  .mg-xs-t-75-f {
    margin-top: 75px !important;
  }

  .mg-xs-r-75-f {
    margin-right: 75px !important;
  }

  .mg-xs-b-75-f {
    margin-bottom: 75px !important;
  }

  .mg-xs-l-75-f {
    margin-left: 75px !important;
  }

  .mg-xs-80 {
    margin: 80px;
  }

  .mg-xs-80-f {
    margin: 80px !important;
  }

  .mg-xs-y-80 {
    margin-top: 80px;
    margin-bottom: 80px;
  }

  .mg-xs-y-80-f {
    margin-top: 80px !important;
    margin-bottom: 80px !important;
  }

  .mg-xs-x-80 {
    margin-left: 80px;
    margin-right: 80px;
  }

  .mg-xs-x-80-f {
    margin-left: 80px !important;
    margin-right: 80px !important;
  }

  .mg-xs-t-80 {
    margin-top: 80px;
  }

  .mg-xs-r-80 {
    margin-right: 80px;
  }

  .mg-xs-b-80 {
    margin-bottom: 80px;
  }

  .mg-xs-l-80 {
    margin-left: 80px;
  }

  .mg-xs-t-80-f {
    margin-top: 80px !important;
  }

  .mg-xs-r-80-f {
    margin-right: 80px !important;
  }

  .mg-xs-b-80-f {
    margin-bottom: 80px !important;
  }

  .mg-xs-l-80-f {
    margin-left: 80px !important;
  }

  .mg-xs-85 {
    margin: 85px;
  }

  .mg-xs-85-f {
    margin: 85px !important;
  }

  .mg-xs-y-85 {
    margin-top: 85px;
    margin-bottom: 85px;
  }

  .mg-xs-y-85-f {
    margin-top: 85px !important;
    margin-bottom: 85px !important;
  }

  .mg-xs-x-85 {
    margin-left: 85px;
    margin-right: 85px;
  }

  .mg-xs-x-85-f {
    margin-left: 85px !important;
    margin-right: 85px !important;
  }

  .mg-xs-t-85 {
    margin-top: 85px;
  }

  .mg-xs-r-85 {
    margin-right: 85px;
  }

  .mg-xs-b-85 {
    margin-bottom: 85px;
  }

  .mg-xs-l-85 {
    margin-left: 85px;
  }

  .mg-xs-t-85-f {
    margin-top: 85px !important;
  }

  .mg-xs-r-85-f {
    margin-right: 85px !important;
  }

  .mg-xs-b-85-f {
    margin-bottom: 85px !important;
  }

  .mg-xs-l-85-f {
    margin-left: 85px !important;
  }

  .mg-xs-90 {
    margin: 90px;
  }

  .mg-xs-90-f {
    margin: 90px !important;
  }

  .mg-xs-y-90 {
    margin-top: 90px;
    margin-bottom: 90px;
  }

  .mg-xs-y-90-f {
    margin-top: 90px !important;
    margin-bottom: 90px !important;
  }

  .mg-xs-x-90 {
    margin-left: 90px;
    margin-right: 90px;
  }

  .mg-xs-x-90-f {
    margin-left: 90px !important;
    margin-right: 90px !important;
  }

  .mg-xs-t-90 {
    margin-top: 90px;
  }

  .mg-xs-r-90 {
    margin-right: 90px;
  }

  .mg-xs-b-90 {
    margin-bottom: 90px;
  }

  .mg-xs-l-90 {
    margin-left: 90px;
  }

  .mg-xs-t-90-f {
    margin-top: 90px !important;
  }

  .mg-xs-r-90-f {
    margin-right: 90px !important;
  }

  .mg-xs-b-90-f {
    margin-bottom: 90px !important;
  }

  .mg-xs-l-90-f {
    margin-left: 90px !important;
  }

  .mg-xs-95 {
    margin: 95px;
  }

  .mg-xs-95-f {
    margin: 95px !important;
  }

  .mg-xs-y-95 {
    margin-top: 95px;
    margin-bottom: 95px;
  }

  .mg-xs-y-95-f {
    margin-top: 95px !important;
    margin-bottom: 95px !important;
  }

  .mg-xs-x-95 {
    margin-left: 95px;
    margin-right: 95px;
  }

  .mg-xs-x-95-f {
    margin-left: 95px !important;
    margin-right: 95px !important;
  }

  .mg-xs-t-95 {
    margin-top: 95px;
  }

  .mg-xs-r-95 {
    margin-right: 95px;
  }

  .mg-xs-b-95 {
    margin-bottom: 95px;
  }

  .mg-xs-l-95 {
    margin-left: 95px;
  }

  .mg-xs-t-95-f {
    margin-top: 95px !important;
  }

  .mg-xs-r-95-f {
    margin-right: 95px !important;
  }

  .mg-xs-b-95-f {
    margin-bottom: 95px !important;
  }

  .mg-xs-l-95-f {
    margin-left: 95px !important;
  }

  .mg-xs-100 {
    margin: 100px;
  }

  .mg-xs-100-f {
    margin: 100px !important;
  }

  .mg-xs-y-100 {
    margin-top: 100px;
    margin-bottom: 100px;
  }

  .mg-xs-y-100-f {
    margin-top: 100px !important;
    margin-bottom: 100px !important;
  }

  .mg-xs-x-100 {
    margin-left: 100px;
    margin-right: 100px;
  }

  .mg-xs-x-100-f {
    margin-left: 100px !important;
    margin-right: 100px !important;
  }

  .mg-xs-t-100 {
    margin-top: 100px;
  }

  .mg-xs-r-100 {
    margin-right: 100px;
  }

  .mg-xs-b-100 {
    margin-bottom: 100px;
  }

  .mg-xs-l-100 {
    margin-left: 100px;
  }

  .mg-xs-t-100-f {
    margin-top: 100px !important;
  }

  .mg-xs-r-100-f {
    margin-right: 100px !important;
  }

  .mg-xs-b-100-f {
    margin-bottom: 100px !important;
  }

  .mg-xs-l-100-f {
    margin-left: 100px !important;
  }

  .mg-xs-t-auto {
    margin-top: auto;
  }

  .mg-xs-r-auto {
    margin-right: auto;
  }

  .mg-xs-b-auto {
    margin-bottom: auto;
  }

  .mg-xs-l-auto {
    margin-left: auto;
  }

  .mg-xs-auto {
    margin: auto;
  }

  .mg-xs-x-auto {
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 576px) {
  .mg-sm-0 {
    margin: 0px;
  }

  .mg-sm-0-f {
    margin: 0px !important;
  }

  .mg-sm-y-0 {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .mg-sm-y-0-f {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }

  .mg-sm-x-0 {
    margin-left: 0px;
    margin-right: 0px;
  }

  .mg-sm-x-0-f {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .mg-sm-t-0 {
    margin-top: 0px;
  }

  .mg-sm-r-0 {
    margin-right: 0px;
  }

  .mg-sm-b-0 {
    margin-bottom: 0px;
  }

  .mg-sm-l-0 {
    margin-left: 0px;
  }

  .mg-sm-t-0-f {
    margin-top: 0px !important;
  }

  .mg-sm-r-0-f {
    margin-right: 0px !important;
  }

  .mg-sm-b-0-f {
    margin-bottom: 0px !important;
  }

  .mg-sm-l-0-f {
    margin-left: 0px !important;
  }

  .mg-sm-1 {
    margin: 1px;
  }

  .mg-sm-1-f {
    margin: 1px !important;
  }

  .mg-sm-y-1 {
    margin-top: 1px;
    margin-bottom: 1px;
  }

  .mg-sm-y-1-f {
    margin-top: 1px !important;
    margin-bottom: 1px !important;
  }

  .mg-sm-x-1 {
    margin-left: 1px;
    margin-right: 1px;
  }

  .mg-sm-x-1-f {
    margin-left: 1px !important;
    margin-right: 1px !important;
  }

  .mg-sm-t-1 {
    margin-top: 1px;
  }

  .mg-sm-r-1 {
    margin-right: 1px;
  }

  .mg-sm-b-1 {
    margin-bottom: 1px;
  }

  .mg-sm-l-1 {
    margin-left: 1px;
  }

  .mg-sm-t-1-f {
    margin-top: 1px !important;
  }

  .mg-sm-r-1-f {
    margin-right: 1px !important;
  }

  .mg-sm-b-1-f {
    margin-bottom: 1px !important;
  }

  .mg-sm-l-1-f {
    margin-left: 1px !important;
  }

  .mg-sm-2 {
    margin: 2px;
  }

  .mg-sm-2-f {
    margin: 2px !important;
  }

  .mg-sm-y-2 {
    margin-top: 2px;
    margin-bottom: 2px;
  }

  .mg-sm-y-2-f {
    margin-top: 2px !important;
    margin-bottom: 2px !important;
  }

  .mg-sm-x-2 {
    margin-left: 2px;
    margin-right: 2px;
  }

  .mg-sm-x-2-f {
    margin-left: 2px !important;
    margin-right: 2px !important;
  }

  .mg-sm-t-2 {
    margin-top: 2px;
  }

  .mg-sm-r-2 {
    margin-right: 2px;
  }

  .mg-sm-b-2 {
    margin-bottom: 2px;
  }

  .mg-sm-l-2 {
    margin-left: 2px;
  }

  .mg-sm-t-2-f {
    margin-top: 2px !important;
  }

  .mg-sm-r-2-f {
    margin-right: 2px !important;
  }

  .mg-sm-b-2-f {
    margin-bottom: 2px !important;
  }

  .mg-sm-l-2-f {
    margin-left: 2px !important;
  }

  .mg-sm-3 {
    margin: 3px;
  }

  .mg-sm-3-f {
    margin: 3px !important;
  }

  .mg-sm-y-3 {
    margin-top: 3px;
    margin-bottom: 3px;
  }

  .mg-sm-y-3-f {
    margin-top: 3px !important;
    margin-bottom: 3px !important;
  }

  .mg-sm-x-3 {
    margin-left: 3px;
    margin-right: 3px;
  }

  .mg-sm-x-3-f {
    margin-left: 3px !important;
    margin-right: 3px !important;
  }

  .mg-sm-t-3 {
    margin-top: 3px;
  }

  .mg-sm-r-3 {
    margin-right: 3px;
  }

  .mg-sm-b-3 {
    margin-bottom: 3px;
  }

  .mg-sm-l-3 {
    margin-left: 3px;
  }

  .mg-sm-t-3-f {
    margin-top: 3px !important;
  }

  .mg-sm-r-3-f {
    margin-right: 3px !important;
  }

  .mg-sm-b-3-f {
    margin-bottom: 3px !important;
  }

  .mg-sm-l-3-f {
    margin-left: 3px !important;
  }

  .mg-sm-4 {
    margin: 4px;
  }

  .mg-sm-4-f {
    margin: 4px !important;
  }

  .mg-sm-y-4 {
    margin-top: 4px;
    margin-bottom: 4px;
  }

  .mg-sm-y-4-f {
    margin-top: 4px !important;
    margin-bottom: 4px !important;
  }

  .mg-sm-x-4 {
    margin-left: 4px;
    margin-right: 4px;
  }

  .mg-sm-x-4-f {
    margin-left: 4px !important;
    margin-right: 4px !important;
  }

  .mg-sm-t-4 {
    margin-top: 4px;
  }

  .mg-sm-r-4 {
    margin-right: 4px;
  }

  .mg-sm-b-4 {
    margin-bottom: 4px;
  }

  .mg-sm-l-4 {
    margin-left: 4px;
  }

  .mg-sm-t-4-f {
    margin-top: 4px !important;
  }

  .mg-sm-r-4-f {
    margin-right: 4px !important;
  }

  .mg-sm-b-4-f {
    margin-bottom: 4px !important;
  }

  .mg-sm-l-4-f {
    margin-left: 4px !important;
  }

  .mg-sm-5 {
    margin: 5px;
  }

  .mg-sm-5-f {
    margin: 5px !important;
  }

  .mg-sm-y-5 {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .mg-sm-y-5-f {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }

  .mg-sm-x-5 {
    margin-left: 5px;
    margin-right: 5px;
  }

  .mg-sm-x-5-f {
    margin-left: 5px !important;
    margin-right: 5px !important;
  }

  .mg-sm-t-5 {
    margin-top: 5px;
  }

  .mg-sm-r-5 {
    margin-right: 5px;
  }

  .mg-sm-b-5 {
    margin-bottom: 5px;
  }

  .mg-sm-l-5 {
    margin-left: 5px;
  }

  .mg-sm-t-5-f {
    margin-top: 5px !important;
  }

  .mg-sm-r-5-f {
    margin-right: 5px !important;
  }

  .mg-sm-b-5-f {
    margin-bottom: 5px !important;
  }

  .mg-sm-l-5-f {
    margin-left: 5px !important;
  }

  .mg-sm-6 {
    margin: 6px;
  }

  .mg-sm-6-f {
    margin: 6px !important;
  }

  .mg-sm-y-6 {
    margin-top: 6px;
    margin-bottom: 6px;
  }

  .mg-sm-y-6-f {
    margin-top: 6px !important;
    margin-bottom: 6px !important;
  }

  .mg-sm-x-6 {
    margin-left: 6px;
    margin-right: 6px;
  }

  .mg-sm-x-6-f {
    margin-left: 6px !important;
    margin-right: 6px !important;
  }

  .mg-sm-t-6 {
    margin-top: 6px;
  }

  .mg-sm-r-6 {
    margin-right: 6px;
  }

  .mg-sm-b-6 {
    margin-bottom: 6px;
  }

  .mg-sm-l-6 {
    margin-left: 6px;
  }

  .mg-sm-t-6-f {
    margin-top: 6px !important;
  }

  .mg-sm-r-6-f {
    margin-right: 6px !important;
  }

  .mg-sm-b-6-f {
    margin-bottom: 6px !important;
  }

  .mg-sm-l-6-f {
    margin-left: 6px !important;
  }

  .mg-sm-7 {
    margin: 7px;
  }

  .mg-sm-7-f {
    margin: 7px !important;
  }

  .mg-sm-y-7 {
    margin-top: 7px;
    margin-bottom: 7px;
  }

  .mg-sm-y-7-f {
    margin-top: 7px !important;
    margin-bottom: 7px !important;
  }

  .mg-sm-x-7 {
    margin-left: 7px;
    margin-right: 7px;
  }

  .mg-sm-x-7-f {
    margin-left: 7px !important;
    margin-right: 7px !important;
  }

  .mg-sm-t-7 {
    margin-top: 7px;
  }

  .mg-sm-r-7 {
    margin-right: 7px;
  }

  .mg-sm-b-7 {
    margin-bottom: 7px;
  }

  .mg-sm-l-7 {
    margin-left: 7px;
  }

  .mg-sm-t-7-f {
    margin-top: 7px !important;
  }

  .mg-sm-r-7-f {
    margin-right: 7px !important;
  }

  .mg-sm-b-7-f {
    margin-bottom: 7px !important;
  }

  .mg-sm-l-7-f {
    margin-left: 7px !important;
  }

  .mg-sm-8 {
    margin: 8px;
  }

  .mg-sm-8-f {
    margin: 8px !important;
  }

  .mg-sm-y-8 {
    margin-top: 8px;
    margin-bottom: 8px;
  }

  .mg-sm-y-8-f {
    margin-top: 8px !important;
    margin-bottom: 8px !important;
  }

  .mg-sm-x-8 {
    margin-left: 8px;
    margin-right: 8px;
  }

  .mg-sm-x-8-f {
    margin-left: 8px !important;
    margin-right: 8px !important;
  }

  .mg-sm-t-8 {
    margin-top: 8px;
  }

  .mg-sm-r-8 {
    margin-right: 8px;
  }

  .mg-sm-b-8 {
    margin-bottom: 8px;
  }

  .mg-sm-l-8 {
    margin-left: 8px;
  }

  .mg-sm-t-8-f {
    margin-top: 8px !important;
  }

  .mg-sm-r-8-f {
    margin-right: 8px !important;
  }

  .mg-sm-b-8-f {
    margin-bottom: 8px !important;
  }

  .mg-sm-l-8-f {
    margin-left: 8px !important;
  }

  .mg-sm-9 {
    margin: 9px;
  }

  .mg-sm-9-f {
    margin: 9px !important;
  }

  .mg-sm-y-9 {
    margin-top: 9px;
    margin-bottom: 9px;
  }

  .mg-sm-y-9-f {
    margin-top: 9px !important;
    margin-bottom: 9px !important;
  }

  .mg-sm-x-9 {
    margin-left: 9px;
    margin-right: 9px;
  }

  .mg-sm-x-9-f {
    margin-left: 9px !important;
    margin-right: 9px !important;
  }

  .mg-sm-t-9 {
    margin-top: 9px;
  }

  .mg-sm-r-9 {
    margin-right: 9px;
  }

  .mg-sm-b-9 {
    margin-bottom: 9px;
  }

  .mg-sm-l-9 {
    margin-left: 9px;
  }

  .mg-sm-t-9-f {
    margin-top: 9px !important;
  }

  .mg-sm-r-9-f {
    margin-right: 9px !important;
  }

  .mg-sm-b-9-f {
    margin-bottom: 9px !important;
  }

  .mg-sm-l-9-f {
    margin-left: 9px !important;
  }

  .mg-sm-10 {
    margin: 10px;
  }

  .mg-sm-10-f {
    margin: 10px !important;
  }

  .mg-sm-y-10 {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .mg-sm-y-10-f {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }

  .mg-sm-x-10 {
    margin-left: 10px;
    margin-right: 10px;
  }

  .mg-sm-x-10-f {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }

  .mg-sm-t-10 {
    margin-top: 10px;
  }

  .mg-sm-r-10 {
    margin-right: 10px;
  }

  .mg-sm-b-10 {
    margin-bottom: 10px;
  }

  .mg-sm-l-10 {
    margin-left: 10px;
  }

  .mg-sm-t-10-f {
    margin-top: 10px !important;
  }

  .mg-sm-r-10-f {
    margin-right: 10px !important;
  }

  .mg-sm-b-10-f {
    margin-bottom: 10px !important;
  }

  .mg-sm-l-10-f {
    margin-left: 10px !important;
  }

  .mg-sm-15 {
    margin: 15px;
  }

  .mg-sm-15-f {
    margin: 15px !important;
  }

  .mg-sm-y-15 {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .mg-sm-y-15-f {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }

  .mg-sm-x-15 {
    margin-left: 15px;
    margin-right: 15px;
  }

  .mg-sm-x-15-f {
    margin-left: 15px !important;
    margin-right: 15px !important;
  }

  .mg-sm-t-15 {
    margin-top: 15px;
  }

  .mg-sm-r-15 {
    margin-right: 15px;
  }

  .mg-sm-b-15 {
    margin-bottom: 15px;
  }

  .mg-sm-l-15 {
    margin-left: 15px;
  }

  .mg-sm-t-15-f {
    margin-top: 15px !important;
  }

  .mg-sm-r-15-f {
    margin-right: 15px !important;
  }

  .mg-sm-b-15-f {
    margin-bottom: 15px !important;
  }

  .mg-sm-l-15-f {
    margin-left: 15px !important;
  }

  .mg-sm-20 {
    margin: 20px;
  }

  .mg-sm-20-f {
    margin: 20px !important;
  }

  .mg-sm-y-20 {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .mg-sm-y-20-f {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }

  .mg-sm-x-20 {
    margin-left: 20px;
    margin-right: 20px;
  }

  .mg-sm-x-20-f {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }

  .mg-sm-t-20 {
    margin-top: 20px;
  }

  .mg-sm-r-20 {
    margin-right: 20px;
  }

  .mg-sm-b-20 {
    margin-bottom: 20px;
  }

  .mg-sm-l-20 {
    margin-left: 20px;
  }

  .mg-sm-t-20-f {
    margin-top: 20px !important;
  }

  .mg-sm-r-20-f {
    margin-right: 20px !important;
  }

  .mg-sm-b-20-f {
    margin-bottom: 20px !important;
  }

  .mg-sm-l-20-f {
    margin-left: 20px !important;
  }

  .mg-sm-25 {
    margin: 25px;
  }

  .mg-sm-25-f {
    margin: 25px !important;
  }

  .mg-sm-y-25 {
    margin-top: 25px;
    margin-bottom: 25px;
  }

  .mg-sm-y-25-f {
    margin-top: 25px !important;
    margin-bottom: 25px !important;
  }

  .mg-sm-x-25 {
    margin-left: 25px;
    margin-right: 25px;
  }

  .mg-sm-x-25-f {
    margin-left: 25px !important;
    margin-right: 25px !important;
  }

  .mg-sm-t-25 {
    margin-top: 25px;
  }

  .mg-sm-r-25 {
    margin-right: 25px;
  }

  .mg-sm-b-25 {
    margin-bottom: 25px;
  }

  .mg-sm-l-25 {
    margin-left: 25px;
  }

  .mg-sm-t-25-f {
    margin-top: 25px !important;
  }

  .mg-sm-r-25-f {
    margin-right: 25px !important;
  }

  .mg-sm-b-25-f {
    margin-bottom: 25px !important;
  }

  .mg-sm-l-25-f {
    margin-left: 25px !important;
  }

  .mg-sm-30 {
    margin: 30px;
  }

  .mg-sm-30-f {
    margin: 30px !important;
  }

  .mg-sm-y-30 {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .mg-sm-y-30-f {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }

  .mg-sm-x-30 {
    margin-left: 30px;
    margin-right: 30px;
  }

  .mg-sm-x-30-f {
    margin-left: 30px !important;
    margin-right: 30px !important;
  }

  .mg-sm-t-30 {
    margin-top: 30px;
  }

  .mg-sm-r-30 {
    margin-right: 30px;
  }

  .mg-sm-b-30 {
    margin-bottom: 30px;
  }

  .mg-sm-l-30 {
    margin-left: 30px;
  }

  .mg-sm-t-30-f {
    margin-top: 30px !important;
  }

  .mg-sm-r-30-f {
    margin-right: 30px !important;
  }

  .mg-sm-b-30-f {
    margin-bottom: 30px !important;
  }

  .mg-sm-l-30-f {
    margin-left: 30px !important;
  }

  .mg-sm-35 {
    margin: 35px;
  }

  .mg-sm-35-f {
    margin: 35px !important;
  }

  .mg-sm-y-35 {
    margin-top: 35px;
    margin-bottom: 35px;
  }

  .mg-sm-y-35-f {
    margin-top: 35px !important;
    margin-bottom: 35px !important;
  }

  .mg-sm-x-35 {
    margin-left: 35px;
    margin-right: 35px;
  }

  .mg-sm-x-35-f {
    margin-left: 35px !important;
    margin-right: 35px !important;
  }

  .mg-sm-t-35 {
    margin-top: 35px;
  }

  .mg-sm-r-35 {
    margin-right: 35px;
  }

  .mg-sm-b-35 {
    margin-bottom: 35px;
  }

  .mg-sm-l-35 {
    margin-left: 35px;
  }

  .mg-sm-t-35-f {
    margin-top: 35px !important;
  }

  .mg-sm-r-35-f {
    margin-right: 35px !important;
  }

  .mg-sm-b-35-f {
    margin-bottom: 35px !important;
  }

  .mg-sm-l-35-f {
    margin-left: 35px !important;
  }

  .mg-sm-40 {
    margin: 40px;
  }

  .mg-sm-40-f {
    margin: 40px !important;
  }

  .mg-sm-y-40 {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .mg-sm-y-40-f {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }

  .mg-sm-x-40 {
    margin-left: 40px;
    margin-right: 40px;
  }

  .mg-sm-x-40-f {
    margin-left: 40px !important;
    margin-right: 40px !important;
  }

  .mg-sm-t-40 {
    margin-top: 40px;
  }

  .mg-sm-r-40 {
    margin-right: 40px;
  }

  .mg-sm-b-40 {
    margin-bottom: 40px;
  }

  .mg-sm-l-40 {
    margin-left: 40px;
  }

  .mg-sm-t-40-f {
    margin-top: 40px !important;
  }

  .mg-sm-r-40-f {
    margin-right: 40px !important;
  }

  .mg-sm-b-40-f {
    margin-bottom: 40px !important;
  }

  .mg-sm-l-40-f {
    margin-left: 40px !important;
  }

  .mg-sm-45 {
    margin: 45px;
  }

  .mg-sm-45-f {
    margin: 45px !important;
  }

  .mg-sm-y-45 {
    margin-top: 45px;
    margin-bottom: 45px;
  }

  .mg-sm-y-45-f {
    margin-top: 45px !important;
    margin-bottom: 45px !important;
  }

  .mg-sm-x-45 {
    margin-left: 45px;
    margin-right: 45px;
  }

  .mg-sm-x-45-f {
    margin-left: 45px !important;
    margin-right: 45px !important;
  }

  .mg-sm-t-45 {
    margin-top: 45px;
  }

  .mg-sm-r-45 {
    margin-right: 45px;
  }

  .mg-sm-b-45 {
    margin-bottom: 45px;
  }

  .mg-sm-l-45 {
    margin-left: 45px;
  }

  .mg-sm-t-45-f {
    margin-top: 45px !important;
  }

  .mg-sm-r-45-f {
    margin-right: 45px !important;
  }

  .mg-sm-b-45-f {
    margin-bottom: 45px !important;
  }

  .mg-sm-l-45-f {
    margin-left: 45px !important;
  }

  .mg-sm-50 {
    margin: 50px;
  }

  .mg-sm-50-f {
    margin: 50px !important;
  }

  .mg-sm-y-50 {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .mg-sm-y-50-f {
    margin-top: 50px !important;
    margin-bottom: 50px !important;
  }

  .mg-sm-x-50 {
    margin-left: 50px;
    margin-right: 50px;
  }

  .mg-sm-x-50-f {
    margin-left: 50px !important;
    margin-right: 50px !important;
  }

  .mg-sm-t-50 {
    margin-top: 50px;
  }

  .mg-sm-r-50 {
    margin-right: 50px;
  }

  .mg-sm-b-50 {
    margin-bottom: 50px;
  }

  .mg-sm-l-50 {
    margin-left: 50px;
  }

  .mg-sm-t-50-f {
    margin-top: 50px !important;
  }

  .mg-sm-r-50-f {
    margin-right: 50px !important;
  }

  .mg-sm-b-50-f {
    margin-bottom: 50px !important;
  }

  .mg-sm-l-50-f {
    margin-left: 50px !important;
  }

  .mg-sm-55 {
    margin: 55px;
  }

  .mg-sm-55-f {
    margin: 55px !important;
  }

  .mg-sm-y-55 {
    margin-top: 55px;
    margin-bottom: 55px;
  }

  .mg-sm-y-55-f {
    margin-top: 55px !important;
    margin-bottom: 55px !important;
  }

  .mg-sm-x-55 {
    margin-left: 55px;
    margin-right: 55px;
  }

  .mg-sm-x-55-f {
    margin-left: 55px !important;
    margin-right: 55px !important;
  }

  .mg-sm-t-55 {
    margin-top: 55px;
  }

  .mg-sm-r-55 {
    margin-right: 55px;
  }

  .mg-sm-b-55 {
    margin-bottom: 55px;
  }

  .mg-sm-l-55 {
    margin-left: 55px;
  }

  .mg-sm-t-55-f {
    margin-top: 55px !important;
  }

  .mg-sm-r-55-f {
    margin-right: 55px !important;
  }

  .mg-sm-b-55-f {
    margin-bottom: 55px !important;
  }

  .mg-sm-l-55-f {
    margin-left: 55px !important;
  }

  .mg-sm-60 {
    margin: 60px;
  }

  .mg-sm-60-f {
    margin: 60px !important;
  }

  .mg-sm-y-60 {
    margin-top: 60px;
    margin-bottom: 60px;
  }

  .mg-sm-y-60-f {
    margin-top: 60px !important;
    margin-bottom: 60px !important;
  }

  .mg-sm-x-60 {
    margin-left: 60px;
    margin-right: 60px;
  }

  .mg-sm-x-60-f {
    margin-left: 60px !important;
    margin-right: 60px !important;
  }

  .mg-sm-t-60 {
    margin-top: 60px;
  }

  .mg-sm-r-60 {
    margin-right: 60px;
  }

  .mg-sm-b-60 {
    margin-bottom: 60px;
  }

  .mg-sm-l-60 {
    margin-left: 60px;
  }

  .mg-sm-t-60-f {
    margin-top: 60px !important;
  }

  .mg-sm-r-60-f {
    margin-right: 60px !important;
  }

  .mg-sm-b-60-f {
    margin-bottom: 60px !important;
  }

  .mg-sm-l-60-f {
    margin-left: 60px !important;
  }

  .mg-sm-65 {
    margin: 65px;
  }

  .mg-sm-65-f {
    margin: 65px !important;
  }

  .mg-sm-y-65 {
    margin-top: 65px;
    margin-bottom: 65px;
  }

  .mg-sm-y-65-f {
    margin-top: 65px !important;
    margin-bottom: 65px !important;
  }

  .mg-sm-x-65 {
    margin-left: 65px;
    margin-right: 65px;
  }

  .mg-sm-x-65-f {
    margin-left: 65px !important;
    margin-right: 65px !important;
  }

  .mg-sm-t-65 {
    margin-top: 65px;
  }

  .mg-sm-r-65 {
    margin-right: 65px;
  }

  .mg-sm-b-65 {
    margin-bottom: 65px;
  }

  .mg-sm-l-65 {
    margin-left: 65px;
  }

  .mg-sm-t-65-f {
    margin-top: 65px !important;
  }

  .mg-sm-r-65-f {
    margin-right: 65px !important;
  }

  .mg-sm-b-65-f {
    margin-bottom: 65px !important;
  }

  .mg-sm-l-65-f {
    margin-left: 65px !important;
  }

  .mg-sm-70 {
    margin: 70px;
  }

  .mg-sm-70-f {
    margin: 70px !important;
  }

  .mg-sm-y-70 {
    margin-top: 70px;
    margin-bottom: 70px;
  }

  .mg-sm-y-70-f {
    margin-top: 70px !important;
    margin-bottom: 70px !important;
  }

  .mg-sm-x-70 {
    margin-left: 70px;
    margin-right: 70px;
  }

  .mg-sm-x-70-f {
    margin-left: 70px !important;
    margin-right: 70px !important;
  }

  .mg-sm-t-70 {
    margin-top: 70px;
  }

  .mg-sm-r-70 {
    margin-right: 70px;
  }

  .mg-sm-b-70 {
    margin-bottom: 70px;
  }

  .mg-sm-l-70 {
    margin-left: 70px;
  }

  .mg-sm-t-70-f {
    margin-top: 70px !important;
  }

  .mg-sm-r-70-f {
    margin-right: 70px !important;
  }

  .mg-sm-b-70-f {
    margin-bottom: 70px !important;
  }

  .mg-sm-l-70-f {
    margin-left: 70px !important;
  }

  .mg-sm-75 {
    margin: 75px;
  }

  .mg-sm-75-f {
    margin: 75px !important;
  }

  .mg-sm-y-75 {
    margin-top: 75px;
    margin-bottom: 75px;
  }

  .mg-sm-y-75-f {
    margin-top: 75px !important;
    margin-bottom: 75px !important;
  }

  .mg-sm-x-75 {
    margin-left: 75px;
    margin-right: 75px;
  }

  .mg-sm-x-75-f {
    margin-left: 75px !important;
    margin-right: 75px !important;
  }

  .mg-sm-t-75 {
    margin-top: 75px;
  }

  .mg-sm-r-75 {
    margin-right: 75px;
  }

  .mg-sm-b-75 {
    margin-bottom: 75px;
  }

  .mg-sm-l-75 {
    margin-left: 75px;
  }

  .mg-sm-t-75-f {
    margin-top: 75px !important;
  }

  .mg-sm-r-75-f {
    margin-right: 75px !important;
  }

  .mg-sm-b-75-f {
    margin-bottom: 75px !important;
  }

  .mg-sm-l-75-f {
    margin-left: 75px !important;
  }

  .mg-sm-80 {
    margin: 80px;
  }

  .mg-sm-80-f {
    margin: 80px !important;
  }

  .mg-sm-y-80 {
    margin-top: 80px;
    margin-bottom: 80px;
  }

  .mg-sm-y-80-f {
    margin-top: 80px !important;
    margin-bottom: 80px !important;
  }

  .mg-sm-x-80 {
    margin-left: 80px;
    margin-right: 80px;
  }

  .mg-sm-x-80-f {
    margin-left: 80px !important;
    margin-right: 80px !important;
  }

  .mg-sm-t-80 {
    margin-top: 80px;
  }

  .mg-sm-r-80 {
    margin-right: 80px;
  }

  .mg-sm-b-80 {
    margin-bottom: 80px;
  }

  .mg-sm-l-80 {
    margin-left: 80px;
  }

  .mg-sm-t-80-f {
    margin-top: 80px !important;
  }

  .mg-sm-r-80-f {
    margin-right: 80px !important;
  }

  .mg-sm-b-80-f {
    margin-bottom: 80px !important;
  }

  .mg-sm-l-80-f {
    margin-left: 80px !important;
  }

  .mg-sm-85 {
    margin: 85px;
  }

  .mg-sm-85-f {
    margin: 85px !important;
  }

  .mg-sm-y-85 {
    margin-top: 85px;
    margin-bottom: 85px;
  }

  .mg-sm-y-85-f {
    margin-top: 85px !important;
    margin-bottom: 85px !important;
  }

  .mg-sm-x-85 {
    margin-left: 85px;
    margin-right: 85px;
  }

  .mg-sm-x-85-f {
    margin-left: 85px !important;
    margin-right: 85px !important;
  }

  .mg-sm-t-85 {
    margin-top: 85px;
  }

  .mg-sm-r-85 {
    margin-right: 85px;
  }

  .mg-sm-b-85 {
    margin-bottom: 85px;
  }

  .mg-sm-l-85 {
    margin-left: 85px;
  }

  .mg-sm-t-85-f {
    margin-top: 85px !important;
  }

  .mg-sm-r-85-f {
    margin-right: 85px !important;
  }

  .mg-sm-b-85-f {
    margin-bottom: 85px !important;
  }

  .mg-sm-l-85-f {
    margin-left: 85px !important;
  }

  .mg-sm-90 {
    margin: 90px;
  }

  .mg-sm-90-f {
    margin: 90px !important;
  }

  .mg-sm-y-90 {
    margin-top: 90px;
    margin-bottom: 90px;
  }

  .mg-sm-y-90-f {
    margin-top: 90px !important;
    margin-bottom: 90px !important;
  }

  .mg-sm-x-90 {
    margin-left: 90px;
    margin-right: 90px;
  }

  .mg-sm-x-90-f {
    margin-left: 90px !important;
    margin-right: 90px !important;
  }

  .mg-sm-t-90 {
    margin-top: 90px;
  }

  .mg-sm-r-90 {
    margin-right: 90px;
  }

  .mg-sm-b-90 {
    margin-bottom: 90px;
  }

  .mg-sm-l-90 {
    margin-left: 90px;
  }

  .mg-sm-t-90-f {
    margin-top: 90px !important;
  }

  .mg-sm-r-90-f {
    margin-right: 90px !important;
  }

  .mg-sm-b-90-f {
    margin-bottom: 90px !important;
  }

  .mg-sm-l-90-f {
    margin-left: 90px !important;
  }

  .mg-sm-95 {
    margin: 95px;
  }

  .mg-sm-95-f {
    margin: 95px !important;
  }

  .mg-sm-y-95 {
    margin-top: 95px;
    margin-bottom: 95px;
  }

  .mg-sm-y-95-f {
    margin-top: 95px !important;
    margin-bottom: 95px !important;
  }

  .mg-sm-x-95 {
    margin-left: 95px;
    margin-right: 95px;
  }

  .mg-sm-x-95-f {
    margin-left: 95px !important;
    margin-right: 95px !important;
  }

  .mg-sm-t-95 {
    margin-top: 95px;
  }

  .mg-sm-r-95 {
    margin-right: 95px;
  }

  .mg-sm-b-95 {
    margin-bottom: 95px;
  }

  .mg-sm-l-95 {
    margin-left: 95px;
  }

  .mg-sm-t-95-f {
    margin-top: 95px !important;
  }

  .mg-sm-r-95-f {
    margin-right: 95px !important;
  }

  .mg-sm-b-95-f {
    margin-bottom: 95px !important;
  }

  .mg-sm-l-95-f {
    margin-left: 95px !important;
  }

  .mg-sm-100 {
    margin: 100px;
  }

  .mg-sm-100-f {
    margin: 100px !important;
  }

  .mg-sm-y-100 {
    margin-top: 100px;
    margin-bottom: 100px;
  }

  .mg-sm-y-100-f {
    margin-top: 100px !important;
    margin-bottom: 100px !important;
  }

  .mg-sm-x-100 {
    margin-left: 100px;
    margin-right: 100px;
  }

  .mg-sm-x-100-f {
    margin-left: 100px !important;
    margin-right: 100px !important;
  }

  .mg-sm-t-100 {
    margin-top: 100px;
  }

  .mg-sm-r-100 {
    margin-right: 100px;
  }

  .mg-sm-b-100 {
    margin-bottom: 100px;
  }

  .mg-sm-l-100 {
    margin-left: 100px;
  }

  .mg-sm-t-100-f {
    margin-top: 100px !important;
  }

  .mg-sm-r-100-f {
    margin-right: 100px !important;
  }

  .mg-sm-b-100-f {
    margin-bottom: 100px !important;
  }

  .mg-sm-l-100-f {
    margin-left: 100px !important;
  }

  .mg-sm-t-auto {
    margin-top: auto;
  }

  .mg-sm-r-auto {
    margin-right: auto;
  }

  .mg-sm-b-auto {
    margin-bottom: auto;
  }

  .mg-sm-l-auto {
    margin-left: auto;
  }

  .mg-sm-auto {
    margin: auto;
  }

  .mg-sm-x-auto {
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 768px) {
  .mg-md-0 {
    margin: 0px;
  }

  .mg-md-0-f {
    margin: 0px !important;
  }

  .mg-md-y-0 {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .mg-md-y-0-f {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }

  .mg-md-x-0 {
    margin-left: 0px;
    margin-right: 0px;
  }

  .mg-md-x-0-f {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .mg-md-t-0 {
    margin-top: 0px;
  }

  .mg-md-r-0 {
    margin-right: 0px;
  }

  .mg-md-b-0 {
    margin-bottom: 0px;
  }

  .mg-md-l-0 {
    margin-left: 0px;
  }

  .mg-md-t-0-f {
    margin-top: 0px !important;
  }

  .mg-md-r-0-f {
    margin-right: 0px !important;
  }

  .mg-md-b-0-f {
    margin-bottom: 0px !important;
  }

  .mg-md-l-0-f {
    margin-left: 0px !important;
  }

  .mg-md-1 {
    margin: 1px;
  }

  .mg-md-1-f {
    margin: 1px !important;
  }

  .mg-md-y-1 {
    margin-top: 1px;
    margin-bottom: 1px;
  }

  .mg-md-y-1-f {
    margin-top: 1px !important;
    margin-bottom: 1px !important;
  }

  .mg-md-x-1 {
    margin-left: 1px;
    margin-right: 1px;
  }

  .mg-md-x-1-f {
    margin-left: 1px !important;
    margin-right: 1px !important;
  }

  .mg-md-t-1 {
    margin-top: 1px;
  }

  .mg-md-r-1 {
    margin-right: 1px;
  }

  .mg-md-b-1 {
    margin-bottom: 1px;
  }

  .mg-md-l-1 {
    margin-left: 1px;
  }

  .mg-md-t-1-f {
    margin-top: 1px !important;
  }

  .mg-md-r-1-f {
    margin-right: 1px !important;
  }

  .mg-md-b-1-f {
    margin-bottom: 1px !important;
  }

  .mg-md-l-1-f {
    margin-left: 1px !important;
  }

  .mg-md-2 {
    margin: 2px;
  }

  .mg-md-2-f {
    margin: 2px !important;
  }

  .mg-md-y-2 {
    margin-top: 2px;
    margin-bottom: 2px;
  }

  .mg-md-y-2-f {
    margin-top: 2px !important;
    margin-bottom: 2px !important;
  }

  .mg-md-x-2 {
    margin-left: 2px;
    margin-right: 2px;
  }

  .mg-md-x-2-f {
    margin-left: 2px !important;
    margin-right: 2px !important;
  }

  .mg-md-t-2 {
    margin-top: 2px;
  }

  .mg-md-r-2 {
    margin-right: 2px;
  }

  .mg-md-b-2 {
    margin-bottom: 2px;
  }

  .mg-md-l-2 {
    margin-left: 2px;
  }

  .mg-md-t-2-f {
    margin-top: 2px !important;
  }

  .mg-md-r-2-f {
    margin-right: 2px !important;
  }

  .mg-md-b-2-f {
    margin-bottom: 2px !important;
  }

  .mg-md-l-2-f {
    margin-left: 2px !important;
  }

  .mg-md-3 {
    margin: 3px;
  }

  .mg-md-3-f {
    margin: 3px !important;
  }

  .mg-md-y-3 {
    margin-top: 3px;
    margin-bottom: 3px;
  }

  .mg-md-y-3-f {
    margin-top: 3px !important;
    margin-bottom: 3px !important;
  }

  .mg-md-x-3 {
    margin-left: 3px;
    margin-right: 3px;
  }

  .mg-md-x-3-f {
    margin-left: 3px !important;
    margin-right: 3px !important;
  }

  .mg-md-t-3 {
    margin-top: 3px;
  }

  .mg-md-r-3 {
    margin-right: 3px;
  }

  .mg-md-b-3 {
    margin-bottom: 3px;
  }

  .mg-md-l-3 {
    margin-left: 3px;
  }

  .mg-md-t-3-f {
    margin-top: 3px !important;
  }

  .mg-md-r-3-f {
    margin-right: 3px !important;
  }

  .mg-md-b-3-f {
    margin-bottom: 3px !important;
  }

  .mg-md-l-3-f {
    margin-left: 3px !important;
  }

  .mg-md-4 {
    margin: 4px;
  }

  .mg-md-4-f {
    margin: 4px !important;
  }

  .mg-md-y-4 {
    margin-top: 4px;
    margin-bottom: 4px;
  }

  .mg-md-y-4-f {
    margin-top: 4px !important;
    margin-bottom: 4px !important;
  }

  .mg-md-x-4 {
    margin-left: 4px;
    margin-right: 4px;
  }

  .mg-md-x-4-f {
    margin-left: 4px !important;
    margin-right: 4px !important;
  }

  .mg-md-t-4 {
    margin-top: 4px;
  }

  .mg-md-r-4 {
    margin-right: 4px;
  }

  .mg-md-b-4 {
    margin-bottom: 4px;
  }

  .mg-md-l-4 {
    margin-left: 4px;
  }

  .mg-md-t-4-f {
    margin-top: 4px !important;
  }

  .mg-md-r-4-f {
    margin-right: 4px !important;
  }

  .mg-md-b-4-f {
    margin-bottom: 4px !important;
  }

  .mg-md-l-4-f {
    margin-left: 4px !important;
  }

  .mg-md-5 {
    margin: 5px;
  }

  .mg-md-5-f {
    margin: 5px !important;
  }

  .mg-md-y-5 {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .mg-md-y-5-f {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }

  .mg-md-x-5 {
    margin-left: 5px;
    margin-right: 5px;
  }

  .mg-md-x-5-f {
    margin-left: 5px !important;
    margin-right: 5px !important;
  }

  .mg-md-t-5 {
    margin-top: 5px;
  }

  .mg-md-r-5 {
    margin-right: 5px;
  }

  .mg-md-b-5 {
    margin-bottom: 5px;
  }

  .mg-md-l-5 {
    margin-left: 5px;
  }

  .mg-md-t-5-f {
    margin-top: 5px !important;
  }

  .mg-md-r-5-f {
    margin-right: 5px !important;
  }

  .mg-md-b-5-f {
    margin-bottom: 5px !important;
  }

  .mg-md-l-5-f {
    margin-left: 5px !important;
  }

  .mg-md-6 {
    margin: 6px;
  }

  .mg-md-6-f {
    margin: 6px !important;
  }

  .mg-md-y-6 {
    margin-top: 6px;
    margin-bottom: 6px;
  }

  .mg-md-y-6-f {
    margin-top: 6px !important;
    margin-bottom: 6px !important;
  }

  .mg-md-x-6 {
    margin-left: 6px;
    margin-right: 6px;
  }

  .mg-md-x-6-f {
    margin-left: 6px !important;
    margin-right: 6px !important;
  }

  .mg-md-t-6 {
    margin-top: 6px;
  }

  .mg-md-r-6 {
    margin-right: 6px;
  }

  .mg-md-b-6 {
    margin-bottom: 6px;
  }

  .mg-md-l-6 {
    margin-left: 6px;
  }

  .mg-md-t-6-f {
    margin-top: 6px !important;
  }

  .mg-md-r-6-f {
    margin-right: 6px !important;
  }

  .mg-md-b-6-f {
    margin-bottom: 6px !important;
  }

  .mg-md-l-6-f {
    margin-left: 6px !important;
  }

  .mg-md-7 {
    margin: 7px;
  }

  .mg-md-7-f {
    margin: 7px !important;
  }

  .mg-md-y-7 {
    margin-top: 7px;
    margin-bottom: 7px;
  }

  .mg-md-y-7-f {
    margin-top: 7px !important;
    margin-bottom: 7px !important;
  }

  .mg-md-x-7 {
    margin-left: 7px;
    margin-right: 7px;
  }

  .mg-md-x-7-f {
    margin-left: 7px !important;
    margin-right: 7px !important;
  }

  .mg-md-t-7 {
    margin-top: 7px;
  }

  .mg-md-r-7 {
    margin-right: 7px;
  }

  .mg-md-b-7 {
    margin-bottom: 7px;
  }

  .mg-md-l-7 {
    margin-left: 7px;
  }

  .mg-md-t-7-f {
    margin-top: 7px !important;
  }

  .mg-md-r-7-f {
    margin-right: 7px !important;
  }

  .mg-md-b-7-f {
    margin-bottom: 7px !important;
  }

  .mg-md-l-7-f {
    margin-left: 7px !important;
  }

  .mg-md-8 {
    margin: 8px;
  }

  .mg-md-8-f {
    margin: 8px !important;
  }

  .mg-md-y-8 {
    margin-top: 8px;
    margin-bottom: 8px;
  }

  .mg-md-y-8-f {
    margin-top: 8px !important;
    margin-bottom: 8px !important;
  }

  .mg-md-x-8 {
    margin-left: 8px;
    margin-right: 8px;
  }

  .mg-md-x-8-f {
    margin-left: 8px !important;
    margin-right: 8px !important;
  }

  .mg-md-t-8 {
    margin-top: 8px;
  }

  .mg-md-r-8 {
    margin-right: 8px;
  }

  .mg-md-b-8 {
    margin-bottom: 8px;
  }

  .mg-md-l-8 {
    margin-left: 8px;
  }

  .mg-md-t-8-f {
    margin-top: 8px !important;
  }

  .mg-md-r-8-f {
    margin-right: 8px !important;
  }

  .mg-md-b-8-f {
    margin-bottom: 8px !important;
  }

  .mg-md-l-8-f {
    margin-left: 8px !important;
  }

  .mg-md-9 {
    margin: 9px;
  }

  .mg-md-9-f {
    margin: 9px !important;
  }

  .mg-md-y-9 {
    margin-top: 9px;
    margin-bottom: 9px;
  }

  .mg-md-y-9-f {
    margin-top: 9px !important;
    margin-bottom: 9px !important;
  }

  .mg-md-x-9 {
    margin-left: 9px;
    margin-right: 9px;
  }

  .mg-md-x-9-f {
    margin-left: 9px !important;
    margin-right: 9px !important;
  }

  .mg-md-t-9 {
    margin-top: 9px;
  }

  .mg-md-r-9 {
    margin-right: 9px;
  }

  .mg-md-b-9 {
    margin-bottom: 9px;
  }

  .mg-md-l-9 {
    margin-left: 9px;
  }

  .mg-md-t-9-f {
    margin-top: 9px !important;
  }

  .mg-md-r-9-f {
    margin-right: 9px !important;
  }

  .mg-md-b-9-f {
    margin-bottom: 9px !important;
  }

  .mg-md-l-9-f {
    margin-left: 9px !important;
  }

  .mg-md-10 {
    margin: 10px;
  }

  .mg-md-10-f {
    margin: 10px !important;
  }

  .mg-md-y-10 {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .mg-md-y-10-f {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }

  .mg-md-x-10 {
    margin-left: 10px;
    margin-right: 10px;
  }

  .mg-md-x-10-f {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }

  .mg-md-t-10 {
    margin-top: 10px;
  }

  .mg-md-r-10 {
    margin-right: 10px;
  }

  .mg-md-b-10 {
    margin-bottom: 10px;
  }

  .mg-md-l-10 {
    margin-left: 10px;
  }

  .mg-md-t-10-f {
    margin-top: 10px !important;
  }

  .mg-md-r-10-f {
    margin-right: 10px !important;
  }

  .mg-md-b-10-f {
    margin-bottom: 10px !important;
  }

  .mg-md-l-10-f {
    margin-left: 10px !important;
  }

  .mg-md-15 {
    margin: 15px;
  }

  .mg-md-15-f {
    margin: 15px !important;
  }

  .mg-md-y-15 {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .mg-md-y-15-f {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }

  .mg-md-x-15 {
    margin-left: 15px;
    margin-right: 15px;
  }

  .mg-md-x-15-f {
    margin-left: 15px !important;
    margin-right: 15px !important;
  }

  .mg-md-t-15 {
    margin-top: 15px;
  }

  .mg-md-r-15 {
    margin-right: 15px;
  }

  .mg-md-b-15 {
    margin-bottom: 15px;
  }

  .mg-md-l-15 {
    margin-left: 15px;
  }

  .mg-md-t-15-f {
    margin-top: 15px !important;
  }

  .mg-md-r-15-f {
    margin-right: 15px !important;
  }

  .mg-md-b-15-f {
    margin-bottom: 15px !important;
  }

  .mg-md-l-15-f {
    margin-left: 15px !important;
  }

  .mg-md-20 {
    margin: 20px;
  }

  .mg-md-20-f {
    margin: 20px !important;
  }

  .mg-md-y-20 {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .mg-md-y-20-f {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }

  .mg-md-x-20 {
    margin-left: 20px;
    margin-right: 20px;
  }

  .mg-md-x-20-f {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }

  .mg-md-t-20 {
    margin-top: 20px;
  }

  .mg-md-r-20 {
    margin-right: 20px;
  }

  .mg-md-b-20 {
    margin-bottom: 20px;
  }

  .mg-md-l-20 {
    margin-left: 20px;
  }

  .mg-md-t-20-f {
    margin-top: 20px !important;
  }

  .mg-md-r-20-f {
    margin-right: 20px !important;
  }

  .mg-md-b-20-f {
    margin-bottom: 20px !important;
  }

  .mg-md-l-20-f {
    margin-left: 20px !important;
  }

  .mg-md-25 {
    margin: 25px;
  }

  .mg-md-25-f {
    margin: 25px !important;
  }

  .mg-md-y-25 {
    margin-top: 25px;
    margin-bottom: 25px;
  }

  .mg-md-y-25-f {
    margin-top: 25px !important;
    margin-bottom: 25px !important;
  }

  .mg-md-x-25 {
    margin-left: 25px;
    margin-right: 25px;
  }

  .mg-md-x-25-f {
    margin-left: 25px !important;
    margin-right: 25px !important;
  }

  .mg-md-t-25 {
    margin-top: 25px;
  }

  .mg-md-r-25 {
    margin-right: 25px;
  }

  .mg-md-b-25 {
    margin-bottom: 25px;
  }

  .mg-md-l-25 {
    margin-left: 25px;
  }

  .mg-md-t-25-f {
    margin-top: 25px !important;
  }

  .mg-md-r-25-f {
    margin-right: 25px !important;
  }

  .mg-md-b-25-f {
    margin-bottom: 25px !important;
  }

  .mg-md-l-25-f {
    margin-left: 25px !important;
  }

  .mg-md-30 {
    margin: 30px;
  }

  .mg-md-30-f {
    margin: 30px !important;
  }

  .mg-md-y-30 {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .mg-md-y-30-f {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }

  .mg-md-x-30 {
    margin-left: 30px;
    margin-right: 30px;
  }

  .mg-md-x-30-f {
    margin-left: 30px !important;
    margin-right: 30px !important;
  }

  .mg-md-t-30 {
    margin-top: 30px;
  }

  .mg-md-r-30 {
    margin-right: 30px;
  }

  .mg-md-b-30 {
    margin-bottom: 30px;
  }

  .mg-md-l-30 {
    margin-left: 30px;
  }

  .mg-md-t-30-f {
    margin-top: 30px !important;
  }

  .mg-md-r-30-f {
    margin-right: 30px !important;
  }

  .mg-md-b-30-f {
    margin-bottom: 30px !important;
  }

  .mg-md-l-30-f {
    margin-left: 30px !important;
  }

  .mg-md-35 {
    margin: 35px;
  }

  .mg-md-35-f {
    margin: 35px !important;
  }

  .mg-md-y-35 {
    margin-top: 35px;
    margin-bottom: 35px;
  }

  .mg-md-y-35-f {
    margin-top: 35px !important;
    margin-bottom: 35px !important;
  }

  .mg-md-x-35 {
    margin-left: 35px;
    margin-right: 35px;
  }

  .mg-md-x-35-f {
    margin-left: 35px !important;
    margin-right: 35px !important;
  }

  .mg-md-t-35 {
    margin-top: 35px;
  }

  .mg-md-r-35 {
    margin-right: 35px;
  }

  .mg-md-b-35 {
    margin-bottom: 35px;
  }

  .mg-md-l-35 {
    margin-left: 35px;
  }

  .mg-md-t-35-f {
    margin-top: 35px !important;
  }

  .mg-md-r-35-f {
    margin-right: 35px !important;
  }

  .mg-md-b-35-f {
    margin-bottom: 35px !important;
  }

  .mg-md-l-35-f {
    margin-left: 35px !important;
  }

  .mg-md-40 {
    margin: 40px;
  }

  .mg-md-40-f {
    margin: 40px !important;
  }

  .mg-md-y-40 {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .mg-md-y-40-f {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }

  .mg-md-x-40 {
    margin-left: 40px;
    margin-right: 40px;
  }

  .mg-md-x-40-f {
    margin-left: 40px !important;
    margin-right: 40px !important;
  }

  .mg-md-t-40 {
    margin-top: 40px;
  }

  .mg-md-r-40 {
    margin-right: 40px;
  }

  .mg-md-b-40 {
    margin-bottom: 40px;
  }

  .mg-md-l-40 {
    margin-left: 40px;
  }

  .mg-md-t-40-f {
    margin-top: 40px !important;
  }

  .mg-md-r-40-f {
    margin-right: 40px !important;
  }

  .mg-md-b-40-f {
    margin-bottom: 40px !important;
  }

  .mg-md-l-40-f {
    margin-left: 40px !important;
  }

  .mg-md-45 {
    margin: 45px;
  }

  .mg-md-45-f {
    margin: 45px !important;
  }

  .mg-md-y-45 {
    margin-top: 45px;
    margin-bottom: 45px;
  }

  .mg-md-y-45-f {
    margin-top: 45px !important;
    margin-bottom: 45px !important;
  }

  .mg-md-x-45 {
    margin-left: 45px;
    margin-right: 45px;
  }

  .mg-md-x-45-f {
    margin-left: 45px !important;
    margin-right: 45px !important;
  }

  .mg-md-t-45 {
    margin-top: 45px;
  }

  .mg-md-r-45 {
    margin-right: 45px;
  }

  .mg-md-b-45 {
    margin-bottom: 45px;
  }

  .mg-md-l-45 {
    margin-left: 45px;
  }

  .mg-md-t-45-f {
    margin-top: 45px !important;
  }

  .mg-md-r-45-f {
    margin-right: 45px !important;
  }

  .mg-md-b-45-f {
    margin-bottom: 45px !important;
  }

  .mg-md-l-45-f {
    margin-left: 45px !important;
  }

  .mg-md-50 {
    margin: 50px;
  }

  .mg-md-50-f {
    margin: 50px !important;
  }

  .mg-md-y-50 {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .mg-md-y-50-f {
    margin-top: 50px !important;
    margin-bottom: 50px !important;
  }

  .mg-md-x-50 {
    margin-left: 50px;
    margin-right: 50px;
  }

  .mg-md-x-50-f {
    margin-left: 50px !important;
    margin-right: 50px !important;
  }

  .mg-md-t-50 {
    margin-top: 50px;
  }

  .mg-md-r-50 {
    margin-right: 50px;
  }

  .mg-md-b-50 {
    margin-bottom: 50px;
  }

  .mg-md-l-50 {
    margin-left: 50px;
  }

  .mg-md-t-50-f {
    margin-top: 50px !important;
  }

  .mg-md-r-50-f {
    margin-right: 50px !important;
  }

  .mg-md-b-50-f {
    margin-bottom: 50px !important;
  }

  .mg-md-l-50-f {
    margin-left: 50px !important;
  }

  .mg-md-55 {
    margin: 55px;
  }

  .mg-md-55-f {
    margin: 55px !important;
  }

  .mg-md-y-55 {
    margin-top: 55px;
    margin-bottom: 55px;
  }

  .mg-md-y-55-f {
    margin-top: 55px !important;
    margin-bottom: 55px !important;
  }

  .mg-md-x-55 {
    margin-left: 55px;
    margin-right: 55px;
  }

  .mg-md-x-55-f {
    margin-left: 55px !important;
    margin-right: 55px !important;
  }

  .mg-md-t-55 {
    margin-top: 55px;
  }

  .mg-md-r-55 {
    margin-right: 55px;
  }

  .mg-md-b-55 {
    margin-bottom: 55px;
  }

  .mg-md-l-55 {
    margin-left: 55px;
  }

  .mg-md-t-55-f {
    margin-top: 55px !important;
  }

  .mg-md-r-55-f {
    margin-right: 55px !important;
  }

  .mg-md-b-55-f {
    margin-bottom: 55px !important;
  }

  .mg-md-l-55-f {
    margin-left: 55px !important;
  }

  .mg-md-60 {
    margin: 60px;
  }

  .mg-md-60-f {
    margin: 60px !important;
  }

  .mg-md-y-60 {
    margin-top: 60px;
    margin-bottom: 60px;
  }

  .mg-md-y-60-f {
    margin-top: 60px !important;
    margin-bottom: 60px !important;
  }

  .mg-md-x-60 {
    margin-left: 60px;
    margin-right: 60px;
  }

  .mg-md-x-60-f {
    margin-left: 60px !important;
    margin-right: 60px !important;
  }

  .mg-md-t-60 {
    margin-top: 60px;
  }

  .mg-md-r-60 {
    margin-right: 60px;
  }

  .mg-md-b-60 {
    margin-bottom: 60px;
  }

  .mg-md-l-60 {
    margin-left: 60px;
  }

  .mg-md-t-60-f {
    margin-top: 60px !important;
  }

  .mg-md-r-60-f {
    margin-right: 60px !important;
  }

  .mg-md-b-60-f {
    margin-bottom: 60px !important;
  }

  .mg-md-l-60-f {
    margin-left: 60px !important;
  }

  .mg-md-65 {
    margin: 65px;
  }

  .mg-md-65-f {
    margin: 65px !important;
  }

  .mg-md-y-65 {
    margin-top: 65px;
    margin-bottom: 65px;
  }

  .mg-md-y-65-f {
    margin-top: 65px !important;
    margin-bottom: 65px !important;
  }

  .mg-md-x-65 {
    margin-left: 65px;
    margin-right: 65px;
  }

  .mg-md-x-65-f {
    margin-left: 65px !important;
    margin-right: 65px !important;
  }

  .mg-md-t-65 {
    margin-top: 65px;
  }

  .mg-md-r-65 {
    margin-right: 65px;
  }

  .mg-md-b-65 {
    margin-bottom: 65px;
  }

  .mg-md-l-65 {
    margin-left: 65px;
  }

  .mg-md-t-65-f {
    margin-top: 65px !important;
  }

  .mg-md-r-65-f {
    margin-right: 65px !important;
  }

  .mg-md-b-65-f {
    margin-bottom: 65px !important;
  }

  .mg-md-l-65-f {
    margin-left: 65px !important;
  }

  .mg-md-70 {
    margin: 70px;
  }

  .mg-md-70-f {
    margin: 70px !important;
  }

  .mg-md-y-70 {
    margin-top: 70px;
    margin-bottom: 70px;
  }

  .mg-md-y-70-f {
    margin-top: 70px !important;
    margin-bottom: 70px !important;
  }

  .mg-md-x-70 {
    margin-left: 70px;
    margin-right: 70px;
  }

  .mg-md-x-70-f {
    margin-left: 70px !important;
    margin-right: 70px !important;
  }

  .mg-md-t-70 {
    margin-top: 70px;
  }

  .mg-md-r-70 {
    margin-right: 70px;
  }

  .mg-md-b-70 {
    margin-bottom: 70px;
  }

  .mg-md-l-70 {
    margin-left: 70px;
  }

  .mg-md-t-70-f {
    margin-top: 70px !important;
  }

  .mg-md-r-70-f {
    margin-right: 70px !important;
  }

  .mg-md-b-70-f {
    margin-bottom: 70px !important;
  }

  .mg-md-l-70-f {
    margin-left: 70px !important;
  }

  .mg-md-75 {
    margin: 75px;
  }

  .mg-md-75-f {
    margin: 75px !important;
  }

  .mg-md-y-75 {
    margin-top: 75px;
    margin-bottom: 75px;
  }

  .mg-md-y-75-f {
    margin-top: 75px !important;
    margin-bottom: 75px !important;
  }

  .mg-md-x-75 {
    margin-left: 75px;
    margin-right: 75px;
  }

  .mg-md-x-75-f {
    margin-left: 75px !important;
    margin-right: 75px !important;
  }

  .mg-md-t-75 {
    margin-top: 75px;
  }

  .mg-md-r-75 {
    margin-right: 75px;
  }

  .mg-md-b-75 {
    margin-bottom: 75px;
  }

  .mg-md-l-75 {
    margin-left: 75px;
  }

  .mg-md-t-75-f {
    margin-top: 75px !important;
  }

  .mg-md-r-75-f {
    margin-right: 75px !important;
  }

  .mg-md-b-75-f {
    margin-bottom: 75px !important;
  }

  .mg-md-l-75-f {
    margin-left: 75px !important;
  }

  .mg-md-80 {
    margin: 80px;
  }

  .mg-md-80-f {
    margin: 80px !important;
  }

  .mg-md-y-80 {
    margin-top: 80px;
    margin-bottom: 80px;
  }

  .mg-md-y-80-f {
    margin-top: 80px !important;
    margin-bottom: 80px !important;
  }

  .mg-md-x-80 {
    margin-left: 80px;
    margin-right: 80px;
  }

  .mg-md-x-80-f {
    margin-left: 80px !important;
    margin-right: 80px !important;
  }

  .mg-md-t-80 {
    margin-top: 80px;
  }

  .mg-md-r-80 {
    margin-right: 80px;
  }

  .mg-md-b-80 {
    margin-bottom: 80px;
  }

  .mg-md-l-80 {
    margin-left: 80px;
  }

  .mg-md-t-80-f {
    margin-top: 80px !important;
  }

  .mg-md-r-80-f {
    margin-right: 80px !important;
  }

  .mg-md-b-80-f {
    margin-bottom: 80px !important;
  }

  .mg-md-l-80-f {
    margin-left: 80px !important;
  }

  .mg-md-85 {
    margin: 85px;
  }

  .mg-md-85-f {
    margin: 85px !important;
  }

  .mg-md-y-85 {
    margin-top: 85px;
    margin-bottom: 85px;
  }

  .mg-md-y-85-f {
    margin-top: 85px !important;
    margin-bottom: 85px !important;
  }

  .mg-md-x-85 {
    margin-left: 85px;
    margin-right: 85px;
  }

  .mg-md-x-85-f {
    margin-left: 85px !important;
    margin-right: 85px !important;
  }

  .mg-md-t-85 {
    margin-top: 85px;
  }

  .mg-md-r-85 {
    margin-right: 85px;
  }

  .mg-md-b-85 {
    margin-bottom: 85px;
  }

  .mg-md-l-85 {
    margin-left: 85px;
  }

  .mg-md-t-85-f {
    margin-top: 85px !important;
  }

  .mg-md-r-85-f {
    margin-right: 85px !important;
  }

  .mg-md-b-85-f {
    margin-bottom: 85px !important;
  }

  .mg-md-l-85-f {
    margin-left: 85px !important;
  }

  .mg-md-90 {
    margin: 90px;
  }

  .mg-md-90-f {
    margin: 90px !important;
  }

  .mg-md-y-90 {
    margin-top: 90px;
    margin-bottom: 90px;
  }

  .mg-md-y-90-f {
    margin-top: 90px !important;
    margin-bottom: 90px !important;
  }

  .mg-md-x-90 {
    margin-left: 90px;
    margin-right: 90px;
  }

  .mg-md-x-90-f {
    margin-left: 90px !important;
    margin-right: 90px !important;
  }

  .mg-md-t-90 {
    margin-top: 90px;
  }

  .mg-md-r-90 {
    margin-right: 90px;
  }

  .mg-md-b-90 {
    margin-bottom: 90px;
  }

  .mg-md-l-90 {
    margin-left: 90px;
  }

  .mg-md-t-90-f {
    margin-top: 90px !important;
  }

  .mg-md-r-90-f {
    margin-right: 90px !important;
  }

  .mg-md-b-90-f {
    margin-bottom: 90px !important;
  }

  .mg-md-l-90-f {
    margin-left: 90px !important;
  }

  .mg-md-95 {
    margin: 95px;
  }

  .mg-md-95-f {
    margin: 95px !important;
  }

  .mg-md-y-95 {
    margin-top: 95px;
    margin-bottom: 95px;
  }

  .mg-md-y-95-f {
    margin-top: 95px !important;
    margin-bottom: 95px !important;
  }

  .mg-md-x-95 {
    margin-left: 95px;
    margin-right: 95px;
  }

  .mg-md-x-95-f {
    margin-left: 95px !important;
    margin-right: 95px !important;
  }

  .mg-md-t-95 {
    margin-top: 95px;
  }

  .mg-md-r-95 {
    margin-right: 95px;
  }

  .mg-md-b-95 {
    margin-bottom: 95px;
  }

  .mg-md-l-95 {
    margin-left: 95px;
  }

  .mg-md-t-95-f {
    margin-top: 95px !important;
  }

  .mg-md-r-95-f {
    margin-right: 95px !important;
  }

  .mg-md-b-95-f {
    margin-bottom: 95px !important;
  }

  .mg-md-l-95-f {
    margin-left: 95px !important;
  }

  .mg-md-100 {
    margin: 100px;
  }

  .mg-md-100-f {
    margin: 100px !important;
  }

  .mg-md-y-100 {
    margin-top: 100px;
    margin-bottom: 100px;
  }

  .mg-md-y-100-f {
    margin-top: 100px !important;
    margin-bottom: 100px !important;
  }

  .mg-md-x-100 {
    margin-left: 100px;
    margin-right: 100px;
  }

  .mg-md-x-100-f {
    margin-left: 100px !important;
    margin-right: 100px !important;
  }

  .mg-md-t-100 {
    margin-top: 100px;
  }

  .mg-md-r-100 {
    margin-right: 100px;
  }

  .mg-md-b-100 {
    margin-bottom: 100px;
  }

  .mg-md-l-100 {
    margin-left: 100px;
  }

  .mg-md-t-100-f {
    margin-top: 100px !important;
  }

  .mg-md-r-100-f {
    margin-right: 100px !important;
  }

  .mg-md-b-100-f {
    margin-bottom: 100px !important;
  }

  .mg-md-l-100-f {
    margin-left: 100px !important;
  }

  .mg-md-t-auto {
    margin-top: auto;
  }

  .mg-md-r-auto {
    margin-right: auto;
  }

  .mg-md-b-auto {
    margin-bottom: auto;
  }

  .mg-md-l-auto {
    margin-left: auto;
  }

  .mg-md-auto {
    margin: auto;
  }

  .mg-md-x-auto {
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 992px) {
  .mg-lg-0 {
    margin: 0px;
  }

  .mg-lg-0-f {
    margin: 0px !important;
  }

  .mg-lg-y-0 {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .mg-lg-y-0-f {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }

  .mg-lg-x-0 {
    margin-left: 0px;
    margin-right: 0px;
  }

  .mg-lg-x-0-f {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .mg-lg-t-0 {
    margin-top: 0px;
  }

  .mg-lg-r-0 {
    margin-right: 0px;
  }

  .mg-lg-b-0 {
    margin-bottom: 0px;
  }

  .mg-lg-l-0 {
    margin-left: 0px;
  }

  .mg-lg-t-0-f {
    margin-top: 0px !important;
  }

  .mg-lg-r-0-f {
    margin-right: 0px !important;
  }

  .mg-lg-b-0-f {
    margin-bottom: 0px !important;
  }

  .mg-lg-l-0-f {
    margin-left: 0px !important;
  }

  .mg-lg-1 {
    margin: 1px;
  }

  .mg-lg-1-f {
    margin: 1px !important;
  }

  .mg-lg-y-1 {
    margin-top: 1px;
    margin-bottom: 1px;
  }

  .mg-lg-y-1-f {
    margin-top: 1px !important;
    margin-bottom: 1px !important;
  }

  .mg-lg-x-1 {
    margin-left: 1px;
    margin-right: 1px;
  }

  .mg-lg-x-1-f {
    margin-left: 1px !important;
    margin-right: 1px !important;
  }

  .mg-lg-t-1 {
    margin-top: 1px;
  }

  .mg-lg-r-1 {
    margin-right: 1px;
  }

  .mg-lg-b-1 {
    margin-bottom: 1px;
  }

  .mg-lg-l-1 {
    margin-left: 1px;
  }

  .mg-lg-t-1-f {
    margin-top: 1px !important;
  }

  .mg-lg-r-1-f {
    margin-right: 1px !important;
  }

  .mg-lg-b-1-f {
    margin-bottom: 1px !important;
  }

  .mg-lg-l-1-f {
    margin-left: 1px !important;
  }

  .mg-lg-2 {
    margin: 2px;
  }

  .mg-lg-2-f {
    margin: 2px !important;
  }

  .mg-lg-y-2 {
    margin-top: 2px;
    margin-bottom: 2px;
  }

  .mg-lg-y-2-f {
    margin-top: 2px !important;
    margin-bottom: 2px !important;
  }

  .mg-lg-x-2 {
    margin-left: 2px;
    margin-right: 2px;
  }

  .mg-lg-x-2-f {
    margin-left: 2px !important;
    margin-right: 2px !important;
  }

  .mg-lg-t-2 {
    margin-top: 2px;
  }

  .mg-lg-r-2 {
    margin-right: 2px;
  }

  .mg-lg-b-2 {
    margin-bottom: 2px;
  }

  .mg-lg-l-2 {
    margin-left: 2px;
  }

  .mg-lg-t-2-f {
    margin-top: 2px !important;
  }

  .mg-lg-r-2-f {
    margin-right: 2px !important;
  }

  .mg-lg-b-2-f {
    margin-bottom: 2px !important;
  }

  .mg-lg-l-2-f {
    margin-left: 2px !important;
  }

  .mg-lg-3 {
    margin: 3px;
  }

  .mg-lg-3-f {
    margin: 3px !important;
  }

  .mg-lg-y-3 {
    margin-top: 3px;
    margin-bottom: 3px;
  }

  .mg-lg-y-3-f {
    margin-top: 3px !important;
    margin-bottom: 3px !important;
  }

  .mg-lg-x-3 {
    margin-left: 3px;
    margin-right: 3px;
  }

  .mg-lg-x-3-f {
    margin-left: 3px !important;
    margin-right: 3px !important;
  }

  .mg-lg-t-3 {
    margin-top: 3px;
  }

  .mg-lg-r-3 {
    margin-right: 3px;
  }

  .mg-lg-b-3 {
    margin-bottom: 3px;
  }

  .mg-lg-l-3 {
    margin-left: 3px;
  }

  .mg-lg-t-3-f {
    margin-top: 3px !important;
  }

  .mg-lg-r-3-f {
    margin-right: 3px !important;
  }

  .mg-lg-b-3-f {
    margin-bottom: 3px !important;
  }

  .mg-lg-l-3-f {
    margin-left: 3px !important;
  }

  .mg-lg-4 {
    margin: 4px;
  }

  .mg-lg-4-f {
    margin: 4px !important;
  }

  .mg-lg-y-4 {
    margin-top: 4px;
    margin-bottom: 4px;
  }

  .mg-lg-y-4-f {
    margin-top: 4px !important;
    margin-bottom: 4px !important;
  }

  .mg-lg-x-4 {
    margin-left: 4px;
    margin-right: 4px;
  }

  .mg-lg-x-4-f {
    margin-left: 4px !important;
    margin-right: 4px !important;
  }

  .mg-lg-t-4 {
    margin-top: 4px;
  }

  .mg-lg-r-4 {
    margin-right: 4px;
  }

  .mg-lg-b-4 {
    margin-bottom: 4px;
  }

  .mg-lg-l-4 {
    margin-left: 4px;
  }

  .mg-lg-t-4-f {
    margin-top: 4px !important;
  }

  .mg-lg-r-4-f {
    margin-right: 4px !important;
  }

  .mg-lg-b-4-f {
    margin-bottom: 4px !important;
  }

  .mg-lg-l-4-f {
    margin-left: 4px !important;
  }

  .mg-lg-5 {
    margin: 5px;
  }

  .mg-lg-5-f {
    margin: 5px !important;
  }

  .mg-lg-y-5 {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .mg-lg-y-5-f {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }

  .mg-lg-x-5 {
    margin-left: 5px;
    margin-right: 5px;
  }

  .mg-lg-x-5-f {
    margin-left: 5px !important;
    margin-right: 5px !important;
  }

  .mg-lg-t-5 {
    margin-top: 5px;
  }

  .mg-lg-r-5 {
    margin-right: 5px;
  }

  .mg-lg-b-5 {
    margin-bottom: 5px;
  }

  .mg-lg-l-5 {
    margin-left: 5px;
  }

  .mg-lg-t-5-f {
    margin-top: 5px !important;
  }

  .mg-lg-r-5-f {
    margin-right: 5px !important;
  }

  .mg-lg-b-5-f {
    margin-bottom: 5px !important;
  }

  .mg-lg-l-5-f {
    margin-left: 5px !important;
  }

  .mg-lg-6 {
    margin: 6px;
  }

  .mg-lg-6-f {
    margin: 6px !important;
  }

  .mg-lg-y-6 {
    margin-top: 6px;
    margin-bottom: 6px;
  }

  .mg-lg-y-6-f {
    margin-top: 6px !important;
    margin-bottom: 6px !important;
  }

  .mg-lg-x-6 {
    margin-left: 6px;
    margin-right: 6px;
  }

  .mg-lg-x-6-f {
    margin-left: 6px !important;
    margin-right: 6px !important;
  }

  .mg-lg-t-6 {
    margin-top: 6px;
  }

  .mg-lg-r-6 {
    margin-right: 6px;
  }

  .mg-lg-b-6 {
    margin-bottom: 6px;
  }

  .mg-lg-l-6 {
    margin-left: 6px;
  }

  .mg-lg-t-6-f {
    margin-top: 6px !important;
  }

  .mg-lg-r-6-f {
    margin-right: 6px !important;
  }

  .mg-lg-b-6-f {
    margin-bottom: 6px !important;
  }

  .mg-lg-l-6-f {
    margin-left: 6px !important;
  }

  .mg-lg-7 {
    margin: 7px;
  }

  .mg-lg-7-f {
    margin: 7px !important;
  }

  .mg-lg-y-7 {
    margin-top: 7px;
    margin-bottom: 7px;
  }

  .mg-lg-y-7-f {
    margin-top: 7px !important;
    margin-bottom: 7px !important;
  }

  .mg-lg-x-7 {
    margin-left: 7px;
    margin-right: 7px;
  }

  .mg-lg-x-7-f {
    margin-left: 7px !important;
    margin-right: 7px !important;
  }

  .mg-lg-t-7 {
    margin-top: 7px;
  }

  .mg-lg-r-7 {
    margin-right: 7px;
  }

  .mg-lg-b-7 {
    margin-bottom: 7px;
  }

  .mg-lg-l-7 {
    margin-left: 7px;
  }

  .mg-lg-t-7-f {
    margin-top: 7px !important;
  }

  .mg-lg-r-7-f {
    margin-right: 7px !important;
  }

  .mg-lg-b-7-f {
    margin-bottom: 7px !important;
  }

  .mg-lg-l-7-f {
    margin-left: 7px !important;
  }

  .mg-lg-8 {
    margin: 8px;
  }

  .mg-lg-8-f {
    margin: 8px !important;
  }

  .mg-lg-y-8 {
    margin-top: 8px;
    margin-bottom: 8px;
  }

  .mg-lg-y-8-f {
    margin-top: 8px !important;
    margin-bottom: 8px !important;
  }

  .mg-lg-x-8 {
    margin-left: 8px;
    margin-right: 8px;
  }

  .mg-lg-x-8-f {
    margin-left: 8px !important;
    margin-right: 8px !important;
  }

  .mg-lg-t-8 {
    margin-top: 8px;
  }

  .mg-lg-r-8 {
    margin-right: 8px;
  }

  .mg-lg-b-8 {
    margin-bottom: 8px;
  }

  .mg-lg-l-8 {
    margin-left: 8px;
  }

  .mg-lg-t-8-f {
    margin-top: 8px !important;
  }

  .mg-lg-r-8-f {
    margin-right: 8px !important;
  }

  .mg-lg-b-8-f {
    margin-bottom: 8px !important;
  }

  .mg-lg-l-8-f {
    margin-left: 8px !important;
  }

  .mg-lg-9 {
    margin: 9px;
  }

  .mg-lg-9-f {
    margin: 9px !important;
  }

  .mg-lg-y-9 {
    margin-top: 9px;
    margin-bottom: 9px;
  }

  .mg-lg-y-9-f {
    margin-top: 9px !important;
    margin-bottom: 9px !important;
  }

  .mg-lg-x-9 {
    margin-left: 9px;
    margin-right: 9px;
  }

  .mg-lg-x-9-f {
    margin-left: 9px !important;
    margin-right: 9px !important;
  }

  .mg-lg-t-9 {
    margin-top: 9px;
  }

  .mg-lg-r-9 {
    margin-right: 9px;
  }

  .mg-lg-b-9 {
    margin-bottom: 9px;
  }

  .mg-lg-l-9 {
    margin-left: 9px;
  }

  .mg-lg-t-9-f {
    margin-top: 9px !important;
  }

  .mg-lg-r-9-f {
    margin-right: 9px !important;
  }

  .mg-lg-b-9-f {
    margin-bottom: 9px !important;
  }

  .mg-lg-l-9-f {
    margin-left: 9px !important;
  }

  .mg-lg-10 {
    margin: 10px;
  }

  .mg-lg-10-f {
    margin: 10px !important;
  }

  .mg-lg-y-10 {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .mg-lg-y-10-f {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }

  .mg-lg-x-10 {
    margin-left: 10px;
    margin-right: 10px;
  }

  .mg-lg-x-10-f {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }

  .mg-lg-t-10 {
    margin-top: 10px;
  }

  .mg-lg-r-10 {
    margin-right: 10px;
  }

  .mg-lg-b-10 {
    margin-bottom: 10px;
  }

  .mg-lg-l-10 {
    margin-left: 10px;
  }

  .mg-lg-t-10-f {
    margin-top: 10px !important;
  }

  .mg-lg-r-10-f {
    margin-right: 10px !important;
  }

  .mg-lg-b-10-f {
    margin-bottom: 10px !important;
  }

  .mg-lg-l-10-f {
    margin-left: 10px !important;
  }

  .mg-lg-15 {
    margin: 15px;
  }

  .mg-lg-15-f {
    margin: 15px !important;
  }

  .mg-lg-y-15 {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .mg-lg-y-15-f {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }

  .mg-lg-x-15 {
    margin-left: 15px;
    margin-right: 15px;
  }

  .mg-lg-x-15-f {
    margin-left: 15px !important;
    margin-right: 15px !important;
  }

  .mg-lg-t-15 {
    margin-top: 15px;
  }

  .mg-lg-r-15 {
    margin-right: 15px;
  }

  .mg-lg-b-15 {
    margin-bottom: 15px;
  }

  .mg-lg-l-15 {
    margin-left: 15px;
  }

  .mg-lg-t-15-f {
    margin-top: 15px !important;
  }

  .mg-lg-r-15-f {
    margin-right: 15px !important;
  }

  .mg-lg-b-15-f {
    margin-bottom: 15px !important;
  }

  .mg-lg-l-15-f {
    margin-left: 15px !important;
  }

  .mg-lg-20 {
    margin: 20px;
  }

  .mg-lg-20-f {
    margin: 20px !important;
  }

  .mg-lg-y-20 {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .mg-lg-y-20-f {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }

  .mg-lg-x-20 {
    margin-left: 20px;
    margin-right: 20px;
  }

  .mg-lg-x-20-f {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }

  .mg-lg-t-20 {
    margin-top: 20px;
  }

  .mg-lg-r-20 {
    margin-right: 20px;
  }

  .mg-lg-b-20 {
    margin-bottom: 20px;
  }

  .mg-lg-l-20 {
    margin-left: 20px;
  }

  .mg-lg-t-20-f {
    margin-top: 20px !important;
  }

  .mg-lg-r-20-f {
    margin-right: 20px !important;
  }

  .mg-lg-b-20-f {
    margin-bottom: 20px !important;
  }

  .mg-lg-l-20-f {
    margin-left: 20px !important;
  }

  .mg-lg-25 {
    margin: 25px;
  }

  .mg-lg-25-f {
    margin: 25px !important;
  }

  .mg-lg-y-25 {
    margin-top: 25px;
    margin-bottom: 25px;
  }

  .mg-lg-y-25-f {
    margin-top: 25px !important;
    margin-bottom: 25px !important;
  }

  .mg-lg-x-25 {
    margin-left: 25px;
    margin-right: 25px;
  }

  .mg-lg-x-25-f {
    margin-left: 25px !important;
    margin-right: 25px !important;
  }

  .mg-lg-t-25 {
    margin-top: 25px;
  }

  .mg-lg-r-25 {
    margin-right: 25px;
  }

  .mg-lg-b-25 {
    margin-bottom: 25px;
  }

  .mg-lg-l-25 {
    margin-left: 25px;
  }

  .mg-lg-t-25-f {
    margin-top: 25px !important;
  }

  .mg-lg-r-25-f {
    margin-right: 25px !important;
  }

  .mg-lg-b-25-f {
    margin-bottom: 25px !important;
  }

  .mg-lg-l-25-f {
    margin-left: 25px !important;
  }

  .mg-lg-30 {
    margin: 30px;
  }

  .mg-lg-30-f {
    margin: 30px !important;
  }

  .mg-lg-y-30 {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .mg-lg-y-30-f {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }

  .mg-lg-x-30 {
    margin-left: 30px;
    margin-right: 30px;
  }

  .mg-lg-x-30-f {
    margin-left: 30px !important;
    margin-right: 30px !important;
  }

  .mg-lg-t-30 {
    margin-top: 30px;
  }

  .mg-lg-r-30 {
    margin-right: 30px;
  }

  .mg-lg-b-30 {
    margin-bottom: 30px;
  }

  .mg-lg-l-30 {
    margin-left: 30px;
  }

  .mg-lg-t-30-f {
    margin-top: 30px !important;
  }

  .mg-lg-r-30-f {
    margin-right: 30px !important;
  }

  .mg-lg-b-30-f {
    margin-bottom: 30px !important;
  }

  .mg-lg-l-30-f {
    margin-left: 30px !important;
  }

  .mg-lg-35 {
    margin: 35px;
  }

  .mg-lg-35-f {
    margin: 35px !important;
  }

  .mg-lg-y-35 {
    margin-top: 35px;
    margin-bottom: 35px;
  }

  .mg-lg-y-35-f {
    margin-top: 35px !important;
    margin-bottom: 35px !important;
  }

  .mg-lg-x-35 {
    margin-left: 35px;
    margin-right: 35px;
  }

  .mg-lg-x-35-f {
    margin-left: 35px !important;
    margin-right: 35px !important;
  }

  .mg-lg-t-35 {
    margin-top: 35px;
  }

  .mg-lg-r-35 {
    margin-right: 35px;
  }

  .mg-lg-b-35 {
    margin-bottom: 35px;
  }

  .mg-lg-l-35 {
    margin-left: 35px;
  }

  .mg-lg-t-35-f {
    margin-top: 35px !important;
  }

  .mg-lg-r-35-f {
    margin-right: 35px !important;
  }

  .mg-lg-b-35-f {
    margin-bottom: 35px !important;
  }

  .mg-lg-l-35-f {
    margin-left: 35px !important;
  }

  .mg-lg-40 {
    margin: 40px;
  }

  .mg-lg-40-f {
    margin: 40px !important;
  }

  .mg-lg-y-40 {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .mg-lg-y-40-f {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }

  .mg-lg-x-40 {
    margin-left: 40px;
    margin-right: 40px;
  }

  .mg-lg-x-40-f {
    margin-left: 40px !important;
    margin-right: 40px !important;
  }

  .mg-lg-t-40 {
    margin-top: 40px;
  }

  .mg-lg-r-40 {
    margin-right: 40px;
  }

  .mg-lg-b-40 {
    margin-bottom: 40px;
  }

  .mg-lg-l-40 {
    margin-left: 40px;
  }

  .mg-lg-t-40-f {
    margin-top: 40px !important;
  }

  .mg-lg-r-40-f {
    margin-right: 40px !important;
  }

  .mg-lg-b-40-f {
    margin-bottom: 40px !important;
  }

  .mg-lg-l-40-f {
    margin-left: 40px !important;
  }

  .mg-lg-45 {
    margin: 45px;
  }

  .mg-lg-45-f {
    margin: 45px !important;
  }

  .mg-lg-y-45 {
    margin-top: 45px;
    margin-bottom: 45px;
  }

  .mg-lg-y-45-f {
    margin-top: 45px !important;
    margin-bottom: 45px !important;
  }

  .mg-lg-x-45 {
    margin-left: 45px;
    margin-right: 45px;
  }

  .mg-lg-x-45-f {
    margin-left: 45px !important;
    margin-right: 45px !important;
  }

  .mg-lg-t-45 {
    margin-top: 45px;
  }

  .mg-lg-r-45 {
    margin-right: 45px;
  }

  .mg-lg-b-45 {
    margin-bottom: 45px;
  }

  .mg-lg-l-45 {
    margin-left: 45px;
  }

  .mg-lg-t-45-f {
    margin-top: 45px !important;
  }

  .mg-lg-r-45-f {
    margin-right: 45px !important;
  }

  .mg-lg-b-45-f {
    margin-bottom: 45px !important;
  }

  .mg-lg-l-45-f {
    margin-left: 45px !important;
  }

  .mg-lg-50 {
    margin: 50px;
  }

  .mg-lg-50-f {
    margin: 50px !important;
  }

  .mg-lg-y-50 {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .mg-lg-y-50-f {
    margin-top: 50px !important;
    margin-bottom: 50px !important;
  }

  .mg-lg-x-50 {
    margin-left: 50px;
    margin-right: 50px;
  }

  .mg-lg-x-50-f {
    margin-left: 50px !important;
    margin-right: 50px !important;
  }

  .mg-lg-t-50 {
    margin-top: 50px;
  }

  .mg-lg-r-50 {
    margin-right: 50px;
  }

  .mg-lg-b-50 {
    margin-bottom: 50px;
  }

  .mg-lg-l-50 {
    margin-left: 50px;
  }

  .mg-lg-t-50-f {
    margin-top: 50px !important;
  }

  .mg-lg-r-50-f {
    margin-right: 50px !important;
  }

  .mg-lg-b-50-f {
    margin-bottom: 50px !important;
  }

  .mg-lg-l-50-f {
    margin-left: 50px !important;
  }

  .mg-lg-55 {
    margin: 55px;
  }

  .mg-lg-55-f {
    margin: 55px !important;
  }

  .mg-lg-y-55 {
    margin-top: 55px;
    margin-bottom: 55px;
  }

  .mg-lg-y-55-f {
    margin-top: 55px !important;
    margin-bottom: 55px !important;
  }

  .mg-lg-x-55 {
    margin-left: 55px;
    margin-right: 55px;
  }

  .mg-lg-x-55-f {
    margin-left: 55px !important;
    margin-right: 55px !important;
  }

  .mg-lg-t-55 {
    margin-top: 55px;
  }

  .mg-lg-r-55 {
    margin-right: 55px;
  }

  .mg-lg-b-55 {
    margin-bottom: 55px;
  }

  .mg-lg-l-55 {
    margin-left: 55px;
  }

  .mg-lg-t-55-f {
    margin-top: 55px !important;
  }

  .mg-lg-r-55-f {
    margin-right: 55px !important;
  }

  .mg-lg-b-55-f {
    margin-bottom: 55px !important;
  }

  .mg-lg-l-55-f {
    margin-left: 55px !important;
  }

  .mg-lg-60 {
    margin: 60px;
  }

  .mg-lg-60-f {
    margin: 60px !important;
  }

  .mg-lg-y-60 {
    margin-top: 60px;
    margin-bottom: 60px;
  }

  .mg-lg-y-60-f {
    margin-top: 60px !important;
    margin-bottom: 60px !important;
  }

  .mg-lg-x-60 {
    margin-left: 60px;
    margin-right: 60px;
  }

  .mg-lg-x-60-f {
    margin-left: 60px !important;
    margin-right: 60px !important;
  }

  .mg-lg-t-60 {
    margin-top: 60px;
  }

  .mg-lg-r-60 {
    margin-right: 60px;
  }

  .mg-lg-b-60 {
    margin-bottom: 60px;
  }

  .mg-lg-l-60 {
    margin-left: 60px;
  }

  .mg-lg-t-60-f {
    margin-top: 60px !important;
  }

  .mg-lg-r-60-f {
    margin-right: 60px !important;
  }

  .mg-lg-b-60-f {
    margin-bottom: 60px !important;
  }

  .mg-lg-l-60-f {
    margin-left: 60px !important;
  }

  .mg-lg-65 {
    margin: 65px;
  }

  .mg-lg-65-f {
    margin: 65px !important;
  }

  .mg-lg-y-65 {
    margin-top: 65px;
    margin-bottom: 65px;
  }

  .mg-lg-y-65-f {
    margin-top: 65px !important;
    margin-bottom: 65px !important;
  }

  .mg-lg-x-65 {
    margin-left: 65px;
    margin-right: 65px;
  }

  .mg-lg-x-65-f {
    margin-left: 65px !important;
    margin-right: 65px !important;
  }

  .mg-lg-t-65 {
    margin-top: 65px;
  }

  .mg-lg-r-65 {
    margin-right: 65px;
  }

  .mg-lg-b-65 {
    margin-bottom: 65px;
  }

  .mg-lg-l-65 {
    margin-left: 65px;
  }

  .mg-lg-t-65-f {
    margin-top: 65px !important;
  }

  .mg-lg-r-65-f {
    margin-right: 65px !important;
  }

  .mg-lg-b-65-f {
    margin-bottom: 65px !important;
  }

  .mg-lg-l-65-f {
    margin-left: 65px !important;
  }

  .mg-lg-70 {
    margin: 70px;
  }

  .mg-lg-70-f {
    margin: 70px !important;
  }

  .mg-lg-y-70 {
    margin-top: 70px;
    margin-bottom: 70px;
  }

  .mg-lg-y-70-f {
    margin-top: 70px !important;
    margin-bottom: 70px !important;
  }

  .mg-lg-x-70 {
    margin-left: 70px;
    margin-right: 70px;
  }

  .mg-lg-x-70-f {
    margin-left: 70px !important;
    margin-right: 70px !important;
  }

  .mg-lg-t-70 {
    margin-top: 70px;
  }

  .mg-lg-r-70 {
    margin-right: 70px;
  }

  .mg-lg-b-70 {
    margin-bottom: 70px;
  }

  .mg-lg-l-70 {
    margin-left: 70px;
  }

  .mg-lg-t-70-f {
    margin-top: 70px !important;
  }

  .mg-lg-r-70-f {
    margin-right: 70px !important;
  }

  .mg-lg-b-70-f {
    margin-bottom: 70px !important;
  }

  .mg-lg-l-70-f {
    margin-left: 70px !important;
  }

  .mg-lg-75 {
    margin: 75px;
  }

  .mg-lg-75-f {
    margin: 75px !important;
  }

  .mg-lg-y-75 {
    margin-top: 75px;
    margin-bottom: 75px;
  }

  .mg-lg-y-75-f {
    margin-top: 75px !important;
    margin-bottom: 75px !important;
  }

  .mg-lg-x-75 {
    margin-left: 75px;
    margin-right: 75px;
  }

  .mg-lg-x-75-f {
    margin-left: 75px !important;
    margin-right: 75px !important;
  }

  .mg-lg-t-75 {
    margin-top: 75px;
  }

  .mg-lg-r-75 {
    margin-right: 75px;
  }

  .mg-lg-b-75 {
    margin-bottom: 75px;
  }

  .mg-lg-l-75 {
    margin-left: 75px;
  }

  .mg-lg-t-75-f {
    margin-top: 75px !important;
  }

  .mg-lg-r-75-f {
    margin-right: 75px !important;
  }

  .mg-lg-b-75-f {
    margin-bottom: 75px !important;
  }

  .mg-lg-l-75-f {
    margin-left: 75px !important;
  }

  .mg-lg-80 {
    margin: 80px;
  }

  .mg-lg-80-f {
    margin: 80px !important;
  }

  .mg-lg-y-80 {
    margin-top: 80px;
    margin-bottom: 80px;
  }

  .mg-lg-y-80-f {
    margin-top: 80px !important;
    margin-bottom: 80px !important;
  }

  .mg-lg-x-80 {
    margin-left: 80px;
    margin-right: 80px;
  }

  .mg-lg-x-80-f {
    margin-left: 80px !important;
    margin-right: 80px !important;
  }

  .mg-lg-t-80 {
    margin-top: 80px;
  }

  .mg-lg-r-80 {
    margin-right: 80px;
  }

  .mg-lg-b-80 {
    margin-bottom: 80px;
  }

  .mg-lg-l-80 {
    margin-left: 80px;
  }

  .mg-lg-t-80-f {
    margin-top: 80px !important;
  }

  .mg-lg-r-80-f {
    margin-right: 80px !important;
  }

  .mg-lg-b-80-f {
    margin-bottom: 80px !important;
  }

  .mg-lg-l-80-f {
    margin-left: 80px !important;
  }

  .mg-lg-85 {
    margin: 85px;
  }

  .mg-lg-85-f {
    margin: 85px !important;
  }

  .mg-lg-y-85 {
    margin-top: 85px;
    margin-bottom: 85px;
  }

  .mg-lg-y-85-f {
    margin-top: 85px !important;
    margin-bottom: 85px !important;
  }

  .mg-lg-x-85 {
    margin-left: 85px;
    margin-right: 85px;
  }

  .mg-lg-x-85-f {
    margin-left: 85px !important;
    margin-right: 85px !important;
  }

  .mg-lg-t-85 {
    margin-top: 85px;
  }

  .mg-lg-r-85 {
    margin-right: 85px;
  }

  .mg-lg-b-85 {
    margin-bottom: 85px;
  }

  .mg-lg-l-85 {
    margin-left: 85px;
  }

  .mg-lg-t-85-f {
    margin-top: 85px !important;
  }

  .mg-lg-r-85-f {
    margin-right: 85px !important;
  }

  .mg-lg-b-85-f {
    margin-bottom: 85px !important;
  }

  .mg-lg-l-85-f {
    margin-left: 85px !important;
  }

  .mg-lg-90 {
    margin: 90px;
  }

  .mg-lg-90-f {
    margin: 90px !important;
  }

  .mg-lg-y-90 {
    margin-top: 90px;
    margin-bottom: 90px;
  }

  .mg-lg-y-90-f {
    margin-top: 90px !important;
    margin-bottom: 90px !important;
  }

  .mg-lg-x-90 {
    margin-left: 90px;
    margin-right: 90px;
  }

  .mg-lg-x-90-f {
    margin-left: 90px !important;
    margin-right: 90px !important;
  }

  .mg-lg-t-90 {
    margin-top: 90px;
  }

  .mg-lg-r-90 {
    margin-right: 90px;
  }

  .mg-lg-b-90 {
    margin-bottom: 90px;
  }

  .mg-lg-l-90 {
    margin-left: 90px;
  }

  .mg-lg-t-90-f {
    margin-top: 90px !important;
  }

  .mg-lg-r-90-f {
    margin-right: 90px !important;
  }

  .mg-lg-b-90-f {
    margin-bottom: 90px !important;
  }

  .mg-lg-l-90-f {
    margin-left: 90px !important;
  }

  .mg-lg-95 {
    margin: 95px;
  }

  .mg-lg-95-f {
    margin: 95px !important;
  }

  .mg-lg-y-95 {
    margin-top: 95px;
    margin-bottom: 95px;
  }

  .mg-lg-y-95-f {
    margin-top: 95px !important;
    margin-bottom: 95px !important;
  }

  .mg-lg-x-95 {
    margin-left: 95px;
    margin-right: 95px;
  }

  .mg-lg-x-95-f {
    margin-left: 95px !important;
    margin-right: 95px !important;
  }

  .mg-lg-t-95 {
    margin-top: 95px;
  }

  .mg-lg-r-95 {
    margin-right: 95px;
  }

  .mg-lg-b-95 {
    margin-bottom: 95px;
  }

  .mg-lg-l-95 {
    margin-left: 95px;
  }

  .mg-lg-t-95-f {
    margin-top: 95px !important;
  }

  .mg-lg-r-95-f {
    margin-right: 95px !important;
  }

  .mg-lg-b-95-f {
    margin-bottom: 95px !important;
  }

  .mg-lg-l-95-f {
    margin-left: 95px !important;
  }

  .mg-lg-100 {
    margin: 100px;
  }

  .mg-lg-100-f {
    margin: 100px !important;
  }

  .mg-lg-y-100 {
    margin-top: 100px;
    margin-bottom: 100px;
  }

  .mg-lg-y-100-f {
    margin-top: 100px !important;
    margin-bottom: 100px !important;
  }

  .mg-lg-x-100 {
    margin-left: 100px;
    margin-right: 100px;
  }

  .mg-lg-x-100-f {
    margin-left: 100px !important;
    margin-right: 100px !important;
  }

  .mg-lg-t-100 {
    margin-top: 100px;
  }

  .mg-lg-r-100 {
    margin-right: 100px;
  }

  .mg-lg-b-100 {
    margin-bottom: 100px;
  }

  .mg-lg-l-100 {
    margin-left: 100px;
  }

  .mg-lg-t-100-f {
    margin-top: 100px !important;
  }

  .mg-lg-r-100-f {
    margin-right: 100px !important;
  }

  .mg-lg-b-100-f {
    margin-bottom: 100px !important;
  }

  .mg-lg-l-100-f {
    margin-left: 100px !important;
  }

  .mg-lg-t-auto {
    margin-top: auto;
  }

  .mg-lg-r-auto {
    margin-right: auto;
  }

  .mg-lg-b-auto {
    margin-bottom: auto;
  }

  .mg-lg-l-auto {
    margin-left: auto;
  }

  .mg-lg-auto {
    margin: auto;
  }

  .mg-lg-x-auto {
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 1200px) {
  .mg-xl-0 {
    margin: 0px;
  }

  .mg-xl-0-f {
    margin: 0px !important;
  }

  .mg-xl-y-0 {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .mg-xl-y-0-f {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }

  .mg-xl-x-0 {
    margin-left: 0px;
    margin-right: 0px;
  }

  .mg-xl-x-0-f {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .mg-xl-t-0 {
    margin-top: 0px;
  }

  .mg-xl-r-0 {
    margin-right: 0px;
  }

  .mg-xl-b-0 {
    margin-bottom: 0px;
  }

  .mg-xl-l-0 {
    margin-left: 0px;
  }

  .mg-xl-t-0-f {
    margin-top: 0px !important;
  }

  .mg-xl-r-0-f {
    margin-right: 0px !important;
  }

  .mg-xl-b-0-f {
    margin-bottom: 0px !important;
  }

  .mg-xl-l-0-f {
    margin-left: 0px !important;
  }

  .mg-xl-1 {
    margin: 1px;
  }

  .mg-xl-1-f {
    margin: 1px !important;
  }

  .mg-xl-y-1 {
    margin-top: 1px;
    margin-bottom: 1px;
  }

  .mg-xl-y-1-f {
    margin-top: 1px !important;
    margin-bottom: 1px !important;
  }

  .mg-xl-x-1 {
    margin-left: 1px;
    margin-right: 1px;
  }

  .mg-xl-x-1-f {
    margin-left: 1px !important;
    margin-right: 1px !important;
  }

  .mg-xl-t-1 {
    margin-top: 1px;
  }

  .mg-xl-r-1 {
    margin-right: 1px;
  }

  .mg-xl-b-1 {
    margin-bottom: 1px;
  }

  .mg-xl-l-1 {
    margin-left: 1px;
  }

  .mg-xl-t-1-f {
    margin-top: 1px !important;
  }

  .mg-xl-r-1-f {
    margin-right: 1px !important;
  }

  .mg-xl-b-1-f {
    margin-bottom: 1px !important;
  }

  .mg-xl-l-1-f {
    margin-left: 1px !important;
  }

  .mg-xl-2 {
    margin: 2px;
  }

  .mg-xl-2-f {
    margin: 2px !important;
  }

  .mg-xl-y-2 {
    margin-top: 2px;
    margin-bottom: 2px;
  }

  .mg-xl-y-2-f {
    margin-top: 2px !important;
    margin-bottom: 2px !important;
  }

  .mg-xl-x-2 {
    margin-left: 2px;
    margin-right: 2px;
  }

  .mg-xl-x-2-f {
    margin-left: 2px !important;
    margin-right: 2px !important;
  }

  .mg-xl-t-2 {
    margin-top: 2px;
  }

  .mg-xl-r-2 {
    margin-right: 2px;
  }

  .mg-xl-b-2 {
    margin-bottom: 2px;
  }

  .mg-xl-l-2 {
    margin-left: 2px;
  }

  .mg-xl-t-2-f {
    margin-top: 2px !important;
  }

  .mg-xl-r-2-f {
    margin-right: 2px !important;
  }

  .mg-xl-b-2-f {
    margin-bottom: 2px !important;
  }

  .mg-xl-l-2-f {
    margin-left: 2px !important;
  }

  .mg-xl-3 {
    margin: 3px;
  }

  .mg-xl-3-f {
    margin: 3px !important;
  }

  .mg-xl-y-3 {
    margin-top: 3px;
    margin-bottom: 3px;
  }

  .mg-xl-y-3-f {
    margin-top: 3px !important;
    margin-bottom: 3px !important;
  }

  .mg-xl-x-3 {
    margin-left: 3px;
    margin-right: 3px;
  }

  .mg-xl-x-3-f {
    margin-left: 3px !important;
    margin-right: 3px !important;
  }

  .mg-xl-t-3 {
    margin-top: 3px;
  }

  .mg-xl-r-3 {
    margin-right: 3px;
  }

  .mg-xl-b-3 {
    margin-bottom: 3px;
  }

  .mg-xl-l-3 {
    margin-left: 3px;
  }

  .mg-xl-t-3-f {
    margin-top: 3px !important;
  }

  .mg-xl-r-3-f {
    margin-right: 3px !important;
  }

  .mg-xl-b-3-f {
    margin-bottom: 3px !important;
  }

  .mg-xl-l-3-f {
    margin-left: 3px !important;
  }

  .mg-xl-4 {
    margin: 4px;
  }

  .mg-xl-4-f {
    margin: 4px !important;
  }

  .mg-xl-y-4 {
    margin-top: 4px;
    margin-bottom: 4px;
  }

  .mg-xl-y-4-f {
    margin-top: 4px !important;
    margin-bottom: 4px !important;
  }

  .mg-xl-x-4 {
    margin-left: 4px;
    margin-right: 4px;
  }

  .mg-xl-x-4-f {
    margin-left: 4px !important;
    margin-right: 4px !important;
  }

  .mg-xl-t-4 {
    margin-top: 4px;
  }

  .mg-xl-r-4 {
    margin-right: 4px;
  }

  .mg-xl-b-4 {
    margin-bottom: 4px;
  }

  .mg-xl-l-4 {
    margin-left: 4px;
  }

  .mg-xl-t-4-f {
    margin-top: 4px !important;
  }

  .mg-xl-r-4-f {
    margin-right: 4px !important;
  }

  .mg-xl-b-4-f {
    margin-bottom: 4px !important;
  }

  .mg-xl-l-4-f {
    margin-left: 4px !important;
  }

  .mg-xl-5 {
    margin: 5px;
  }

  .mg-xl-5-f {
    margin: 5px !important;
  }

  .mg-xl-y-5 {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .mg-xl-y-5-f {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }

  .mg-xl-x-5 {
    margin-left: 5px;
    margin-right: 5px;
  }

  .mg-xl-x-5-f {
    margin-left: 5px !important;
    margin-right: 5px !important;
  }

  .mg-xl-t-5 {
    margin-top: 5px;
  }

  .mg-xl-r-5 {
    margin-right: 5px;
  }

  .mg-xl-b-5 {
    margin-bottom: 5px;
  }

  .mg-xl-l-5 {
    margin-left: 5px;
  }

  .mg-xl-t-5-f {
    margin-top: 5px !important;
  }

  .mg-xl-r-5-f {
    margin-right: 5px !important;
  }

  .mg-xl-b-5-f {
    margin-bottom: 5px !important;
  }

  .mg-xl-l-5-f {
    margin-left: 5px !important;
  }

  .mg-xl-6 {
    margin: 6px;
  }

  .mg-xl-6-f {
    margin: 6px !important;
  }

  .mg-xl-y-6 {
    margin-top: 6px;
    margin-bottom: 6px;
  }

  .mg-xl-y-6-f {
    margin-top: 6px !important;
    margin-bottom: 6px !important;
  }

  .mg-xl-x-6 {
    margin-left: 6px;
    margin-right: 6px;
  }

  .mg-xl-x-6-f {
    margin-left: 6px !important;
    margin-right: 6px !important;
  }

  .mg-xl-t-6 {
    margin-top: 6px;
  }

  .mg-xl-r-6 {
    margin-right: 6px;
  }

  .mg-xl-b-6 {
    margin-bottom: 6px;
  }

  .mg-xl-l-6 {
    margin-left: 6px;
  }

  .mg-xl-t-6-f {
    margin-top: 6px !important;
  }

  .mg-xl-r-6-f {
    margin-right: 6px !important;
  }

  .mg-xl-b-6-f {
    margin-bottom: 6px !important;
  }

  .mg-xl-l-6-f {
    margin-left: 6px !important;
  }

  .mg-xl-7 {
    margin: 7px;
  }

  .mg-xl-7-f {
    margin: 7px !important;
  }

  .mg-xl-y-7 {
    margin-top: 7px;
    margin-bottom: 7px;
  }

  .mg-xl-y-7-f {
    margin-top: 7px !important;
    margin-bottom: 7px !important;
  }

  .mg-xl-x-7 {
    margin-left: 7px;
    margin-right: 7px;
  }

  .mg-xl-x-7-f {
    margin-left: 7px !important;
    margin-right: 7px !important;
  }

  .mg-xl-t-7 {
    margin-top: 7px;
  }

  .mg-xl-r-7 {
    margin-right: 7px;
  }

  .mg-xl-b-7 {
    margin-bottom: 7px;
  }

  .mg-xl-l-7 {
    margin-left: 7px;
  }

  .mg-xl-t-7-f {
    margin-top: 7px !important;
  }

  .mg-xl-r-7-f {
    margin-right: 7px !important;
  }

  .mg-xl-b-7-f {
    margin-bottom: 7px !important;
  }

  .mg-xl-l-7-f {
    margin-left: 7px !important;
  }

  .mg-xl-8 {
    margin: 8px;
  }

  .mg-xl-8-f {
    margin: 8px !important;
  }

  .mg-xl-y-8 {
    margin-top: 8px;
    margin-bottom: 8px;
  }

  .mg-xl-y-8-f {
    margin-top: 8px !important;
    margin-bottom: 8px !important;
  }

  .mg-xl-x-8 {
    margin-left: 8px;
    margin-right: 8px;
  }

  .mg-xl-x-8-f {
    margin-left: 8px !important;
    margin-right: 8px !important;
  }

  .mg-xl-t-8 {
    margin-top: 8px;
  }

  .mg-xl-r-8 {
    margin-right: 8px;
  }

  .mg-xl-b-8 {
    margin-bottom: 8px;
  }

  .mg-xl-l-8 {
    margin-left: 8px;
  }

  .mg-xl-t-8-f {
    margin-top: 8px !important;
  }

  .mg-xl-r-8-f {
    margin-right: 8px !important;
  }

  .mg-xl-b-8-f {
    margin-bottom: 8px !important;
  }

  .mg-xl-l-8-f {
    margin-left: 8px !important;
  }

  .mg-xl-9 {
    margin: 9px;
  }

  .mg-xl-9-f {
    margin: 9px !important;
  }

  .mg-xl-y-9 {
    margin-top: 9px;
    margin-bottom: 9px;
  }

  .mg-xl-y-9-f {
    margin-top: 9px !important;
    margin-bottom: 9px !important;
  }

  .mg-xl-x-9 {
    margin-left: 9px;
    margin-right: 9px;
  }

  .mg-xl-x-9-f {
    margin-left: 9px !important;
    margin-right: 9px !important;
  }

  .mg-xl-t-9 {
    margin-top: 9px;
  }

  .mg-xl-r-9 {
    margin-right: 9px;
  }

  .mg-xl-b-9 {
    margin-bottom: 9px;
  }

  .mg-xl-l-9 {
    margin-left: 9px;
  }

  .mg-xl-t-9-f {
    margin-top: 9px !important;
  }

  .mg-xl-r-9-f {
    margin-right: 9px !important;
  }

  .mg-xl-b-9-f {
    margin-bottom: 9px !important;
  }

  .mg-xl-l-9-f {
    margin-left: 9px !important;
  }

  .mg-xl-10 {
    margin: 10px;
  }

  .mg-xl-10-f {
    margin: 10px !important;
  }

  .mg-xl-y-10 {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .mg-xl-y-10-f {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }

  .mg-xl-x-10 {
    margin-left: 10px;
    margin-right: 10px;
  }

  .mg-xl-x-10-f {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }

  .mg-xl-t-10 {
    margin-top: 10px;
  }

  .mg-xl-r-10 {
    margin-right: 10px;
  }

  .mg-xl-b-10 {
    margin-bottom: 10px;
  }

  .mg-xl-l-10 {
    margin-left: 10px;
  }

  .mg-xl-t-10-f {
    margin-top: 10px !important;
  }

  .mg-xl-r-10-f {
    margin-right: 10px !important;
  }

  .mg-xl-b-10-f {
    margin-bottom: 10px !important;
  }

  .mg-xl-l-10-f {
    margin-left: 10px !important;
  }

  .mg-xl-15 {
    margin: 15px;
  }

  .mg-xl-15-f {
    margin: 15px !important;
  }

  .mg-xl-y-15 {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .mg-xl-y-15-f {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }

  .mg-xl-x-15 {
    margin-left: 15px;
    margin-right: 15px;
  }

  .mg-xl-x-15-f {
    margin-left: 15px !important;
    margin-right: 15px !important;
  }

  .mg-xl-t-15 {
    margin-top: 15px;
  }

  .mg-xl-r-15 {
    margin-right: 15px;
  }

  .mg-xl-b-15 {
    margin-bottom: 15px;
  }

  .mg-xl-l-15 {
    margin-left: 15px;
  }

  .mg-xl-t-15-f {
    margin-top: 15px !important;
  }

  .mg-xl-r-15-f {
    margin-right: 15px !important;
  }

  .mg-xl-b-15-f {
    margin-bottom: 15px !important;
  }

  .mg-xl-l-15-f {
    margin-left: 15px !important;
  }

  .mg-xl-20 {
    margin: 20px;
  }

  .mg-xl-20-f {
    margin: 20px !important;
  }

  .mg-xl-y-20 {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .mg-xl-y-20-f {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }

  .mg-xl-x-20 {
    margin-left: 20px;
    margin-right: 20px;
  }

  .mg-xl-x-20-f {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }

  .mg-xl-t-20 {
    margin-top: 20px;
  }

  .mg-xl-r-20 {
    margin-right: 20px;
  }

  .mg-xl-b-20 {
    margin-bottom: 20px;
  }

  .mg-xl-l-20 {
    margin-left: 20px;
  }

  .mg-xl-t-20-f {
    margin-top: 20px !important;
  }

  .mg-xl-r-20-f {
    margin-right: 20px !important;
  }

  .mg-xl-b-20-f {
    margin-bottom: 20px !important;
  }

  .mg-xl-l-20-f {
    margin-left: 20px !important;
  }

  .mg-xl-25 {
    margin: 25px;
  }

  .mg-xl-25-f {
    margin: 25px !important;
  }

  .mg-xl-y-25 {
    margin-top: 25px;
    margin-bottom: 25px;
  }

  .mg-xl-y-25-f {
    margin-top: 25px !important;
    margin-bottom: 25px !important;
  }

  .mg-xl-x-25 {
    margin-left: 25px;
    margin-right: 25px;
  }

  .mg-xl-x-25-f {
    margin-left: 25px !important;
    margin-right: 25px !important;
  }

  .mg-xl-t-25 {
    margin-top: 25px;
  }

  .mg-xl-r-25 {
    margin-right: 25px;
  }

  .mg-xl-b-25 {
    margin-bottom: 25px;
  }

  .mg-xl-l-25 {
    margin-left: 25px;
  }

  .mg-xl-t-25-f {
    margin-top: 25px !important;
  }

  .mg-xl-r-25-f {
    margin-right: 25px !important;
  }

  .mg-xl-b-25-f {
    margin-bottom: 25px !important;
  }

  .mg-xl-l-25-f {
    margin-left: 25px !important;
  }

  .mg-xl-30 {
    margin: 30px;
  }

  .mg-xl-30-f {
    margin: 30px !important;
  }

  .mg-xl-y-30 {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .mg-xl-y-30-f {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }

  .mg-xl-x-30 {
    margin-left: 30px;
    margin-right: 30px;
  }

  .mg-xl-x-30-f {
    margin-left: 30px !important;
    margin-right: 30px !important;
  }

  .mg-xl-t-30 {
    margin-top: 30px;
  }

  .mg-xl-r-30 {
    margin-right: 30px;
  }

  .mg-xl-b-30 {
    margin-bottom: 30px;
  }

  .mg-xl-l-30 {
    margin-left: 30px;
  }

  .mg-xl-t-30-f {
    margin-top: 30px !important;
  }

  .mg-xl-r-30-f {
    margin-right: 30px !important;
  }

  .mg-xl-b-30-f {
    margin-bottom: 30px !important;
  }

  .mg-xl-l-30-f {
    margin-left: 30px !important;
  }

  .mg-xl-35 {
    margin: 35px;
  }

  .mg-xl-35-f {
    margin: 35px !important;
  }

  .mg-xl-y-35 {
    margin-top: 35px;
    margin-bottom: 35px;
  }

  .mg-xl-y-35-f {
    margin-top: 35px !important;
    margin-bottom: 35px !important;
  }

  .mg-xl-x-35 {
    margin-left: 35px;
    margin-right: 35px;
  }

  .mg-xl-x-35-f {
    margin-left: 35px !important;
    margin-right: 35px !important;
  }

  .mg-xl-t-35 {
    margin-top: 35px;
  }

  .mg-xl-r-35 {
    margin-right: 35px;
  }

  .mg-xl-b-35 {
    margin-bottom: 35px;
  }

  .mg-xl-l-35 {
    margin-left: 35px;
  }

  .mg-xl-t-35-f {
    margin-top: 35px !important;
  }

  .mg-xl-r-35-f {
    margin-right: 35px !important;
  }

  .mg-xl-b-35-f {
    margin-bottom: 35px !important;
  }

  .mg-xl-l-35-f {
    margin-left: 35px !important;
  }

  .mg-xl-40 {
    margin: 40px;
  }

  .mg-xl-40-f {
    margin: 40px !important;
  }

  .mg-xl-y-40 {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .mg-xl-y-40-f {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }

  .mg-xl-x-40 {
    margin-left: 40px;
    margin-right: 40px;
  }

  .mg-xl-x-40-f {
    margin-left: 40px !important;
    margin-right: 40px !important;
  }

  .mg-xl-t-40 {
    margin-top: 40px;
  }

  .mg-xl-r-40 {
    margin-right: 40px;
  }

  .mg-xl-b-40 {
    margin-bottom: 40px;
  }

  .mg-xl-l-40 {
    margin-left: 40px;
  }

  .mg-xl-t-40-f {
    margin-top: 40px !important;
  }

  .mg-xl-r-40-f {
    margin-right: 40px !important;
  }

  .mg-xl-b-40-f {
    margin-bottom: 40px !important;
  }

  .mg-xl-l-40-f {
    margin-left: 40px !important;
  }

  .mg-xl-45 {
    margin: 45px;
  }

  .mg-xl-45-f {
    margin: 45px !important;
  }

  .mg-xl-y-45 {
    margin-top: 45px;
    margin-bottom: 45px;
  }

  .mg-xl-y-45-f {
    margin-top: 45px !important;
    margin-bottom: 45px !important;
  }

  .mg-xl-x-45 {
    margin-left: 45px;
    margin-right: 45px;
  }

  .mg-xl-x-45-f {
    margin-left: 45px !important;
    margin-right: 45px !important;
  }

  .mg-xl-t-45 {
    margin-top: 45px;
  }

  .mg-xl-r-45 {
    margin-right: 45px;
  }

  .mg-xl-b-45 {
    margin-bottom: 45px;
  }

  .mg-xl-l-45 {
    margin-left: 45px;
  }

  .mg-xl-t-45-f {
    margin-top: 45px !important;
  }

  .mg-xl-r-45-f {
    margin-right: 45px !important;
  }

  .mg-xl-b-45-f {
    margin-bottom: 45px !important;
  }

  .mg-xl-l-45-f {
    margin-left: 45px !important;
  }

  .mg-xl-50 {
    margin: 50px;
  }

  .mg-xl-50-f {
    margin: 50px !important;
  }

  .mg-xl-y-50 {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .mg-xl-y-50-f {
    margin-top: 50px !important;
    margin-bottom: 50px !important;
  }

  .mg-xl-x-50 {
    margin-left: 50px;
    margin-right: 50px;
  }

  .mg-xl-x-50-f {
    margin-left: 50px !important;
    margin-right: 50px !important;
  }

  .mg-xl-t-50 {
    margin-top: 50px;
  }

  .mg-xl-r-50 {
    margin-right: 50px;
  }

  .mg-xl-b-50 {
    margin-bottom: 50px;
  }

  .mg-xl-l-50 {
    margin-left: 50px;
  }

  .mg-xl-t-50-f {
    margin-top: 50px !important;
  }

  .mg-xl-r-50-f {
    margin-right: 50px !important;
  }

  .mg-xl-b-50-f {
    margin-bottom: 50px !important;
  }

  .mg-xl-l-50-f {
    margin-left: 50px !important;
  }

  .mg-xl-55 {
    margin: 55px;
  }

  .mg-xl-55-f {
    margin: 55px !important;
  }

  .mg-xl-y-55 {
    margin-top: 55px;
    margin-bottom: 55px;
  }

  .mg-xl-y-55-f {
    margin-top: 55px !important;
    margin-bottom: 55px !important;
  }

  .mg-xl-x-55 {
    margin-left: 55px;
    margin-right: 55px;
  }

  .mg-xl-x-55-f {
    margin-left: 55px !important;
    margin-right: 55px !important;
  }

  .mg-xl-t-55 {
    margin-top: 55px;
  }

  .mg-xl-r-55 {
    margin-right: 55px;
  }

  .mg-xl-b-55 {
    margin-bottom: 55px;
  }

  .mg-xl-l-55 {
    margin-left: 55px;
  }

  .mg-xl-t-55-f {
    margin-top: 55px !important;
  }

  .mg-xl-r-55-f {
    margin-right: 55px !important;
  }

  .mg-xl-b-55-f {
    margin-bottom: 55px !important;
  }

  .mg-xl-l-55-f {
    margin-left: 55px !important;
  }

  .mg-xl-60 {
    margin: 60px;
  }

  .mg-xl-60-f {
    margin: 60px !important;
  }

  .mg-xl-y-60 {
    margin-top: 60px;
    margin-bottom: 60px;
  }

  .mg-xl-y-60-f {
    margin-top: 60px !important;
    margin-bottom: 60px !important;
  }

  .mg-xl-x-60 {
    margin-left: 60px;
    margin-right: 60px;
  }

  .mg-xl-x-60-f {
    margin-left: 60px !important;
    margin-right: 60px !important;
  }

  .mg-xl-t-60 {
    margin-top: 60px;
  }

  .mg-xl-r-60 {
    margin-right: 60px;
  }

  .mg-xl-b-60 {
    margin-bottom: 60px;
  }

  .mg-xl-l-60 {
    margin-left: 60px;
  }

  .mg-xl-t-60-f {
    margin-top: 60px !important;
  }

  .mg-xl-r-60-f {
    margin-right: 60px !important;
  }

  .mg-xl-b-60-f {
    margin-bottom: 60px !important;
  }

  .mg-xl-l-60-f {
    margin-left: 60px !important;
  }

  .mg-xl-65 {
    margin: 65px;
  }

  .mg-xl-65-f {
    margin: 65px !important;
  }

  .mg-xl-y-65 {
    margin-top: 65px;
    margin-bottom: 65px;
  }

  .mg-xl-y-65-f {
    margin-top: 65px !important;
    margin-bottom: 65px !important;
  }

  .mg-xl-x-65 {
    margin-left: 65px;
    margin-right: 65px;
  }

  .mg-xl-x-65-f {
    margin-left: 65px !important;
    margin-right: 65px !important;
  }

  .mg-xl-t-65 {
    margin-top: 65px;
  }

  .mg-xl-r-65 {
    margin-right: 65px;
  }

  .mg-xl-b-65 {
    margin-bottom: 65px;
  }

  .mg-xl-l-65 {
    margin-left: 65px;
  }

  .mg-xl-t-65-f {
    margin-top: 65px !important;
  }

  .mg-xl-r-65-f {
    margin-right: 65px !important;
  }

  .mg-xl-b-65-f {
    margin-bottom: 65px !important;
  }

  .mg-xl-l-65-f {
    margin-left: 65px !important;
  }

  .mg-xl-70 {
    margin: 70px;
  }

  .mg-xl-70-f {
    margin: 70px !important;
  }

  .mg-xl-y-70 {
    margin-top: 70px;
    margin-bottom: 70px;
  }

  .mg-xl-y-70-f {
    margin-top: 70px !important;
    margin-bottom: 70px !important;
  }

  .mg-xl-x-70 {
    margin-left: 70px;
    margin-right: 70px;
  }

  .mg-xl-x-70-f {
    margin-left: 70px !important;
    margin-right: 70px !important;
  }

  .mg-xl-t-70 {
    margin-top: 70px;
  }

  .mg-xl-r-70 {
    margin-right: 70px;
  }

  .mg-xl-b-70 {
    margin-bottom: 70px;
  }

  .mg-xl-l-70 {
    margin-left: 70px;
  }

  .mg-xl-t-70-f {
    margin-top: 70px !important;
  }

  .mg-xl-r-70-f {
    margin-right: 70px !important;
  }

  .mg-xl-b-70-f {
    margin-bottom: 70px !important;
  }

  .mg-xl-l-70-f {
    margin-left: 70px !important;
  }

  .mg-xl-75 {
    margin: 75px;
  }

  .mg-xl-75-f {
    margin: 75px !important;
  }

  .mg-xl-y-75 {
    margin-top: 75px;
    margin-bottom: 75px;
  }

  .mg-xl-y-75-f {
    margin-top: 75px !important;
    margin-bottom: 75px !important;
  }

  .mg-xl-x-75 {
    margin-left: 75px;
    margin-right: 75px;
  }

  .mg-xl-x-75-f {
    margin-left: 75px !important;
    margin-right: 75px !important;
  }

  .mg-xl-t-75 {
    margin-top: 75px;
  }

  .mg-xl-r-75 {
    margin-right: 75px;
  }

  .mg-xl-b-75 {
    margin-bottom: 75px;
  }

  .mg-xl-l-75 {
    margin-left: 75px;
  }

  .mg-xl-t-75-f {
    margin-top: 75px !important;
  }

  .mg-xl-r-75-f {
    margin-right: 75px !important;
  }

  .mg-xl-b-75-f {
    margin-bottom: 75px !important;
  }

  .mg-xl-l-75-f {
    margin-left: 75px !important;
  }

  .mg-xl-80 {
    margin: 80px;
  }

  .mg-xl-80-f {
    margin: 80px !important;
  }

  .mg-xl-y-80 {
    margin-top: 80px;
    margin-bottom: 80px;
  }

  .mg-xl-y-80-f {
    margin-top: 80px !important;
    margin-bottom: 80px !important;
  }

  .mg-xl-x-80 {
    margin-left: 80px;
    margin-right: 80px;
  }

  .mg-xl-x-80-f {
    margin-left: 80px !important;
    margin-right: 80px !important;
  }

  .mg-xl-t-80 {
    margin-top: 80px;
  }

  .mg-xl-r-80 {
    margin-right: 80px;
  }

  .mg-xl-b-80 {
    margin-bottom: 80px;
  }

  .mg-xl-l-80 {
    margin-left: 80px;
  }

  .mg-xl-t-80-f {
    margin-top: 80px !important;
  }

  .mg-xl-r-80-f {
    margin-right: 80px !important;
  }

  .mg-xl-b-80-f {
    margin-bottom: 80px !important;
  }

  .mg-xl-l-80-f {
    margin-left: 80px !important;
  }

  .mg-xl-85 {
    margin: 85px;
  }

  .mg-xl-85-f {
    margin: 85px !important;
  }

  .mg-xl-y-85 {
    margin-top: 85px;
    margin-bottom: 85px;
  }

  .mg-xl-y-85-f {
    margin-top: 85px !important;
    margin-bottom: 85px !important;
  }

  .mg-xl-x-85 {
    margin-left: 85px;
    margin-right: 85px;
  }

  .mg-xl-x-85-f {
    margin-left: 85px !important;
    margin-right: 85px !important;
  }

  .mg-xl-t-85 {
    margin-top: 85px;
  }

  .mg-xl-r-85 {
    margin-right: 85px;
  }

  .mg-xl-b-85 {
    margin-bottom: 85px;
  }

  .mg-xl-l-85 {
    margin-left: 85px;
  }

  .mg-xl-t-85-f {
    margin-top: 85px !important;
  }

  .mg-xl-r-85-f {
    margin-right: 85px !important;
  }

  .mg-xl-b-85-f {
    margin-bottom: 85px !important;
  }

  .mg-xl-l-85-f {
    margin-left: 85px !important;
  }

  .mg-xl-90 {
    margin: 90px;
  }

  .mg-xl-90-f {
    margin: 90px !important;
  }

  .mg-xl-y-90 {
    margin-top: 90px;
    margin-bottom: 90px;
  }

  .mg-xl-y-90-f {
    margin-top: 90px !important;
    margin-bottom: 90px !important;
  }

  .mg-xl-x-90 {
    margin-left: 90px;
    margin-right: 90px;
  }

  .mg-xl-x-90-f {
    margin-left: 90px !important;
    margin-right: 90px !important;
  }

  .mg-xl-t-90 {
    margin-top: 90px;
  }

  .mg-xl-r-90 {
    margin-right: 90px;
  }

  .mg-xl-b-90 {
    margin-bottom: 90px;
  }

  .mg-xl-l-90 {
    margin-left: 90px;
  }

  .mg-xl-t-90-f {
    margin-top: 90px !important;
  }

  .mg-xl-r-90-f {
    margin-right: 90px !important;
  }

  .mg-xl-b-90-f {
    margin-bottom: 90px !important;
  }

  .mg-xl-l-90-f {
    margin-left: 90px !important;
  }

  .mg-xl-95 {
    margin: 95px;
  }

  .mg-xl-95-f {
    margin: 95px !important;
  }

  .mg-xl-y-95 {
    margin-top: 95px;
    margin-bottom: 95px;
  }

  .mg-xl-y-95-f {
    margin-top: 95px !important;
    margin-bottom: 95px !important;
  }

  .mg-xl-x-95 {
    margin-left: 95px;
    margin-right: 95px;
  }

  .mg-xl-x-95-f {
    margin-left: 95px !important;
    margin-right: 95px !important;
  }

  .mg-xl-t-95 {
    margin-top: 95px;
  }

  .mg-xl-r-95 {
    margin-right: 95px;
  }

  .mg-xl-b-95 {
    margin-bottom: 95px;
  }

  .mg-xl-l-95 {
    margin-left: 95px;
  }

  .mg-xl-t-95-f {
    margin-top: 95px !important;
  }

  .mg-xl-r-95-f {
    margin-right: 95px !important;
  }

  .mg-xl-b-95-f {
    margin-bottom: 95px !important;
  }

  .mg-xl-l-95-f {
    margin-left: 95px !important;
  }

  .mg-xl-100 {
    margin: 100px;
  }

  .mg-xl-100-f {
    margin: 100px !important;
  }

  .mg-xl-y-100 {
    margin-top: 100px;
    margin-bottom: 100px;
  }

  .mg-xl-y-100-f {
    margin-top: 100px !important;
    margin-bottom: 100px !important;
  }

  .mg-xl-x-100 {
    margin-left: 100px;
    margin-right: 100px;
  }

  .mg-xl-x-100-f {
    margin-left: 100px !important;
    margin-right: 100px !important;
  }

  .mg-xl-t-100 {
    margin-top: 100px;
  }

  .mg-xl-r-100 {
    margin-right: 100px;
  }

  .mg-xl-b-100 {
    margin-bottom: 100px;
  }

  .mg-xl-l-100 {
    margin-left: 100px;
  }

  .mg-xl-t-100-f {
    margin-top: 100px !important;
  }

  .mg-xl-r-100-f {
    margin-right: 100px !important;
  }

  .mg-xl-b-100-f {
    margin-bottom: 100px !important;
  }

  .mg-xl-l-100-f {
    margin-left: 100px !important;
  }

  .mg-xl-t-auto {
    margin-top: auto;
  }

  .mg-xl-r-auto {
    margin-right: auto;
  }

  .mg-xl-b-auto {
    margin-bottom: auto;
  }

  .mg-xl-l-auto {
    margin-left: auto;
  }

  .mg-xl-auto {
    margin: auto;
  }

  .mg-xl-x-auto {
    margin-left: auto;
    margin-right: auto;
  }
}